/* eslint-disable camelcase */
import React, {
	useState,
	useEffect
}    from "react";
import PropTypes              from "prop-types";
import { connect }            from "react-redux";
import { bindActionCreators } from "redux";
import {
	Card,
	CardContent,
	Typography,
	withStyles,
	Grid,
	Dialog,
	Slide,
	DialogTitle,
	DialogContent,
	IconButton
} from "@material-ui/core";
import { makeStyles }	from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

// Import Own Components
import AuthActions  	from "../../config/store/actions/authActions";
import ModalActions 	from "../../config/store/actions/modalActions";
import userService		from "../../config/services/userService";
import EmployeesTable	from "./Components/EmployeesTable";
import "./BranchOfficeData.scss";

const styles = theme => ({
	fallbackAvatar : {
		backgroundColor : "#3a8fb7",
	},
	closeButton : {
		position : "absolute",
		right    : "1rem",
		top      : theme.spacing(1),
		color    : theme.palette.grey[500],
	},
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const BranchOfficeData = ({
	branchOfficeData : {
		workers_count,
		name,
		id,
		employer_registration
	},
	customerId
}) => {
	const classes = makeStyles(styles)();
	const [open, setOpen]             = useState(false);
	const [employees, setEmployees]   = useState([]);
	const [pagination, setPagination] = useState({});
	const [paginate, setPaginate] = useState({
		per_page : 10,
	});

	const handleModal = () => {
		setOpen(!open);
	};
	const branchOfficeEmployeeList = async (page = 1) => {
		if (!workers_count) return;
		const response = await userService.getCustomerEmployees(customerId, { branch_office_id : id, ...paginate, page });
		setEmployees(response.data.employees);
		setPagination(response.pagination);
	};

	const handlePagination = propName => (value) => {
		setPaginate(pastVal => ({
			...pastVal,
			[propName] : value,
		}));
	};

	useEffect(() => {
		branchOfficeEmployeeList(paginate.page);
	}, [paginate]);

	return (
		<Grid item md={6} xs={12} className="BranchOfficeData">
			<Card onClick={() => setOpen(true)} style={{ cursor : workers_count > 0 ? "pointer" : "default" }}>
				<Grid container>
					<Grid item xs={9}>
						<CardContent className="content">
							<Typography component="h5" variant="h5">
								{name}
							</Typography>
							<Typography variant="subtitle1" color="textSecondary">
								{employer_registration
									? `${employer_registration.nrp || ""} | ${employer_registration.delegation}` : ""}
							</Typography>
						</CardContent>
					</Grid>
					<Grid item xs={3}>
						<Card className="workers">
							<CardContent>
								<Typography
									component="h5"
									variant="h5"
									className="workersNumber"
								>
									{workers_count}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Card>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleModal}
				aria-labelledby="Empleados de sucursal"
				aria-describedby="Empleados de sucursal"
			>
				<DialogTitle>
					<Typography component="h5" variant="h5" style={{ padding : "1rem", textAlign : "center" }}>
					Empleados de la sucursal
						{" "}
						{name}
					</Typography>
					<IconButton aria-label="close" className={classes.closeButton} onClick={handleModal}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<EmployeesTable employees={employees} pagination={pagination} handlePagination={handlePagination} />
				</DialogContent>
			</Dialog>
		</Grid>
	);
};

BranchOfficeData.propTypes = {
	branchOfficeData : PropTypes.object.isRequired,
	customerId       : PropTypes.number.isRequired,
};

const mapStateToProps = ({ authReducer : { customerData } }) => ({ customerId : customerData.id });

const mapDispatchToProps = dispatch => ({
	authActions  : bindActionCreators(AuthActions, dispatch),
	modalActions : bindActionCreators(ModalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
	withStyles(styles)(BranchOfficeData)
);
