import { startOfMonth, addDays, format } from "date-fns";

export const mapStatus = {
	PENDING : {
		name      : "Solicitud iniciada",
		progress  : 25,
		className : "pending",
	},
	IN_REVISION : {
		name      : "Solicitud en proceso por MAC",
		progress  : 50,
		className : "in_revision",
	},
	IMSS_REVISION : {
		name      : "Solicitud en proceso por IMSS",
		progress  : 75,
		className : "imss_revision",
	},
	REJECTED : {
		name      : "Solicitud rechazada por el IMSS",
		progress  : 100,
		className : "canceled",
	},
	FINISHED : {
		name      : "Solicitud finalizada",
		progress  : 100,
		className : "finished",
	},
	CANCELED : {
		name      : "Solicitud cancelada",
		progress  : 0,
		className : "canceled",
	},
};

export const mapRequestType = {
	REGISTER_WORKER : {
		icon	: "fas fa-user-hard-hat",
		name : "Alta de Empleado",
	},
	WITHDRAWAL : {
		icon	: "far fa-user-hard-hat",
		name : "Baja de Empleado",
	},
	NEW_SALARY : {
		icon	: "fas fa-money-check-edit-alt",
		name : "Modificación de Salario",
	},
	DISABILITIES : {
		icon	: "fas fa-wheelchair",
		name : "Incapacidad",
	},
	EMPLOYMENT_CREDIT : {
		icon	: "fas fa-credit-card",
		name : "Crédito de Empleado",
	},
	EMPLOYER_REGISTRATION : {
		name : "Alta de Registro Patronal",
	},
	EMPLOYER_REGISTRATION_WITHDRAWAL : {
		name : "Baja de Registro Patronal",
	},
};

export const disabilitiesTypes = {
	GENERAL_ILLNESS   : "Enfermedad general",
	MATERNITY         : "Maternidad",
	OCCUPATIONAL_RISK : "Riesgo de trabajo",
};

export const filtersInit = {
	date_start : format(startOfMonth(new Date()), "yyyy-MM-dd HH:mm"),
	date_end   : format(addDays(new Date(), 5), "yyyy-MM-dd HH:mm")
};
