/* eslint-disable react/jsx-curly-brace-presence */
import React     from "react";
import PropTypes from "prop-types";
import {
	Typography,
	withStyles
} from "@material-ui/core";

// Import Own Componentns
import SignInForm from "./SignInForm.jsx";
import "./SignIn.scss";

const styles = theme => ({
	upperColorBg : {
		backgroundColor : "#769d9c",
	},
	lowerColorBg : {
		backgroundColor : theme.palette.primary.main,
	},
	colorWhite : {
		color : theme.palette.common.white,
	}
});

const SignIn = ({ classes }) => (
	<div className="SignIn">
		<div className="background">
			<div className={`${classes.upperColorBg} upperColor`}>&nbsp;</div>
			<div className={`${classes.lowerColorBg} lowerColor`}>&nbsp;</div>
		</div>

		<Typography
			variant="h6"
			component="h2"
			className={`${classes.colorWhite} captionText`}
		>
			{"Magaña - Arevalo Contadores"}
		</Typography>

		<SignInForm />

		<Typography
			variant="subtitle2"
			component="h3"
			className={`${classes.colorWhite} captionText`}
		>
			{"Ingresa a nuestra app para estar informado"}
			{" al instante y en todo momento"}
		</Typography>
	</div>
);

SignIn.propTypes = {
	classes : PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
