import React, {
	useEffect,
	useState
} from "react";
import PropTypes    from "prop-types";
import { connect }  from "react-redux";
import { Pendings } from "../../components/Pendings";
import userService  from "../../config/services/userService";

// import own components
import ModuleContainer from "../../components/ModuleContainer";

const StatusAccount = ({
	customerId, userType
}) => {
	const initState = {
		pendings : null
	};
	const [pendings, setPendingsData] = useState(initState.pendings);
	const [render, setRender] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				const pendingsResponse = await userService.getPendingns({
					[userType === "customer" ? "customer_id" : "employee_responsible_id"] : customerId,
					status                                                                : "activo",
				});
				setPendingsData(pendingsResponse.response.pendings);
				setRender(false);
			} catch (err) {
				// console.log(err);
			}
		})();
	}, [render, customerId]);

	const onRender = () => setRender(true);

	return (
		<div className="StatusAccount">
			<ModuleContainer
				title="CONSULTAS"
				color="#f3f0f0"
				background="#254eab"
				icon="far fa-thumbtack"
			>
				{pendings && (
					<Pendings pendings={pendings} render={onRender} customerId={customerId} />
				)}
			</ModuleContainer>
		</div>
	);
};

StatusAccount.propTypes = {
	customerId : PropTypes.number.isRequired,
	userType   : PropTypes.string.isRequired,
};

const mapStateToProps = ({ authReducer : { customerData, type } }) => (
	{
		customerId : customerData.id,
		userType   : type,
	}
);

export default connect(mapStateToProps, null)(StatusAccount);
