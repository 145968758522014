/* eslint-disable import/prefer-default-export */
export const initState = {
	submitted : false,
	formData  : {
		responsible_id	: null,
		customer_id    : null,
	},
};

export const columns = [
	{
		field      : "name",
		headerName : "Nombre",
		width      : 150,
		sortable   : false
	},
	{
		field      : "description",
		headerName : "Descripción",
		width      : 280,
		sortable   : false
	},
	{
		field       : "address",
		headerName  : "Dirección",
		width       : 600,
		sortable    : false,
		valueGetter : params => `${params.getValue("street")}, #${params.getValue("outdoor_number")}, ${params.getValue("colony")}, ${params.getValue("city")}, ${params.getValue("state")}`
	},
];
