import React from "react";
import PropTypes	from "prop-types";

// import own components
import ModuleContainer	from "../../../components/ModuleContainer";
import Disabilities   from "../../../components/Requests/Disabilities";

const DisabilitiesPage = ({ match }) => (
	<div>
		<ModuleContainer
			title="Tramite IMSS > Incapacidades"
			color="#f3f0f0"
			background="#13322b"
			icon="fas fa-wheelchair"
			md={11}
			lg={11}
		>
			<Disabilities
				match={match}
			/>
		</ModuleContainer>
	</div>
);

DisabilitiesPage.propTypes = {
	match	: PropTypes.object.isRequired,
};

export default DisabilitiesPage;
