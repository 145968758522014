import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
	root : {
		height          : `${theme.spacing(8)}px`,
		backgroundColor : theme.palette.grey[50],
	},
	grow : {
		flexGrow : 1,
	},
	darkColor : {
		color : theme.palette.grey[700],
	},
	lightColor : {
		color : theme.palette.grey[100],
	},
	fallbackAvatar : {
		backgroundColor : theme.palette.secondary.main,
	},
}));
