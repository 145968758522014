import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Grid,
	withStyles
} from "@material-ui/core";
import CardDocument    from "../../components/CardDocuments";
import ModuleContainer from "../../components/ModuleContainer";
import fileService     from "../../config/services/fileService";

const styles = theme => ({
	root : {
		flexGrow : 1,
	},
	paper : {
		padding   : theme.spacing(2),
		textAlign : "center",
		color     : theme.palette.text.secondary,
	},
	topHeader : {
		height          : "0.5rem",
		width           : "100%",
		backgroundColor : "#fa5b4d",
	}
});

const Documentes = ({
	classes : {
		root,
		topHeader,
	},
	match
}) => {
	const colors = [
		"#EE7777",
		"#D6D5C6",
		"#AD77EE",
		"#EE77B1",
		"#EEB477",
		"#ADEE77",
		"#77EEE7",
		"#77A0EE",
		"#CAD83B",
		"#F5E829",
	];

	const [categories, setCategories] = useState();

	useEffect(() => {
		(async () => {
			try {
				const categoryResponse = await fileService.getDependencies();
				setCategories({
					categories : categoryResponse.data
				});
			} catch (err) {
				// console.log(err);
			}
		})();
	}, []);

	return (
		<div className={root}>
			<ModuleContainer
				title="Documentos"
				color="#f3f0f0"
				background="#c12424"
				icon="far fa-cabinet-filing"
			>
				<div className={topHeader} />
				<Grid container direction="row" justify="flex-start" alignItems="center">
					{
						categories && (
							categories.categories.map(category => (
								<CardDocument
									key={category.id}
									name={category.name}
									link={`${match.url}/categorias.${category.id}`}
									categoryId={category.id}
									icon="fas fa-folders"
									color={colors[Math.floor(Math.random() * (colors.length))]}
								/>
							))
						)
					}
				</Grid>
			</ModuleContainer>
		</div>
	);
};

Documentes.propTypes = {
	classes : PropTypes.object.isRequired,
	match   : PropTypes.object.isRequired,
};

export default withStyles(styles)(Documentes);
