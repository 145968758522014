/* eslint-disable no-shadow */
import React, {
	useState,
	useEffect
} from "react";
import PropTypes   from "prop-types";
import { connect } from "react-redux";
import {
	Avatar,
	makeStyles
} from "@material-ui/core";

// Import Own Components
import theme from "../../ui/theme";

const defaultStyles = {
	backgroundColor : theme.palette.secondary.main,
	color           : theme.palette.common.white,
	factor          : 1,
};

const useStyles = makeStyles((theme) => {
	const imageSizing = divideBy => ({ factor }) => `${(theme.spacing(5) * factor) / divideBy}px !important`;

	return ({
		userImage : {
			backgroundColor : ({ backgroundColor }) => backgroundColor,
			color           : ({ color }) => color,
			borderRadius    : "50%",
			width           : imageSizing(1),
			height          : imageSizing(1),
			fontSize        : imageSizing(2),
		},
	});
});

const UserImage = ({
	styles,
	fallbackIcon,
	customerData,
	userData
}) => {
	const classes = useStyles(Object.assign({}, defaultStyles, styles));

	const [useFallbackImage, setUseFallbackImage] = useState(false);

	const FallbackImage = props => (
		fallbackIcon ? (
			<div>
				<i className={`${fallbackIcon} ${classes.userImage}`} />
			</div>
		) : (
			<Avatar
				{...props}
				className={classes.userImage}
			>
				{ customerData.name[0].toUpperCase() }
			</Avatar>
		)
	);

	useEffect(() => {
		setUseFallbackImage(false);
	}, [customerData, userData]);

	return (
		useFallbackImage ? (
			<FallbackImage />
		) : (
			<Avatar
				alt={`${customerData.name}Pic`}
				src={`${process.env.REACT_APP_SYSCONTROL_API}/storage/file/${userData.file_id}`}
				className={classes.userImage}
				onError={(e) => {
					setUseFallbackImage(true);
				}}
			/>
		)
	);
};

UserImage.propTypes = {
	styles : PropTypes.shape({
		backgroundColor : PropTypes.string,
		factor          : PropTypes.number,
	}),
	customerData : PropTypes.object.isRequired,
	userData     : PropTypes.object.isRequired,
	fallbackIcon : PropTypes.string,
};

UserImage.defaultProps = {
	styles       : defaultStyles,
	fallbackIcon : "",
};

const mapStateToProps = ({ authReducer : { customerData, userData } }) => ({ customerData, userData });

export default connect(mapStateToProps, null)(UserImage);
