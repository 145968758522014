import { format }   from "date-fns";

export const initState = {
	submitted : false,
	formData  : {
		movementDate      	: format(new Date(), "yyyy-MM-dd HH:mm"),
		customerId        	: null,
		branchOfficeId    	: null,
		hasInfonavitCredit	: false,
		hasFonacotCredit  	: false,
	},
};

export const statusLabel = {
	customerName        	: "Cliente",
	customerId          	: "Cliente",
	branchOffice        	: "Sucursal",
	branchOfficeId      	: "Sucursal",
	workerName          	: "Nombre Trabajador",
	workerLastName       : "Apellido Empleado",
	monthlySalary       	: "Salario Mensual",
	biweeklySalary      	: "Salario Quincenal",
	workerRFC           	: "RFC del Trabajador",
	workerNSS           	: "NSS del Trabajador",
	workerCURP          	: "CURP del Trabajador",
	hasInfonavitCredit  	: "¿El Trabajador tiene Crédito INFONAVIT",
	hasFonacotCredit    	: "¿El Trabajador tiene Crédito FONACOT?",
	retentionFile       	: "Hoja de Retención Infonavit",
	retentionFileFonacot	: "Hoja de Retención Fonacot",
	weeklySalary        	: "Salario Semanal",
	details             	: "Detalles u Observaciones",
	movementDate        	: "Fecha del Movimiento",
};

export const validFormInit = {
	customerName  	: true,
	customerId    	: true,
	branchOffice  	: true,
	branchOfficeId	: true,
	workerName    	: true,
	workerLastName : true,
	workerRFC     	: true,
	workerNSS     	: true,
	workerCURP    	: true,
	salaries      	: true,
};
