import PropTypes from "prop-types";
import React from "react";
import {
	Grid,
	Paper
} from "@material-ui/core";

import "./ModuleContainer.scss";

const ModuleContainer = ({
	icon,
	title,
	color,
	border,
	children,
	background,
	xs,
	sm,
	md,
	lg,
}) => (
	<div className="ModuleContainer">
		<div
			className="header"
			style={{ backgroundColor : background, color, border }}
		>
			<h1>
				{
					icon && (
						<i className={`${icon}`} />
					)
				}
				{title}
			</h1>
		</div>
		<Grid className="main" container>
			<Grid item xs={12}>
				<Grid container justify="center">
					<Grid item xs={xs} sm={sm} md={md} lg={lg}>
						<Paper className="container">
							<Grid container justify="space-around">
								{children}
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</div>
);

ModuleContainer.propTypes = {
	title      : PropTypes.string.isRequired,
	children   : PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	icon       : PropTypes.string,
	color      : PropTypes.string,
	border     : PropTypes.string,
	background : PropTypes.string,
	xs         : PropTypes.number,
	sm         : PropTypes.number,
	md         : PropTypes.number,
	lg         : PropTypes.number,
};

ModuleContainer.defaultProps = {
	children   : <></>,
	icon       : null,
	border     : "0 1px transparent",
	color      : "#d3d3d3",
	background : "#ffffff",
	xs         : 11,
	sm         : 10,
	md         : 8,
	lg         : 6,
};

export default ModuleContainer;
