/* eslint-disable react/jsx-curly-brace-presence */
import React, {
	useEffect,
	useState
} from "react";
import PropTypes   from "prop-types";
import { connect } from "react-redux";
import {
	Typography,
	withStyles,
	Grid
} from "@material-ui/core";

// Import Own Components
import BranchOfficeData               from "./BranchOfficeData.jsx";
import { CircularDeterminateLoading } from "../Loading";
import userService                    from "../../config/services/userService";
import "./WorkersDetails.scss";

const styles = theme => ({
	greyColor : {
		color : theme.palette.grey[700],
	},
});

const WorkersDetails = ({ customerData, classes }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [state, setState]   = useState([]);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);

				const response = await userService.getCustomerBranchOffices({ customer_id : customerData.id });

				if (response.status !== 200) {
					throw Error("An exception has occurred: ", response);
				}
				setState(response.data);
			} catch (err) {
				// console.log("err", err);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	return (
		<div className="WorkersDetails">
			<Grid container spacing={2} justify="center">
				<Grid item className="title">
					<i className={`${classes.greyColor} fas fa-user-friends`} />
					<Typography
						variant="h5"
						component="h4"
						className={classes.greyColor}
					>
						{"Sucursales"}
					</Typography>
				</Grid>
				<Grid container item xs={12} spacing={2} justify="center">
					{state.map(e => (
						<BranchOfficeData
							branchOfficeData={e}
							key={e.id}
						/>
					))}
				</Grid>

				{ isLoading && (
					<div className="WorkersDetailsLoading">
						<CircularDeterminateLoading />
					</div>
				) }
			</Grid>
		</div>
	);
};

WorkersDetails.propTypes = {
	customerData : PropTypes.object.isRequired,
	classes      : PropTypes.object.isRequired,
};

const mapStateToProps = ({ authReducer : { customerData } }) => ({ customerData });

export default connect(mapStateToProps, null)(
	withStyles(styles)(WorkersDetails)
);
