/* eslint-disable react/jsx-filename-extension */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import RequestsCustomer, { RequestsEmployees } from "../../components/Requests";

const Requests = ({ type, match }) => (type === "customer" ? (
	<RequestsCustomer match={match} />
) : (
	<RequestsEmployees match={match} />
));

Requests.propTypes = {
	type  : PropTypes.string.isRequired,
	match : PropTypes.object.isRequired,
};

const mapStateToProps = ({ authReducer: { type } }) => ({
	type,
});

export default connect(
	mapStateToProps,
	null
)(Requests);
