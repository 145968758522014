import PropTypes from "prop-types";
import React     from "react";
import {
	Grid,
	Paper
} from "@material-ui/core";
import history from "../../config/helpers/history";

import "./CardDocumets.scss";

const CardDocuments = ({
	name,
	color,
	link,
	icon,
	categoryId,
	subCategoryId,
}) => {
	const redirect = e => (to) => {
		history.push(to);
	};
	return (
		<Grid item className="CardDocuments" xs={12} sm={6}>
			<Paper
				className={`card ${link.length ? "cursor-pointer papper" : "papper"}`}
				onClick={e => redirect(e)({
					pathname : link,
					state    : {
						categoryId,
					},
				})}
			>
				<Grid container justify="flex-start" alignContent="center" spacing={2}>
					<Grid item className="iconContainer">
						{
							icon && (
								<i
									className={`icon-size ${icon}`}
									style={{ color }}
								/>
							)
						}
					</Grid>
					<Grid item>
						<p>{name}</p>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
};

CardDocuments.propTypes = {
	name          : PropTypes.string,
	color         : PropTypes.string,
	link          : PropTypes.string,
	icon          : PropTypes.string,
	categoryId    : PropTypes.number,
	subCategoryId : PropTypes.number,
};

CardDocuments.defaultProps = {
	name          : "Dependencias",
	color         : "",
	link          : "",
	icon          : "",
	categoryId    : undefined,
	subCategoryId : undefined,
};

export default CardDocuments;
