import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
	root : {
		width     : "100%",
		height    : "100%",
		minHeight : "60vh",
	},
	backButton : {
		marginRight : theme.spacing(1),
	},
	instructions : {
		marginTop    : theme.spacing(1),
		marginBottom : theme.spacing(1),
	},
	topHeader : {
		height          : "0.5rem",
		width           : "100%",
		backgroundColor : "#2b977f",
		marginBottom    : "1rem",
	},
	boxMargin : {
		margin : "2rem",
	},
	marginButton : {
		marginRight : "2rem",
	},
	cancelButton : {
		borderColor : theme.palette.error.light,
		color       : theme.palette.error.light,
	},
	selectedStep : {
		backgroundColor : "#b08d2317",
	}
}));

export const UploadButtonStyles = makeStyles(theme => ({
	btnFile : {
		padding         : "1.5rem !important",
		borderColor     : "#7a7979",
		backgroundColor : "#f5f2f2",
		color           : "#928a8a",
		height          : "100%",
		width           : "100%",
		border          : "2px dashed"
	},
	clip : {
		color     : "#928a8a",
		fontSize  : "48px",
		textAlign : "center",
	},
}));
