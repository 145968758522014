import { format }   from "date-fns";

export const initState = {
	submitted : false,
	formData  : {
		movementDate : format(new Date(), "yyyy-MM-dd HH:mm"),
		customerId   : null,
	},
};

export const statusLabel = {
	customerName   : "Cliente",
	customerId     : "Cliente",
	workerName     : "Nombre Empleado",
	branchOffice   : "Sucursal",
	branchOfficeId : "Sucursal",
	workerLastName : "Apellido Empleado",
	workerRFC      : "RFC Empleado",
	monthlySalary  : "Salario Mensual",
	biweeklySalary : "Salario Quincenal",
	weeklySalary   : "Salario Semanal",
	details        : "Detalles u Observaciones",
	movementDate   : "Fecha del Movimiento"
};

export const validFormInit = {
	customerName   : true,
	customerId     : true,
	branchOffice   : true,
	branchOfficeId : true,
	workerName     : true,
	workerLastName : true,
	workerRFC      : true,
	salaries       : true,
};
