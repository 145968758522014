import PropTypes       from "prop-types";
import React           from "react";
import { Grid, Paper } from "@material-ui/core";

// Import Own Components
import { history } from "../../config/helpers";
import "./CardDirectory.scss";

const CardDirectory = ({
	icon,
	text,
	link,
	title,
	color,
	border,
	children,
	background,
	className,
	xs,
	sm,
	md,
	lg,
	xl,
	onClick,
}) => {
	const redirect = e => (to) => {
		if (link.length) {
			return history.push(to);
		}
		return false;
	};

	return (
		<Grid item className={`CardDirectory ${className}`} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
			<Paper
				className={link.length || onClick ? "cursor-pointer papper" : "papper"}
				style={{ backgroundColor : background, color, border }}
				onClick={e => (link ? redirect(e)(link) : onClick(e))}
			>
				<h3>
					{
						icon && (
							<i className={`${icon}`} />
						)
					}
					{title}
				</h3>
				<h6>
					{text}
				</h6>
				<Grid container justify="center" direction="column">
					{
						children
					}
				</Grid>
			</Paper>
		</Grid>
	);
};

CardDirectory.propTypes = {
	children   : PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	title      : PropTypes.string,
	text       : PropTypes.string,
	link       : PropTypes.string,
	icon       : PropTypes.string,
	color      : PropTypes.string,
	border     : PropTypes.string,
	background : PropTypes.string,
	className  : PropTypes.string,
	xs         : PropTypes.number,
	sm         : PropTypes.number,
	md         : PropTypes.number,
	lg         : PropTypes.number,
	xl         : PropTypes.number,
	onClick    : PropTypes.func,
};

CardDirectory.defaultProps = {
	icon       : null,
	title      : null,
	text       : null,
	border     : "0 1px transparent",
	color      : "#d3d3d3",
	link       : "",
	background : "#ffffff",
	className  : "",
	children   : <></>,
	xs         : 12,
	sm         : 6,
	md         : 6,
	lg         : 5,
	xl         : 5,
	onClick    : null,
};

export default CardDirectory;
