/* eslint-disable no-console */

/** Register service worker if we're in production */
const registerServiceWorker = async () => {
	const isProduction = process.env.NODE_ENV === "production";

	if (isProduction) {
		if ("serviceWorker" in navigator) {
			try {
				const registration = await navigator.serviceWorker.register("/sw.js");

				console.log("[App] ServiceWorker registration successful with scope: ", registration.scope);
			} catch (err) {
				console.log("[App] SW Registration failed", err);
			}
		}
	}
};

export default registerServiceWorker;
