import React,
{ useEffect }            from "react";
import { Provider }      from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline       from "@material-ui/core/CssBaseline";
import {
	Router,
	Route
} from "react-router-dom";
import { MuiPickersUtilsProvider }	from "@material-ui/pickers";
import DateFnsUtils        			from "@date-io/date-fns";
import esLocale            			from "date-fns/locale/es";

// Import Layouts
import SwitchLayout					from "./layouts/SwitchLayouts";

// Import Own Components
import AppDrawer                      from "./components/AppDrawer";
import LoadContent                    from "./components/LoadContent";
import { BlockProcessLoading }        from "./components/Loading";
import MobileModal                    from "./components/MobileModal";
import store                          from "./config/store";
import SignIn                         from "./pages/SignIn";
import { history, scrollToTop }       from "./config/helpers";
import AuthActions                    from "./config/store/actions/authActions";
import theme                          from "./ui/theme";
import "./App.scss";

const App = (props) => {
	useEffect(() => {
		const callToUnlisten = history.listen((location, action) => {
			scrollToTop();
		});
		if (store.getState().authReducer.type) {
			store.dispatch(AuthActions.updateUserSessionData(store.getState().authReducer.type,
				store.getState().authReducer.userData));
		}
		esLocale.options.weekStartsOn = 0;

		return () => {
			callToUnlisten();
			window.cancelAnimationFrame(scrollToTop);
		};
	}, []);

	return (
		<>
			<CssBaseline />
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
				<ThemeProvider theme={theme}>
					<Router history={history}>
						<Provider store={store}>
							<LoadContent>
								<MobileModal />
								<BlockProcessLoading />
								<AppDrawer />
								<SwitchLayout />
							</LoadContent>
							<Route path="/iniciar-sesion" component={SignIn} exact />
						</Provider>
					</Router>
				</ThemeProvider>
			</MuiPickersUtilsProvider>
		</>
	);
};

export default App;
