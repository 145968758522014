import { makeStyles }	from "@material-ui/core/styles";

export default makeStyles(theme => ({
	MainContainer : {
		height    : "100%",
		minHeight : "60vh",
	},
	inputContainer : {
		padding : "1rem 1.2rem",
	},
	topHeader : {
		height          : "0.5rem",
		width           : "100%",
		backgroundColor : "#2b977f",
		marginBottom    : "1rem",
	},
	block : {
		display : "block",
	},
	text : {
		paddingLeft : "0.9rem"
	},
	rating1 : {
		width      : 200,
		display    : "flex",
		alignItems : "center",
	},
	svg : {
		margin : "0 0.5rem",
	},
	form : {
		width     : "100%",
		marginTop : "1rem",
	}
}));
