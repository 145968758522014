import React       from "react";
import PropTypes   from "prop-types";
import { connect } from "react-redux";
import {
	Grid,
	TextField
} from "@material-ui/core";

// Import Own Components
import "./DisplayFiscalData.scss";

const DisplayFiscalData = ({ customerData }) => (
	<div className="DisplayFiscalData">
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<TextField
					label="RFC"
					value={customerData.rfc}
					margin="dense"
					fullWidth
					InputProps={{
						readOnly : true,
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					label="CURP"
					value={customerData.curp}
					margin="dense"
					fullWidth
					InputProps={{
						readOnly : true,
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					label="Giro"
					value={customerData.business_turn}
					margin="dense"
					fullWidth
					InputProps={{
						readOnly : true,
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					label="Trabajadores con IMSS"
					value={customerData.num_workers_imss}
					margin="dense"
					fullWidth
					InputProps={{
						readOnly : true,
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					label="Régimen"
					value={customerData.government}
					margin="dense"
					fullWidth
					InputProps={{
						readOnly : true,
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					label="Contaseña SAT"
					value={customerData.password_sat}
					margin="dense"
					fullWidth
					InputProps={{
						readOnly : true,
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					label="Contaseña FIEL"
					value={customerData.password_fiel}
					margin="dense"
					fullWidth
					InputProps={{
						readOnly : true,
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					label="Contaseña IMSS"
					value={customerData.password_imss}
					margin="dense"
					fullWidth
					InputProps={{
						readOnly : true,
					}}
				/>
			</Grid>
			<Grid item xs={8}>
				<TextField
					label="Contaseña Sello Digital"
					value={customerData.password_sello_digital}
					margin="dense"
					fullWidth
					InputProps={{
						readOnly : true,
					}}
				/>
			</Grid>
		</Grid>
	</div>
);

DisplayFiscalData.propTypes = {
	customerData : PropTypes.object.isRequired,
};

const mapStateToProps = ({ authReducer : { customerData } }) => ({ customerData });

export default connect(mapStateToProps, null)(DisplayFiscalData);
