import React        					from "react";
import PropTypes    					from "prop-types";
import { connect }						from "react-redux";
import {
	Redirect, useLocation,
	Route, Switch
}				from "react-router-dom";
import { openRoutes, openRoutesPaths }	from "../../config/routes";

const LoadContent = ({ loggedIn, children, customerData }) => {
	const { pathname } = useLocation();
	if (openRoutesPaths.includes(pathname) && !loggedIn) {
		return (
			<>
				<Switch>
					{openRoutes.map(route => (
						<Route {...route} key={route.path || "not_found"} />
					))}
				</Switch>
				<Redirect to={pathname} />
			</>
		);
	}

	return (
		<>
			{ !loggedIn
				? <Redirect to="/iniciar-sesion" />
				: children }
		</>
	);
};

LoadContent.propTypes = {
	loggedIn     : PropTypes.bool,
	customerData : PropTypes.object,
	children     : PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

LoadContent.defaultProps = {
	loggedIn     : false,
	customerData : {}
};

const mapStateToProps = ({ authReducer : { loggedIn, customerData } }) => ({ loggedIn, customerData });

export default connect(mapStateToProps, null)(LoadContent);
