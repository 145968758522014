/* eslint-disable max-len */
import React                  from "react";
import PropTypes              from "prop-types";
import { makeStyles }         from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect }            from "react-redux";
import { format }             from "date-fns";
import {
	List,
	Divider,
	ListItem,
	Typography,
	ListItemText
} from "@material-ui/core";
import ModalActions   from "../../config/store/actions/modalActions.js";
import FloatingButton from "../FloatingButton";
import PendingForm    from "./PendingForm.jsx";

const useStyles = makeStyles(theme => ({
	root : {
		width           : "100%",
		backgroundColor : theme.palette.background.paper,
	},
	marginText : {
		margin : "auto 0px",
	},
	folioFont : {
		fontSize : "1rem",
	},
	dateRequired : {
		width       : "7rem",
		maxWidth    : "10rem",
		minWidth    : "6rem",
		marginRight : "0.5rem",
	},
	pendingDetails : {
		borderLeft  : "1px solid #d3d3d3",
		paddingLeft : "0.5rem",
	},
	block : {
		display : "block",
	},
	topHeader : {
		height          : "0.5rem",
		width           : "100%",
		backgroundColor : "#6179dd",
	}
}));

const Pendings = ({
	pendings,
	modalActions,
	render,
	customerId,
}) => {
	const classes = useStyles();

	const monthNames = [
		"Enero",
		"Feb",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Ago",
		"Sept",
		"Oct",
		"Nov",
		"Dic"
	];

	const handleCloseModal = (saved = undefined) => {
		if (saved === true) {
			render();
		}
		modalActions.closeModal();
	};

	const handleOpenPendingForm = () => {
		modalActions.openModal({
			title     : "Crear nueva consulta",
			iconTitle : "far fa-sticky-note",
			component : <PendingForm
				onClose={handleCloseModal}
				formData={{
					customer_id   : customerId,
					required_date : format(new Date(), "yyyy-MM-dd HH:mm"),
					type          : "Pendiente",
					priority      : "alta"
				}}
			/>
		});
	};

	const showDetails = e => (pending) => {
		modalActions.openModal({
			title     : "Detalles de Consulta",
			iconTitle : "far fa-sticky-note",
			component : <PendingForm onClose={handleCloseModal} formData={pending} mode="show" />
		});
	};

	return (
		<div className={classes.root}>
			<FloatingButton
				icon="far fa-plus"
				handleClick={handleOpenPendingForm}
			/>
			<div className={classes.topHeader} />
			<List component="nav" className={classes.root}>
				{pendings.map((pending) => {
					const newDate = new Date(pending.required_date.replace(" ", "T"));
					const day = newDate.getDate();
					const month = newDate.getMonth();
					const year = newDate.getFullYear();

					return (
						<div key={pending.id}>
							<ListItem
								button
								onClick={e => showDetails(e)(pending)}
								alignItems="center"
							>
								<ListItemText className={classes.dateRequired}>
									{newDate && (
										<>
											<Typography variant="h3" align="center" className={`${classes.marginText} ${classes.block}`}>
												{day}
											</Typography>
											<Typography variant="h6" align="center" className={`${classes.marginText} ${classes.folioFont}`}>
												{`${monthNames[month]} - ${year}`}
											</Typography>
										</>
									)}
								</ListItemText>
								<ListItemText
									className={classes.pendingDetails}
									primary={pending.name}
									secondary={(
										<>
											<Typography
												component="span"
												variant="body2"
												className={classes.block}
												color="textPrimary"
											>
												{ `Responsable: ${pending.employee_responsible_name}` }
											</Typography>
											<Typography component="span" variant="body2" className={classes.block} color="textPrimary">
												{`Fecha de la solicitud: ${pending.registry_date}`}
											</Typography>
											<Typography component="span" variant="body2" className={classes.block} color="textPrimary">
												{`Status: ${pending.status}`}
											</Typography>
										</>
									)}
								/>
							</ListItem>
							<Divider />
						</div>
					);
				})}
			</List>
		</div>
	);
};

Pendings.propTypes = {
	pendings     : PropTypes.array.isRequired,
	modalActions : PropTypes.object.isRequired,
	render       : PropTypes.func.isRequired,
	customerId   : PropTypes.number.isRequired
};

const mapStateToProps    = ({ modalReducer }) => ({ ...modalReducer });
const mapDispatchToProps = dispatch => ({ modalActions : bindActionCreators(ModalActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Pendings);
