import {
	OPEN_DRAWER,
	CLOSE_DRAWER
} from "../constants/actionTypes";

const openDrawer  = () => ({ type : OPEN_DRAWER });
const closeDrawer = () => ({ type : CLOSE_DRAWER });

const drawerActions = {
	openDrawer,
	closeDrawer,
};

export default drawerActions;
