/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Grid,
	withStyles,
	Dialog,
	Slide,
	Typography
} from "@material-ui/core";
import { connect }               from "react-redux";
import { bindActionCreators }    from "redux";
import { DatePicker }            from "@material-ui/pickers";
import { format }                from "date-fns";
import CardDirectory             from "../../components/CardDirectory";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";
import userService               from "../../config/services/userService";
import messaging                 from "../../init-fcm";
import ModalActions              from "../../config/store/actions/modalActions";
import WorkersDetails            from "../../components/WorkersDetails";
import RequestSelector           from "../../components/Requests/RequestSelector";
import { history }               from "../../config/helpers";

const styles = theme => ({
	root : {
		flexGrow : 1,
		padding  : 24,
	},
	paper : {
		padding   : theme.spacing(2),
		textAlign : "center",
		color     : theme.palette.text.secondary,
	},
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const HomePage = ({
	classes : {
		root,
	},
	customerId,
	userId,
	modalActions,
	userType,
	match
}) => {
	const initState = {
		activePendings        : 0,
		fileNumbers           : 0,
		own                   : 0,
		obligation            : 0,
		totalWorkers          : 0,
		responsibleCustomers  : 0,
		payrollRequest        : 0,
		employerRegistrations : [],
		customerCompliance    : 0,
		accountantCompliance  : 0,
		complianceId          : null,
		complianceDate        : new Date()
	};

	const [state, setState] = useState(initState);
	const [openSelector, setOpenSelector]	= useState(false);

	const handleRequestSelector = () => {
		setOpenSelector(!openSelector);
	};

	useEffect(() => {
		const requestMessagingPermission = async () => {
			try {
				messaging.requestPermission()
					.then(async () => {
						const token = await messaging.getToken();
						await userService.sendToken({
							token,
							userId,
						});
					})
					.catch((err) => {
						console.error("Unable to get permission to notify.", err);
					});
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		};
		const customerData = async () => {
			await requestMessagingPermission();
			try {
				const [fileResponse,
					pendingsResponse,
					statusAccountResponse,
					branchOfficeResponse,
					employerRegistrationResponse,
					complianceAdvanceResponse] = await Promise.all([
					userService.getFilesCustomer(customerId),
					userService.getPendingns({
						customer_id : customerId,
						status      : "activo",
					}),
					userService.getStatusAccount(customerId),
					userService.getCustomerBranchOffices({ customer_id : customerId }),
					userService.getEmployerRegistrations(customerId),
					// userService.getIMSSRequests({ customer_id : customerId }, false),
					userService.getComplianceAdvanceCount(customerId, { date : state.complianceDate.toJSON() })
				]);

				if (statusAccountResponse) {
					const fees = statusAccountResponse.response.reduce((accum, noteDetail) => {
						if (noteDetail.concept_type === "OWN" && noteDetail.status === "PENDING") {
							accum.own += parseFloat(noteDetail.total) - parseFloat(noteDetail.deposited);
						} else if (noteDetail.concept_type === "OBLIGATION" && noteDetail.status === "PENDING") {
							accum.obligation += parseFloat(noteDetail.total) - parseFloat(noteDetail.deposited);
						}
						return accum;
					}, {
						own        : 0,
						obligation : 0,
					});
					const totalWorkers = branchOfficeResponse.data.reduce((acc, i) => acc + i.workers_count, 0);

					setState({
						...state,
						activePendings        : pendingsResponse.response.pendings.length,
						fileNumbers           : fileResponse.pagination.count,
						obligation            : fees.obligation,
						own                   : fees.own,
						branchOfficeCount     : branchOfficeResponse.data.length,
						employerRegistrations : employerRegistrationResponse.data,
						totalWorkers,
						accountantCompliance  : complianceAdvanceResponse.data.accountant_compliance,
						customerCompliance    : complianceAdvanceResponse.data.customer_compliance,
						complianceId          : complianceAdvanceResponse.data.compliance_id
					});
				}
			} catch (err) {
				// console.log(err);
			}
		};

		const employeeData = async () => {
			await requestMessagingPermission();
			try {
				const [responsibleCustomers, pendingsResponse, payrollRequest] = await Promise.all([
					userService.getResponsibleCustomers(customerId),
					userService.getPendingns({
						employee_responsible_id : customerId,
						status                  : "activo",
					}),
					userService.getIMSSRequests({
						employee_id : customerId
					}, false),
				]);

				if (responsibleCustomers) {
					setState({
						activePendings       : pendingsResponse.response.pendings.length,
						responsibleCustomers : responsibleCustomers.response.customer.length,
						payrollRequest       : payrollRequest.data.length,
					});
				}
			} catch (err) {
				// console.log(err);
			}
		};

		if (userType === "customer") {
			customerData();
		} else {
			employeeData();
		}
	}, [customerId]);

	const openCompliance = type => (e) => {
		if (!state.complianceId) return;
		history.push(`cumplimineto/${type}/${state.complianceId}`);
	};

	const handleWorkersDetailsModal = () => {
		modalActions.openModal({
			title     : "Trabajadores por Sucursal",
			iconTitle : "fas fa-user-hard-hat",
			component : <WorkersDetails />,
		});
	};

	useEffect(() => {
		(async () => {
			const resp = await userService.getComplianceAdvanceCount(customerId, { date : (new Date(state.complianceDate)).toJSON() });
			setState(prevState => ({
				...prevState,
				accountantCompliance : resp.data.accountant_compliance,
				customerCompliance   : resp.data.customer_compliance,
				complianceId         : resp.data.compliance_id,
			}));
		})();
	}, [state.complianceDate]);

	const handleChangeState = name => (d) => {
		const value = d && d.target ? d.target.value : d;

		setState(prevState => ({ ...prevState, [name] : value }));
	};

	return (
		<div className={root}>
			{ userType === "customer" ? (
				<Grid container direction="column" justifyContent="center" alignItems="center">
					{/* <CardDirectory
					key="file-uploader"
					icon="far fa-paper-plane"
					title="Enviar estados de cuenta"
					color="#ffffff"
					background="#d45a08"
					link="/cargar_archivos"
				/> */}
					<Grid container item direction="row" justifyContent="space-around" alignItems="center" xs={12} sm={6} md={6} lg={5} xl={5}>
						<Grid onClick={openCompliance("contador")} item container direction="column" justifyContent="space-around" alignItems="center" xs={6}>
							<CircularProgressWithLabel size={100} value={state.accountantCompliance || 0} />
							<Typography type="h4">Avance despacho</Typography>
						</Grid>
						<Grid onClick={openCompliance("cliente")} item container direction="column" justifyContent="space-around" alignItems="center" xs={6}>
							<CircularProgressWithLabel size={100} value={state.customerCompliance || 0} />
							<Typography type="h4">Tu avance</Typography>
						</Grid>
						<Grid item xs={11}>
							<DatePicker
								views={["year", "month"]}
								autoOk
								fullWidth
								format="MMMM-yyyy"
								cancelLabel="Cancelar"
								showTodayButton
								todayLabel="Hoy"
								color="primary"
								orientation="portrait"
								openTo="month"
								minDate={new Date("2021-01-01")}
								maxDate={new Date()}
								label="Fecha de cumplimiento"
								inputVariant="standard"
								variant="dialog"
								value={state.complianceDate}
								onChange={date => handleChangeState("complianceDate")(format(date, "yyyy-MM-dd HH:mm"))}
							/>
						</Grid>
					</Grid>
					{state.fileNumbers > 0 && (
						<CardDirectory
							key="files"
							icon="far fa-cabinet-filing"
							title={`${state.fileNumbers} EXPEDIENTE FISCAL`}
							text="Estos son los documentos fiscales en tu cuenta actualmente"
							color="#f3f0f0"
							background="#c12424"
							link="/documentos"
						/>
					)}
					<CardDirectory
						key="pendings"
						icon="fas fa-bell-exclamation"
						title={`${state.activePendings} ASIGNAR UN PENDIENTE A MI CONTADOR`}
						color="#f3f0f0"
						background="#254eab"
						link="/consultas"
					/>
					{state.branchOfficeCount > 0 && (
						<CardDirectory
							key="workers"
							icon="fas fa-user-hard-hat"
							title={`${state.totalWorkers} ${state.totalWorkers === 1 ? "TRABAJADOR" : "TRABAJADORES"
							} REGISTRADOS EN ${state.branchOfficeCount === 1 ? "TU SUCURSAL"
								: `TUS ${state.branchOfficeCount} SUCURSALES`}`}
							text="Oprime para ver los trabajadores registrados por sucursal"
							color="#f3f0f0"
							background="#2b977f"
							onClick={() => handleWorkersDetailsModal()}
						/>
					)}
					{state.employerRegistrations.length > 0 && (
						<CardDirectory
							key="imss"
							icon="far fa-inbox-in"
							title="SOLICITUDES DE IMSS"
							text="Oprime para crear una nueva solicitud de IMSS"
							color="#f3f0f0"
							background="#13322b"
							onClick={handleRequestSelector}
						/>
					)}
					{/* <CardDirectory
					key="feesOwn"
					title={`${(state.own + state.obligation).toLocaleString("en-US", { style : "currency", currency : "USD" })} MNX`}
					text="Honorarios y Obligaciones pendientes de pago"
					color="#f3f0f0"
					background="#0a8570"
					link="/finanzas"
				/> */}
					<Dialog
						open={openSelector}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleRequestSelector}
						aria-labelledby="Crear nueva solicitud"
						aria-describedby="Crear nueva solicitud"
					>
						<RequestSelector match={{ url : "/solicitudes_imss/" }} modal />
					</Dialog>
				</Grid>
			) : (
				<Grid container direction="column" justify="center" alignItems="center">
					{/* <CardDirectory
						key="customers"
						icon="fas fa-child"
						title={`${state.responsibleCustomers} CLIENTES ASIGNADOS`}
						color="#ffffff"
						background="#d45a08"
						link="/"
					/> */}
					<CardDirectory
						key="pendings"
						icon="fas fa-bell-exclamation"
						title={`${state.activePendings} PENDIENTES ACTIVOS`}
						text="Revisa las solicitudes pendientes"
						color="#ffffff"
						background="#167cf3"
						link="/consultas"
					/>
					<CardDirectory
						key="requests"
						icon="far fa-inbox-in"
						title={`${state.payrollRequest} SOLICITUDES EN NÓMINAS`}
						text="Revisa las solicitudes de nóminas pendientes"
						color="#ffffff"
						background="#1b4f72"
						link="/solicitudes_imss"
					/>
				</Grid>
			)}
		</div>
	);
};

HomePage.propTypes = {
	classes      : PropTypes.object.isRequired,
	customerId   : PropTypes.number.isRequired,
	userId       : PropTypes.number.isRequired,
	userType     : PropTypes.string.isRequired,
	modalActions : PropTypes.object.isRequired,
	match        : PropTypes.object.isRequired,
};

const mapStateToProps = ({ authReducer : { userData, customerData, type } }) => ({
	customerId : customerData.id,
	userId     : userData.user_id,
	userType   : type,
});

const mapDispatchToProps = dispatch => ({
	modalActions : bindActionCreators(ModalActions, dispatch),
});

export default withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
