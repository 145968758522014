/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import PropTypes           from "prop-types";
import { connect }         from "react-redux";
import ExpandMoreIcon      from "@material-ui/icons/ExpandMore";
import {
	Grid,
	TextField,
	Button,
	Menu,
	MenuItem,
	makeStyles
} from "@material-ui/core";

// Import Own Components
import "./DisplayCustomerData.scss";

const useStyles = makeStyles(theme => ({
	DisplayMenuButton : {
		color : theme.palette.common.white,
	},
	expand : {
		transform  : "rotate(0deg)",
		transition : theme.transitions.create("transform", {
			duration : theme.transitions.duration.short,
		}),
	},
	expandOpen : {
		transform : "rotate(-180deg)",
	},
}));

const DisplayCustomerData = ({ customerData, userData }) => {
	const classes = useStyles();

	const [anchorElements, setAnchorElements] = useState({
		emails : null,
		phones : null,
	});

	const handleMenuBtnClick = ({ currentTarget }, key) => {
		setAnchorElements({
			...anchorElements,
			[key] : currentTarget,
		});
	};

	const handleMenuClose = (key) => {
		setAnchorElements({
			...anchorElements,
			[key] : null,
		});
	};

	return (
		<div className="DisplayCustomerData">
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<TextField
						label="Nombre"
						value={customerData.name}
						margin="normal"
						fullWidth
						InputProps={{
							readOnly : true,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Nombre de Contacto"
						value={customerData.contactname}
						margin="dense"
						fullWidth
						InputProps={{
							readOnly : true,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<div className="DisplayMenu">
						<Button
							variant="contained"
							color="secondary"
							className={classes.DisplayMenuButton}
							onClick={e => handleMenuBtnClick(e, "emails")}
						>
							{"Correos"}
							<div className={`${classes.expand} ${Boolean(anchorElements.emails) && classes.expandOpen}`}>
								<ExpandMoreIcon />
							</div>
						</Button>
						<Menu
							anchorEl={anchorElements.emails}
							open={Boolean(anchorElements.emails)}
							onClose={() => handleMenuClose("emails")}
							getContentAnchorEl={null}
							anchorOrigin={{
								vertical   : "bottom",
								horizontal : "center",
							}}
							transformOrigin={{
								vertical   : "top",
								horizontal : "center",
							}}
						>
							{ (Array.isArray(customerData.mails) && customerData.mails.length > 0) ? (
								customerData.mails.map(({ mail }, key) => (
									<MenuItem key={key}>
										{ mail }
									</MenuItem>
								))
							) : (
								<MenuItem>
									{ userData.email }
								</MenuItem>
							) }
						</Menu>
					</div>
				</Grid>
				<Grid item xs={6} md={7}>
					<TextField
						label="Calle"
						value={customerData.street}
						margin="dense"
						fullWidth
						InputProps={{
							readOnly : true,
						}}
					/>
				</Grid>
				<Grid item xs={3} md={3}>
					<TextField
						label="Num. Ext"
						value={customerData.outdoor_number}
						margin="dense"
						fullWidth
						InputProps={{
							readOnly : true,
						}}
					/>
				</Grid>
				<Grid item xs={3} md={2}>
					<TextField
						label="Num. Int"
						value={customerData.indoor_number}
						margin="dense"
						fullWidth
						InputProps={{
							readOnly : true,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Estado"
						value={customerData.state}
						margin="dense"
						fullWidth
						InputProps={{
							readOnly : true,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Código Postal"
						value={customerData.cp}
						margin="dense"
						fullWidth
						InputProps={{
							readOnly : true,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Colonia"
						value={customerData.colony}
						margin="dense"
						fullWidth
						InputProps={{
							readOnly : true,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Municipio"
						value={customerData.city}
						margin="dense"
						fullWidth
						InputProps={{
							readOnly : true,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<div className="DisplayMenu">
						<Button
							variant="contained"
							color="secondary"
							className={classes.DisplayMenuButton}
							onClick={e => handleMenuBtnClick(e, "phones")}
						>
							{"Teléfonos"}
							<div className={`${classes.expand} ${Boolean(anchorElements.phones) && classes.expandOpen}`}>
								<ExpandMoreIcon />
							</div>
						</Button>
						<Menu
							anchorEl={anchorElements.phones}
							open={Boolean(anchorElements.phones)}
							onClose={() => handleMenuClose("phones")}
							getContentAnchorEl={null}
							anchorOrigin={{
								vertical   : "bottom",
								horizontal : "center",
							}}
							transformOrigin={{
								vertical   : "top",
								horizontal : "center",
							}}
						>
							{ (Array.isArray(customerData.phones) && customerData.phones.length > 0) ? (
								customerData.phones.map(({ number }, key) => (
									<MenuItem key={key}>
										{ number }
									</MenuItem>
								))
							) : (
								<MenuItem>
									{ "No hay ningún teléfono" }
								</MenuItem>
							) }
						</Menu>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

DisplayCustomerData.propTypes = {
	customerData : PropTypes.object.isRequired,
	userData     : PropTypes.object.isRequired,
};

const mapStateToProps = ({ authReducer : { customerData, userData } }) => ({ customerData, userData });

export default connect(mapStateToProps, null)(DisplayCustomerData);
