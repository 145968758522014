import React            from "react";
import PropTypes        from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles }   from "@material-ui/core/styles";
import Typography       from "@material-ui/core/Typography";
import Box              from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
	root : {
		position : "relative",
	},
	bottom : {
		color : theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
	},
	top : {
		color             : "#1a90ff",
		animationDuration : "550ms",
		position          : "absolute",
		left              : 0,
	},
	circle : {
		strokeLinecap : "round",
	},
}));

function CircularProgressWithLabel({
	value, labelClassName, primaryClass, secondaryClass, ...props
}) {
	const classes = useStyles();
	return (
		<Box position="relative" display="inline-flex" className={classes.root}>
			<CircularProgress
				variant="determinate"
				className={`${classes.bottom} ${secondaryClass}`}
				value={100}
				{...props}
			/>
			<CircularProgress
				variant="determinate"
				className={`${classes.top} ${primaryClass}`}
				classes={{
					circle : classes.circle,
				}}
				value={value}
				{...props}
			/>
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography
					variant="caption"
					component="div"
					color="textSecondary"
					className={labelClassName}
				>
					{`${Math.round(
						value,
					)}%`}
				</Typography>
			</Box>
		</Box>
	);
}

CircularProgressWithLabel.propTypes = {
	/**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
	value          : PropTypes.number.isRequired,
	labelClassName : PropTypes.string,
	primaryClass   : PropTypes.string,
	secondaryClass : PropTypes.string,
};

CircularProgressWithLabel.defaultProps = {
	labelClassName : "",
	primaryClass   : "",
	secondaryClass : "",
};

export default CircularProgressWithLabel;
