/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
import React          from "react";
import PropTypes      from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";

const defaultStyles = {
	color           : theme => theme.palette.common.white,
	background      : theme => theme.palette.primary.main,
	hoverBackground : theme => theme.palette.primaryShades[400],
};

const useStyles = makeStyles(theme => ({
	fab : {
		position        : "fixed",
		bottom          : theme.spacing(10),
		right           : theme.spacing(4),
		color           : ({ color }) => typeof color === "string" ? color : color(theme),
		backgroundColor : ({ background }) => typeof background === "string" ? background : background(theme),
		"&:hover"       : {
			backgroundColor : ({ hoverBackground }) => typeof hoverBackground === "string" ? hoverBackground : hoverBackground(theme),
		},
		zIndex : "1000",
	},
}));

const FloatingButton = ({
	handleClick,
	styles,
	icon,
	size
}) => {
	const newStyles = Object.assign({}, defaultStyles, styles);
	const classes   = useStyles(newStyles);

	return (
		<Fab className={classes.fab} onClick={handleClick} size={size}>
			<i className={icon} />
		</Fab>
	);
};

FloatingButton.propTypes = {
	handleClick : PropTypes.func.isRequired,
	styles      : PropTypes.object,
	icon        : PropTypes.string.isRequired,
	size        : PropTypes.oneOf(["small", "medium", "large"]),
};

FloatingButton.defaultProps = {
	styles : defaultStyles,
	size   : "large"
};

export default FloatingButton;
