import { combineReducers } from "redux";

// Import Own Components
import loadingReducer from "./loadingReducer";
import authReducer    from "./authReducer";
import modalReducer   from "./modalReducer";
import drawerReducer  from "./drawerReducer";

const rootReducer = combineReducers({
	loadingReducer,
	authReducer,
	modalReducer,
	drawerReducer,
});

export default rootReducer;
