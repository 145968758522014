import React        from "react";
import PropTypes    from "prop-types";
import {
	Grid
} from "@material-ui/core";

import "./ListFiles.scss";

const Preview = ({ src, type }) => (

	<Grid container className="Preview">
		{
			type === "pdf" || type === "PDF" ? (
				<iframe title={src} src={src} className="pdfIFrame" frameBorder="0" />
			) : (
				<img alt={src} src={src} className="imgIFrame" />
			)
		}
	</Grid>
);

Preview.propTypes = {
	src  : PropTypes.string.isRequired,
	type : PropTypes.string.isRequired,
};

export default Preview;
