import {
	SET_USER_DATA,
	LOGIN_SUCCESS,
	SET_CUSTOMER_DATA,
	SET_EMPLOYEE_DATA,
	CLEAR_USER
} from "../constants/actionTypes";

const authReducer = (state = {}, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...action.payload,
			};
		case SET_CUSTOMER_DATA:
			return {
				...state,
				type      			: "customer",
				customerData : action.payload,
				loggedIn     : true,				// Para este punto, toda la información ya estará en el store
			};
		case SET_USER_DATA:
			return {
				...state,
				userData : action.payload,
			};
		case SET_EMPLOYEE_DATA:
			return {
				...state,
				type      			: "employee",
				customerData	: action.payload,
				loggedIn   		: true,
			};
		case CLEAR_USER:
			return {};
		default:
			return state;
	}
};

export default authReducer;
