import * as firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
	apiKey            : "AIzaSyC1VuOYfiydIQU6U0T4xA8HJ1O92tKxGBY",
	authDomain        : "contadoresmac-ecd58.firebaseapp.com",
	databaseURL       : "https://contadoresmac-ecd58.firebaseio.com",
	projectId         : "contadoresmac-ecd58",
	storageBucket     : "",
	messagingSenderId : "84947197339",
	appId             : "1:84947197339:web:ce637f83e2af3a9516553e"
};

// eslint-disable-next-line import/no-mutable-exports
let messaging;

try {
	const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);

	messaging = initializedFirebaseApp.messaging();
	messaging.usePublicVapidKey(
		// Project Settings => Cloud Messaging => Web Push certificates
		"BO0OPwfU89QXVUEYe8SvPfqgQOhOee7YPdFLKd-eMqduRB5wtAJwdAcP6gaEWP7AKssoT8PWz2VV56_S2a2koPE"
	);
} catch (err) {
	// eslint-disable-next-line no-console
	console.log("Service worker registration failed, error:", err);
}
export default messaging;
