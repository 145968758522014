/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import React, {
	useState,
	useEffect,
	useRef
} from "react";
import PropTypes	from "prop-types";
import { format }	from "date-fns";
import { connect }	from "react-redux";
import {
	TextField,
	Button,
	Grid,
	Divider,
	Typography,
	LinearProgress,
	Box,
	Switch
} from "@material-ui/core";
import swal				from "sweetalert2";
import esLocale			from "date-fns/locale/es";

// Import userService
import userService		from "../../../config/services/userService";

// import own components
import {
	initState,
	validFormInit,
	statusLabel
}		from "./const";
import FormButtons			from "../Components/FormButtons";
import WorkerSalary			from "../Components/WorkerSalaries";
import CustomerIdentifier	from "../Components/CustomerIdentifier";
import EmployeeIdentifier   from "../Components/EmployeeIdentifier";
import CustomDatePicker		from "../Components/CustomDatePicker";
import "./RegisterWorker.scss";

const RegisterWorker = ({
	customerData, loggedIn, match, mode, requestData
}) => {
	if (mode === "VIEW" && !requestData.imss_request) {
		return (
			<Box style={{ width : "100%" }}>
				<LinearProgress />
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="caption" component="div" color="textSecondary">
						Cargando datos de la solicitud
					</Typography>
				</Box>
			</Box>

		);
	}

	const [registerData, setRegisterData]	= useState(initState);
	const [validForm, setValidForm]			= useState(validFormInit);

	const isDisabled = () => (mode === "VIEW");

	useEffect(() => {
		if (mode === "VIEW" && requestData.imss_request) {
			const request = {
				customerName          	: requestData.imss_request.customer_name,
				branchOffice          	: requestData.imss_request.branch_office,
				workerName            	: requestData.imss_request.worker_name,
				workerLastName        	: requestData.imss_request.worker_lastname,
				workerRFC             	: requestData.imss_request.worker_rfc,
				workerNSS             	: requestData.imss_request.worker_nss,
				workerCURP            	: requestData.imss_request.worker_curp,
				hasInfonavitCredit    	: !!requestData.imss_request.has_infonavit_credit,
				hasFonacotCredit      	: !!requestData.imss_request.has_fonacot_credit,
				retentionFileId       	: requestData.imss_request.retention_file_id,
				retentionFileFonacotId	: requestData.imss_request.retention_file_fonacot_id,
				monthlySalary         	: parseFloat(requestData.imss_request.monthly_salary),
				biweeklySalary        	: parseFloat(requestData.imss_request.biweekly_salary),
				weeklySalary          	: parseFloat(requestData.imss_request.weekly_salary),
				details               	: requestData.imss_request.details,
				movementDate          	: requestData.movement_date,
			};

			setRegisterData({
				...registerData,
				formData : {
					...request
				}
			});
		}
	}, [requestData]);

	const downloadFile = (urlFile, name) => {
		fetch(urlFile, {
			method : "get",
		}).then(response => response.blob()).then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", name);
			document.body.appendChild(link);
			link.click();
		});
	};

	const handleValidation = async (name, valid) => {
		await setValidForm({
			...validForm,
			[name] : valid,
		});
	};

	const salariesRef = useRef(null);
	const identifierRef = useRef(null);
	const employeeRef = useRef(null);

	const clearForm = () => {
		salariesRef.current.cleanValue();
		identifierRef.current.cleanValues();
		employeeRef.current.cleanValues();
		setRegisterData(initState);
	};

	const handleChange = name => (d) => {
		if (name === "salaries" || name === "branchOfficeId") {
			handleValidation(name, true);
			setRegisterData({
				...registerData,
				formData : {
					...registerData.formData,
					...d
				}
			});
			return;
		}
		const value = d && d.target ? d.target.value : d;
		handleValidation(name, true);
		setRegisterData({
			...registerData,
			formData : {
				...registerData.formData,
				[name] : value,
			}
		});
	};


	const handleChangeMulti = (obj) => {
		setRegisterData({
			...registerData,
			formData : {
				...registerData.formData,
				...obj
			}
		});
	};

	const generateConfirmationHTML = (formData) => {
		let html = "<table style='width: 100%;border-collapse: collapse;'><tbody>";
		const lhtml = "<tr style='border-bottom: 1px solid #e3e3e3;'><td style=\"text-align:left;\">";
		const mhtml = "</td><td style=\"text-align:right;word-wrap:anywhere;\">";
		const rhtml = "</td></tr>";
		for (const key in formData) {
			if (formData.customerName && key === "customerName") {
				html += lhtml + statusLabel.customerName + mhtml + formData.customerName + rhtml;
			} else if (key === "movementDate") {
				html += lhtml + statusLabel.movementDate + mhtml
					+ format(new Date(formData[key]), "dd-MMMM-yyyy", { locale : esLocale }) + rhtml;
			} else if (key === "hasFonacotCredit") {
				html += lhtml + statusLabel.hasFonacotCredit + mhtml
					+ (formData.hasFonacotCredit ? "Si" : "No") + rhtml;
			} else if (key === "hasInfonavitCredit") {
				html += lhtml + statusLabel.hasInfonavitCredit + mhtml
					+ (formData.hasInfonavitCredit ? "Si" : "No") + rhtml;
			} else if (key === "branchOfficeId" || key === "customerId") {
				continue;
			} else if (typeof formData[key] === "number") {
				html += lhtml + statusLabel[key] + mhtml
					+ formData[key].toLocaleString("en-US", { style : "currency", currency : "MXN" }) + rhtml;
			} else if (key === "retentionFile" || key === "retentionFileFonacot") {
				html += lhtml + statusLabel[key] + mhtml + formData[key].name + rhtml;
			} else {
				html += lhtml + statusLabel[key] + mhtml + formData[key] + rhtml;
			}
		}

		return `${html}</tbody></table>`;
	};

	const formValidate = async () => new Promise((resolve) => {
		const validate = {
			validate	: false,
			error  		: "Debes completar el formulario"
		};
		const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
		const reCurp = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;

		const validFormSync = {};
		Object.assign(validFormSync, validFormInit);
		for (const key in validFormInit) {
			if (key === "salaries") continue;
			if (!registerData.formData[key]) {
				validFormSync[key] = false;
			}
		}
		if (!registerData.formData.monthlySalary
			|| !registerData.formData.biweeklySalary
			|| !registerData.formData.weeklySalary) {
			validFormSync.salaries = false;
		}

		setValidForm(validFormSync);
		if (registerData.formData.hasInfonavitCredit && !registerData.formData.retentionFile) {
			validate.error = "Es obligatorio agregar la hoja de retención de Infonavit";
			validate.validate = false;
			resolve(validate);
			return;
		}

		if (registerData.formData.hasFonacotCredit && !registerData.formData.retentionFileFonacot) {
			validate.error = "Es obligatorio agregar la hoja de retención de Fonacot";
			validate.validate = false;
			resolve(validate);
			return;
		}

		if ((registerData.formData.customerName
			|| registerData.formData.customerId)
			&& (registerData.formData.branchOffice
			|| registerData.formData.branchOfficeId)
			&& registerData.formData.workerName
			&& registerData.formData.workerRFC
			&& registerData.formData.monthlySalary
			&& registerData.formData.biweeklySalary
			&& registerData.formData.weeklySalary) {
			if (!registerData.formData.workerRFC.match(re)) {
				validate.error = "El RFC no es Valido";
				handleValidation("workerRFC", false);
			} else if (!registerData.formData.workerCURP.match(reCurp)) {
				validate.error = "La CURP no es Valida";
				handleValidation("workerCURP", false);
			} else {
				validate.validate = true;
			}
			resolve(validate);
		}

		resolve(validate);
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		setRegisterData({
			...registerData,
			submitted : true,
		});

		try {
			const validateForm = await formValidate();
			if (validateForm.validate) {
				const formData = {};
				if (!registerData.formData.hasInfonavitCredit) { registerData.formData.retentionFile = null; }
				if (!registerData.formData.hasFonacotCredit) { registerData.formData.retentionFileFonacot = null; }

				for (const key in registerData.formData) {
					if (registerData.formData[key] || typeof registerData.formData[key] === "boolean") {
						formData[key] = registerData.formData[key];
					}
				}
				swal.fire({
					title             : "Revisar Datos",
					html              : generateConfirmationHTML(formData),
					icon              : "info",
					width             : 700,
					showCancelButton  : true,
					confirmButtonText : "Enviar",
					cancelButtonText  : "Cancelar",
				}).then(async (result) => {
					if (result.isConfirmed) {
						const response = await userService.createRequest({
							request     : JSON.stringify(formData),
							requestType : "REGISTER_WORKER",
						},
						formData.retentionFile,
						formData.retentionFileFonacot);
						if (response.status === 200) {
							swal.fire("!Enviado!", "Tu solicitud ha sido enviada correctamente!", "success");
							clearForm();
						} else {
							swal.fire("!Error!", response.label, "error");
						}
					}
				});
			} else {
				swal.fire("Error", validateForm.error, "warning");
			}
		} catch (error) {
			swal.fire(`Error ${error}`, "error");
		}
	};

	return (
		<div className="RegisterWorker">
			<div className="topHeader" />
			<CustomerIdentifier
				handleChange={handleChange}
				validForm={validForm}
				status={registerData}
				requireBranchOffice
				match={match}
				mode={mode}
				isDisabled={isDisabled}
				handleChangeMulti={handleChangeMulti}
				ref={identifierRef}
				clearForm={clearForm}
			/>

			{/* Separator */}
			<Grid
				container
				justify="center"
				alignItems="center"
			>
				<Grid item xs={11}>
					<Divider />
				</Grid>
			</Grid>
			<Grid
				container
				spacing={2}
				justify="center"
				alignItems="flex-start"
			>
				<Grid item md={8} xs={11} container spacing={2} justify="center">
					<Grid item xs={11}>
						<Typography variant="h5" component="h2" style={{ paddingBottom : "1rem", textAlign : "center" }}>
							Datos del Empleado a Registrar
						</Typography>
					</Grid>
					<Grid item md={6} container direction="column" spacing={2}>
						<Grid item xs>
							<EmployeeIdentifier
								key={registerData.formData.customerId}
								handleChange={handleChange}
								validForm={validForm}
								status={registerData}
								mode={mode}
								isDisabled={isDisabled}
								setEmployee={() => {}}
								ref={employeeRef}
								customerId={registerData.formData.customerId}
								branchOfficeId={registerData.formData.branchOfficeId}
								selectEmployee={false}
							/>
						</Grid>
						<Grid item xs>
							<TextField
								required
								InputProps={{
									readOnly : isDisabled(),
								}}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								inputProps={{
									minLength   : 11,
									maxLength   : 11,
									placeholder : "00000000000",
									className  	: "validation"
								}}
								value={registerData.formData.workerNSS ? registerData.formData.workerNSS : ""}
								error={!validForm.workerNSS}
								className="formControl"
								id="worker-nss"
								label="Número de Seguro Social del Trabajador"
								onChange={e => handleChange("workerNSS")((({ target }) => target.value.replace(/[^\d\.]/g, "").slice(0, 11))(e))}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								required
								InputProps={{
									readOnly : isDisabled(),
									style    : { textTransform : "uppercase" }
								}}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								inputProps={{
									minLength   : 18,
									// maxLength   : 18,
									placeholder : "AAAA000000AAAAAA00",
									className  	: "validation"
								}}
								value={registerData.formData.workerCURP ? registerData.formData.workerCURP : ""}
								error={!validForm.workerCURP}
								className="formControl"
								id="worker-curp"
								label="CURP del Trabajador"
								onChange={e => handleChange("workerCURP")((({ target }) => target.value.toUpperCase().replaceAll("-", "").slice(0, 18))(e))}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid item md={6} container direction="column" spacing={2}>
						<Grid item xs>
							<TextField
								required
								InputProps={{
									readOnly : isDisabled(),
									style    : { textTransform : "uppercase" }
								}}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								inputProps={{
									minLength   : 12,
									// maxLength   : 13,
									placeholder : "AAAA000000AA0",
									className  	: "validation"
								}}
								value={registerData.formData.workerRFC ? registerData.formData.workerRFC : ""}
								error={!validForm.workerRFC}
								className="formControl"
								id="worker-rfc"
								label="RFC del Trabajador"
								onChange={e => handleChange("workerRFC")((({ target }) => target.value.toUpperCase().replaceAll("-", "").slice(0, 13))(e))}
								variant="outlined"
							/>
						</Grid>
						<Grid component="label" container alignItems="center" spacing={1}>
							<Grid item>No</Grid>
							<Grid item>
								<Switch
									checked={registerData.formData.hasInfonavitCredit}
									onChange={
										e => handleChange("hasInfonavitCredit")(e.target.checked)
									}
									color="primary"
									disabled={isDisabled()}
								/>
							</Grid>
							<Grid item>Si</Grid>
							<Grid item>
								¿EL TRABAJADOR CUENTA CON CRÉDITO INFONAVIT?
							</Grid>
						</Grid>
						{
							registerData.formData.hasInfonavitCredit && !isDisabled() ? (
								<Grid item xs>
									<label htmlFor="retentionFile">
										<input
											style={{ display : "none" }}
											id="retentionFile"
											name="retentionFile"
											type="file"
											// accept="image/*, .pdf"
											onChange={
												e => handleChange("retentionFile")(e.target.files[0])
											}
										/>

										<Button
											className="btn-green"
											variant="outlined"
											color="primary"
											component="span"
										>
											ADJUNTAR HOJA DE RETENCIÓN INFONAVIT
											{" "}
											{
												registerData.formData.retentionFile
													? ` - ${registerData.formData.retentionFile.name}` : null
											}
										</Button>
									</label>
								</Grid>
							) : null
						}
						{
							registerData.formData.hasInfonavitCredit && isDisabled()
								? (
									<Grid item xs>
										<Button
											className="btn-green"
											variant="outlined"
											color="primary"
											component="span"
											onClick={() => downloadFile(
												`${process.env.REACT_APP_SYSCONTROL_API}/storage/file/${registerData.formData.retentionFileId}`,
												`HOJA_RETENCIÓN_INFONAVIT-${registerData.formData.workerName}`
											)}
										>
										DESCARGAR HOJA DE RETENCIÓN INFONAVIT
										</Button>
									</Grid>
								) : null
						}
						<Grid component="label" container alignItems="center" spacing={1}>
							<Grid item>No</Grid>
							<Grid item>
								<Switch
									checked={registerData.formData.hasFonacotCredit}
									onChange={
										e => handleChange("hasFonacotCredit")(e.target.checked)
									}
									color="primary"
									disabled={isDisabled()}
								/>
							</Grid>
							<Grid item>Si</Grid>
							<Grid item>
								¿EL TRABAJADOR CUENTA CON CRÉDITO FONACOT?
							</Grid>
						</Grid>
						{
							registerData.formData.hasFonacotCredit && !isDisabled() ? (
								<Grid item xs>
									<label htmlFor="retentionFileFonacot">
										<input
											style={{ display : "none" }}
											id="retentionFileFonacot"
											name="retentionFileFonacot"
											type="file"
											// accept="image/*, .pdf"
											onChange={
												e => handleChange("retentionFileFonacot")(e.target.files[0])
											}
										/>

										<Button
											className="btn-green"
											variant="outlined"
											color="primary"
											component="span"
										>
											ADJUNTAR HOJA DE RETENCIÓN FONACOT
											{" "}
											{
												registerData.formData.retentionFileFonacot
													? ` - ${registerData.formData.retentionFileFonacot.name}` : null
											}
										</Button>
									</label>
								</Grid>
							) : null
						}
						{
							registerData.formData.hasFonacotCredit && isDisabled()
								? (
									<Grid item xs>
										<Button
											className="btn-green"
											variant="outlined"
											color="primary"
											component="span"
											onClick={() => downloadFile(
												`${process.env.REACT_APP_SYSCONTROL_API}/storage/file/${registerData.formData.retentionFileFonacotId}`,
												`HOJA_RETENCIÓN_FONACOT_-${registerData.formData.workerName}`
											)}
										>
										DESCARGAR HOJA DE RETENCIÓN FONACOT
										</Button>
									</Grid>
								) : null
						}
					</Grid>
					<WorkerSalary
						ref={salariesRef}
						handleChange={handleChange}
						validForm={validForm}
						mode={mode}
						status={registerData}
						isDisabled={isDisabled}
					/>
					<Grid item xs={12}>
						<Typography component="h3" style={{ padding : "1rem 0 1rem 0" }}>EXTRAS</Typography>
						<TextField
							id="details"
							InputProps={{
								readOnly : isDisabled(),
							}}
							value={registerData.formData.details ? registerData.formData.details : ""}
							className="formControl"
							onChange={handleChange("details")}
							label="Detalles u Observaciones"
							multiline
							rows={4}
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<Grid item md={3} xs={11} container spacing={2} direction="column" justify="center">
					<CustomDatePicker handleChange={handleChange} movementDate={registerData.formData.movementDate} mode={mode} isDisabled={isDisabled} />
					{ mode !== "VIEW" && (
						<FormButtons handleSubmit={handleSubmit} match={match} />
					)}
				</Grid>
			</Grid>
			<hr style={{ border : "1rem solid #fff" }} />
		</div>
	);
};

RegisterWorker.propTypes = {
	match     			: PropTypes.object.isRequired,
	customerData	: PropTypes.object,
	loggedIn    	: PropTypes.bool,
	mode      			: PropTypes.string,
	requestData		: PropTypes.object
};

RegisterWorker.defaultProps = {
	customerData	: {},
	loggedIn   		: false,
	mode      			: "NEW",
	requestData		: {}
};

const mapStateToProps = ({ authReducer: { customerData, loggedIn } }) => ({
	customerData,
	loggedIn
});

export default connect(mapStateToProps, null)(RegisterWorker);
