/* eslint-disable camelcase */
import React        from "react";
import PropTypes	from "prop-types";
import { connect }	from "react-redux";

// import own components
import CardDirectory			from "../../CardDirectory";
import ModuleContainer			from "../../ModuleContainer";
import { directoriesProperties } from "./const";
import "./RequestSelector.scss";

const RequestSelector = ({
	match, modal, loggedIn, type, allRequestOpt
}) => {
	const base_url		= match.url.substr(0, match.url.indexOf("imss") + 4);
	const actual_url	= match.url.substr(match.url.indexOf("imss") + 4);

	return (
		<div className="RequestSelector">
			{ !modal ? (
				<ModuleContainer
					title="SOLICITUDES PARA IMSS"
					color="#f3f0f0"
					background="#13322b"
					icon="fas fa-inbox-in"
				>
					<div className="topHeader" />

					<h2>
						Selecciona el tipo de movimiento que deseas realizar
					</h2>

					{directoriesProperties.map(p => (
						p.open && (
							<CardDirectory
								md={10}
								lg={10}
								sm={10}
								xl={10}
								background={p.background ? p.background : "#ff7f0a"}
								key={p.key}
								color="#f3f0f0"
								link={`${match.url}${p.link}`}
							>
								<h3 className="requestButton">
									<i className={p.icon} />
								&nbsp;
									{p.title}
								</h3>
							</CardDirectory>
						)
					))}
				</ModuleContainer>
			) : (
				<>
					<h2>
						Selecciona el tipo de movimiento que deseas realizar
					</h2>
					{(actual_url && loggedIn) || allRequestOpt ? (
						<CardDirectory
							className="cardDirectory"
							md={12}
							lg={12}
							sm={12}
							xl={12}
							background="#2b977f"
							color="#f3f0f0"
							link="/solicitudes_imss"
						>
							<h3 className="requestButton">
								<i className="far fa-inbox-in" />
							&nbsp;
							Ver las solicitudes realizadas
							</h3>
						</CardDirectory>
					) : null}

					{directoriesProperties.map(p => (p.link !== actual_url	&& ((p.customer && type === "customer") || (!p.customer && type === "employee") || p.open) ? (
						<CardDirectory
							className="cardDirectory"
							md={12}
							lg={12}
							sm={12}
							xl={12}
							background={p.background ? p.background : "#ff7f0a"}
							key={p.key}
							color="#f3f0f0"
							link={`${base_url}${p.link}`}
						>
							<h3 className="requestButton">
								<i className={p.icon} />
								&nbsp;
								{p.title}
							</h3>
						</CardDirectory>
					) : null))}
				</>
			)}
		</div>
	);
};


RequestSelector.propTypes = {
	match         : PropTypes.object.isRequired,
	type          : PropTypes.string.isRequired,
	modal         : PropTypes.bool,
	loggedIn      : PropTypes.bool,
	allRequestOpt : PropTypes.bool
};

RequestSelector.defaultProps = {
	modal         : false,
	loggedIn      : false,
	allRequestOpt : false
};

const mapStateToProps = ({ authReducer: { loggedIn, type } }) => ({
	type,
	loggedIn,
});

export default connect(mapStateToProps, null)(RequestSelector);
