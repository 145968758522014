import React      from "react";
import { render } from "react-dom";

// Import Own Components
import App                   from "./App.jsx";
import registerServiceWorker from "./registerServiceWorker";

registerServiceWorker();

const showNotification = async (notification) => {
	// TODO: Enviar Notificación
	const registration = await navigator.serviceWorker.getRegistration();
	if (!registration) {
		// return alert("No hay un Service Worker :(");
	}

	return registration.showNotification(
		notification.title,
		{
			// actions : [{
			// 	title  : "link",
			// 	action : notification.click_action,
			// 	icon   : notification.icon,
			// }],
			body    : notification.body,
			icon    : notification.icon,
			vibrate : [200, 100, 200, 100, 200, 100, 200],
		}
	);
};

if ("serviceWorker" in navigator) {
	navigator.serviceWorker.register("../firebase-messaging-sw.js")
		.then((registration) => {
			navigator.serviceWorker.addEventListener("message", (message) => {
				showNotification(message.data["firebase-messaging-msg-data"].notification);
			});
			// navigator.serviceWorker.addEventListener("notificationclick", (event) => {
			// 	console.log(event);
			// });
		}).catch((err) => {
			// console.log("Service worker registration failed, error:", err);
		});
}

render(
	<App />,
	document.getElementById("root")
);
