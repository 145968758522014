import React     from "react";
import PropTypes from "prop-types";

const ToggleIcon = ({ main, secondary, useSecondaryWhen }) => {
	const Main = props => (
		<div {...props}>
			<i className={main} />
		</div>
	);

	const Secondary = props => (
		<div {...props}>
			<i className={secondary} />
		</div>
	);

	return (
		useSecondaryWhen
			? <Secondary />
			: <Main />
	);
};

ToggleIcon.propTypes = {
	main             : PropTypes.string.isRequired,
	secondary        : PropTypes.string.isRequired,
	useSecondaryWhen : PropTypes.bool.isRequired,
};

export default ToggleIcon;
