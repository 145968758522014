
import React, {
	useState
} from "react";
import PropTypes	from "prop-types";
import {
	Grid,
	Typography,
	Button,
	Dialog,
	Slide
} from "@material-ui/core";

// import own components
import RequestSelector	from "../../RequestSelector";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FormButtons = ({ handleSubmit, match }) => {
	const [openSelector, setOpenSelector]	= useState(false);

	const handleRequestSelector = () => {
		setOpenSelector(!openSelector);
	};

	return (
		<>
			<Grid item xs={12}>
				<Button className="btn-green submitButton" variant="outlined" color="primary" onClick={handleSubmit}>
					<Typography variant="h6" component="legend">GUARDAR SOLICITUD DEL TRAMITE</Typography>
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Button className="btn-green" variant="outlined" color="primary" onClick={handleRequestSelector}>
						CAMBIAR TIPO DE SOLICITUD
				</Button>
			</Grid>
			<Dialog
				open={openSelector}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleRequestSelector}
				aria-labelledby="Crear nueva solicitud"
				aria-describedby="Crear nueva solicitud"
			>
				<RequestSelector match={match} modal />
			</Dialog>
		</>
	);
};

FormButtons.propTypes = {
	handleSubmit : PropTypes.func.isRequired,
	match        : PropTypes.object.isRequired,
};

export default FormButtons;
