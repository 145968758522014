/* eslint-disable no-continue */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import React, {
	useState,
	useEffect,
	useRef
} from "react";
import PropTypes  from "prop-types";
import { connect } from "react-redux";
import {
	FormControl,
	TextField,
	Button,
	Grid,
	Divider,
	Typography,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Box,
	LinearProgress
} from "@material-ui/core";
import swal					from "sweetalert2";

// Import userService
import userService			from "../../../config/services/userService";
// import own components
import FormButtons			from "../Components/FormButtons";
import CustomerIdentifier	from "../Components/CustomerIdentifier";
import EmployeeIdentifier   from "../Components/EmployeeIdentifier";
import {
	initState,
	validFormInit,
	statusLabel,
	creditTypes
}	from "./const";
import "./EmploymentCredit.scss";


const EmploymentCredit = ({
	customerData, loggedIn, match, mode, requestData
}) => {
	if (mode === "VIEW" && !requestData.imss_request) {
		return (
			<Box style={{ width : "100%" }}>
				<LinearProgress />
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="caption" component="div" color="textSecondary">
						Cargando datos de la solicitud
					</Typography>
				</Box>
			</Box>

		);
	}

	const [employmentCreditData, setEmploymentCreditData]	= useState(initState);
	const [validForm, setValidForm]						= useState(validFormInit);

	const isDisabled = () => (mode === "VIEW");

	useEffect(() => {
		if (mode === "VIEW" && requestData.imss_request) {
			const request = {
				customerName   : requestData.imss_request.customer_name,
				workerName     : requestData.imss_request.worker_name,
				workerLastName : requestData.imss_request.worker_lastname,
				creditType     : requestData.imss_request.credit_type,
				creditFile     : requestData.imss_request.credit_file,
				details        : requestData.imss_request.details,
			};

			setEmploymentCreditData({
				...employmentCreditData,
				formData : {
					...request
				}
			});
		}
	}, [requestData]);

	const downloadFile = (urlFile, name) => {
		fetch(urlFile, {
			method : "get",
		}).then(response => response.blob()).then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", name);
			document.body.appendChild(link);
			link.click();
		});
	};

	const handleValidation = async (name, valid) => {
		await setValidForm({
			...validForm,
			[name] : valid,
		});
	};

	const identifierRef = useRef(null);
	const employeeRef = useRef(null);

	const clearForm = () => {
		identifierRef.current.cleanValues();
		employeeRef.current.cleanValues();
		setEmploymentCreditData(initState);
	};

	const handleChange = name => (d) => {
		const value = d && d.target ? d.target.value : d;
		handleValidation(name, true);
		setEmploymentCreditData({
			...employmentCreditData,
			formData : {
				...employmentCreditData.formData,
				[name] : value,
			}
		});
	};


	const handleChangeMulti = (obj) => {
		setEmploymentCreditData({
			...employmentCreditData,
			formData : {
				...employmentCreditData.formData,
				...obj
			}
		});
	};

	const setEmployee = (data) => {
		setEmploymentCreditData({
			...employmentCreditData,
			formData : {
				...employmentCreditData.formData,
				workerName     : data.name,
				workerLastName : data.lastname,
				workerId       : data.id,
			}
		});
	};

	const generateConfirmationHTML = (formData) => {
		let html = "<table style='width: 100%;border-collapse: collapse;'><tbody>";
		const lhtml = "<tr style='border-bottom: 1px solid #e3e3e3;'><td style=\"text-align:left;\">";
		const mhtml = "</td><td style=\"text-align:right;word-wrap:anywhere;\">";
		const rhtml = "</td></tr>";
		for (const key in formData) {
			if (formData.customerName && key === "customerName") {
				html += lhtml + statusLabel.customerName + mhtml + formData.customerName + rhtml;
			} else if (key === "customerId" || key === "workerId") {
				continue;
			} else if (key === "creditType") {
				html += lhtml + statusLabel.creditType + mhtml + creditTypes[formData[key]] + rhtml;
			} else if (key === "creditFile" && formData[key]) {
				html += lhtml + statusLabel.creditFile + mhtml + formData[key].name + rhtml;
			} else if (key === "branchOfficeId") {
				// pass
			} else {
				html += lhtml + statusLabel[key] + mhtml + formData[key] + rhtml;
			}
		}

		return `${html}</tbody></table>`;
	};

	const formValidate = async () => new Promise((resolve) => {
		const validate = {
			validate	: false,
			error  		: "Debes completar el formulario"
		};


		const validFormSync = {};
		Object.assign(validFormSync, validFormInit);
		for (const key in validFormInit) {
			if (key === "creditFile" && employmentCreditData.formData[key]) {
				validFormSync[key] = false;
			} else if (!employmentCreditData.formData[key]) {
				validFormSync[key] = false;
			}
		}
		setValidForm(validFormSync);

		// let validCreditFile = true;
		// if (employmentCreditData.formData.creditFile.length !== 1) {
		// 	validate.error = "Adjuntar el documento de crédito de empleado";
		// 	validCreditFile = false;
		// }

		// if (!validCreditFile) {
		// 	resolve(validate);
		// 	return;
		// }

		if (employmentCreditData.formData.customerName
			|| employmentCreditData.formData.customerId) {
			validate.validate = true;
			resolve(validate);
		}

		resolve(validate);
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		setEmploymentCreditData({
			...employmentCreditData,
			submitted : true,
		});

		try {
			const validateForm = await formValidate();
			if (validateForm.validate) {
				const formData = {};
				for (const key in employmentCreditData.formData) {
					if (employmentCreditData.formData[key]) {
						formData[key] = employmentCreditData.formData[key];
					}
				}

				swal.fire({
					title             : "Revisar Datos",
					html              : generateConfirmationHTML(formData),
					icon              : "info",
					width             : 700,
					showCancelButton  : true,
					confirmButtonText : "Enviar",
					cancelButtonText  : "Cancelar",
				}).then(async (result) => {
					if (result.isConfirmed) {
						const response = await userService.createRequest({
							request     : JSON.stringify(formData),
							requestType : "EMPLOYMENT_CREDIT",
						},
						formData.creditFile);
						if (response.status === 200) {
							swal.fire("!Enviado!", "Tu solicitud ha sido enviada correctamente!", "success");
							clearForm();
						} else {
							swal.fire("!Error!", response.label, "error");
						}
					}
				});
			} else {
				swal.fire("Error", validateForm.error, "warning");
			}
		} catch (error) {
			swal.fire(`Error ${error}`, "error");
		}
	};

	return (
		<div className="EmploymentCredit">
			<Grid container>
				<div className="topHeader" />
				<CustomerIdentifier
					handleChange={handleChange}
					validForm={validForm}
					status={employmentCreditData}
					match={match}
					mode={mode}
					isDisabled={isDisabled}
					handleChangeMulti={handleChangeMulti}
					ref={identifierRef}
					requireBranchOffice
					clearForm={clearForm}
				/>

				{/* Separator */}
				<Grid
					container
					justify="center"
					alignItems="center"
				>
					<Grid item xs={11}>
						<Divider />
					</Grid>
				</Grid>
				<Grid container spacing={2} justify="center" alignItems="flex-start">
					<Grid item md={6} container direction="column" spacing={2}>
						<Grid item xs>
							<EmployeeIdentifier
								key={employmentCreditData.formData.customerId}
								handleChange={handleChange}
								validForm={validForm}
								status={employmentCreditData}
								mode={mode}
								isDisabled={isDisabled}
								setEmployee={setEmployee}
								ref={employeeRef}
								branchOfficeRequired
								customerId={employmentCreditData.formData.customerId}
								branchOfficeId={employmentCreditData.formData.branchOfficeId}
								selectEmployee
							/>
						</Grid>
					</Grid>
					<Grid item xs={11} container spacing={2} justify="center">
						<FormControl
							component="fieldset"
							className="formControl"
							error={!validForm.creditType}
							required
							disabled={isDisabled()}
						>
							<FormLabel component="legend">INDICAR EL TIPO DE CRÉDITO</FormLabel>
							<RadioGroup
								aria-label="INDICAR EL TIPO DE CRÉDITO"
								value={employmentCreditData.formData.creditType}
								onChange={handleChange("creditType")}
								row
							>
								{
									Object.entries(creditTypes).map(
										(value, idx) => <FormControlLabel key={idx} value={value[0]} control={<Radio />} label={value[1]} />

									)
								}
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item xs={11} container spacing={2} direction="column" justify="center">
						<Grid item xs>
							{
								employmentCreditData.formData.creditType && !isDisabled() ? (
									<label htmlFor="creditFile">
										<input
											style={{ display : "none" }}
											id="creditFile"
											name="creditFile"
											type="file"
											// multiple
											// accept="image/*, .pdf"
											onChange={
												e => handleChange("creditFile")(e.target.files[0])
											}
										/>
										<Button
											className="btn-file"
											variant="outlined"
											color="primary"
											component="span"
										>
											<Grid container direction="column" item>
												<Typography component="h3" style={{ padding : "0.5rem 0 1rem 0", textAlign : "center" }}>
													Adjuntar el documento de crédito de empleado
													<br />
												(Escanear el documento y subirlo en PDF o Imagen)
												</Typography>
												<span className="clip">
													<i className="far fa-paperclip" />
												</span>
												{
													employmentCreditData.formData.creditFile ? (
														<Typography
															variant="caption"
															component="h5"
															style={{ padding : "0.5rem 0 1rem 0", textAlign : "center", wordWrap : "break-word" }}
														>
															{employmentCreditData.formData.creditFile.name}
														</Typography>
													)
													 : null
												}

											</Grid>
										</Button>
									</label>
								) : null
							}
							{
								employmentCreditData.formData.creditFile && isDisabled()
									? (
										<Grid item xs>
											<Button
												className="btn-green"
												variant="outlined"
												color="primary"
												component="span"
												onClick={() => downloadFile(
													`${process.env.REACT_APP_SYSCONTROL_API}/storage/file/${employmentCreditData.formData.creditFile}`,
													"DOC-INCAPACIDAD-1"
												)}
											>
											DESCARGAR DOCUMENTO DE INCAPACIDAD UNO
											</Button>
										</Grid>
									) : null
							}
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="details"
								InputProps={{
									readOnly : isDisabled(),
								}}
								value={employmentCreditData.formData.details ? employmentCreditData.formData.details : ""}
								className="formControl"
								onChange={handleChange("details")}
								label="Detalles u Observaciones"
								multiline
								rows={4}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid item md={3} xs={11} container spacing={2} direction="column" justify="flex-end">
						{mode !== "VIEW" && (
							<FormButtons handleSubmit={handleSubmit} match={match} />
						)}
					</Grid>
				</Grid>
				<hr style={{ border : "1rem solid #fff" }} />
			</Grid>
		</div>
	);
};

EmploymentCredit.propTypes = {
	match        : PropTypes.object.isRequired,
	customerData : PropTypes.object,
	loggedIn     : PropTypes.bool,
	mode         : PropTypes.string,
	requestData  : PropTypes.object
};

EmploymentCredit.defaultProps = {
	customerData : {},
	loggedIn     : false,
	mode         : "NEW",
	requestData  : {}
};

const mapStateToProps = ({ authReducer: { customerData, loggedIn } }) => ({
	customerData,
	loggedIn
});

export default connect(mapStateToProps, null)(EmploymentCredit);
