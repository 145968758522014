import React        from "react";
import { NotFound } from "sigue-components/core/Errors";

// Import Own Components
import HomePage      					from "../../pages/HomePage";
import Documents     					from "../../pages/Documents";
import Categories    					from "../../pages/Documents/Categories";
import Files         					from "../../pages/Documents/Files";
import Profile       					from "../../pages/Profile";
import LoadFile      					from "../../pages/LoadFile";
import StatusAccount 					from "../../pages/StatusAccount";
import Requests		 					from "../../pages/Requests";
import RegisterWorker					from "../../pages/Requests/RegisterWorker";
import WithdrawalWorker					from "../../pages/Requests/WithdrawalWorker";
import NewSalary						from "../../pages/Requests/NewSalary";
import Disabilities						from "../../pages/Requests/Disabilities";
import EmployerRegistration				from "../../pages/Requests/EmployerRegistration";
import EmployerRegistrationWithdrawal	from "../../pages/Requests/EmployerRegistrationWithdrawal";
import EmploymentCredit					from "../../pages/Requests/EmploymentCredit";
import Pendings      					from "../../pages/Pendings";
import Compliance						from "../../pages/Compliance";

const routes = [
	{
		component : HomePage,
		path      : "/",
		exact     : true,
	},
	{
		component : LoadFile,
		path      : "/cargar_archivos",
		exact     : true,
	},
	{
		component : Documents,
		path      : "/documentos",
		exact     : true,
	},
	{
		component : Categories,
		path      : "/documentos/categorias.:categoryId",
		exact     : true,
	},
	{
		component : Files,
		path      : "/documentos/categorias.:categoryId/:subCategoryName.:subCategoryId",
		exact     : true,
	},
	{
		component : StatusAccount,
		path      : "/finanzas",
		exact     : true,
	},
	{
		component : Profile,
		path      : "/perfil",
		exact     : false,
	},
	{
		component : Pendings,
		path      : "/consultas",
		exact     : true,
	},
	{
		component : Requests,
		path      : "/solicitudes_imss",
		exact     : true,
		employer  : true,
	},
	{
		component : RegisterWorker,
		path      : "/solicitudes_imss/registrar_trabajador",
		exact     : true,
		employer  : true,
	},
	{
		component : WithdrawalWorker,
		path      : "/solicitudes_imss/baja_trabajador",
		exact     : true,
		employer  : true,
	},
	{
		component : NewSalary,
		path      : "/solicitudes_imss/nuevo_salario",
		exact     : true,
		employer  : true,
	},
	{
		component : Disabilities,
		path      : "/solicitudes_imss/incapacidades",
		exact     : true,
		employer  : true,
	},
	{
		component : EmploymentCredit,
		path      : "/solicitudes_imss/crédito_nominas",
		exact     : true,
		employer  : true,
	},
	{
		component : Compliance,
		path      : "/cumplimineto/:complianceType/:complianceId",
		exact     : false,
	},
	{
		component : props => <NotFound {...props} />,
	},
];

export const routesEmployees = [
	{
		component : HomePage,
		path      : "/",
		exact     : true,
	},
	{
		component : Profile,
		path      : "/perfil",
		exact     : false,
	},
	{
		component : Pendings,
		path      : "/consultas",
		exact     : true,
	},
	{
		component : Requests,
		path      : "/solicitudes_imss",
		exact     : true,
	},
	{
		component : RegisterWorker,
		path      : "/solicitudes_imss/registrar_trabajador",
		exact     : true,
	},
	{
		component : WithdrawalWorker,
		path      : "/solicitudes_imss/baja_trabajador",
		exact     : true,
	},
	{
		component : NewSalary,
		path      : "/solicitudes_imss/nuevo_salario",
		exact     : true,
	},
	{
		component : Disabilities,
		path      : "/solicitudes_imss/incapacidades",
		exact     : true,
	},
	{
		component : EmploymentCredit,
		path      : "/solicitudes_imss/crédito_nominas",
		exact     : true,
	},
	{
		component : EmployerRegistration,
		path      : "/solicitudes_imss/alta_registro_patronal",
		exact     : true,
	},
	{
		component : EmployerRegistrationWithdrawal,
		path      : "/solicitudes_imss/baja_registro_patronal",
		exact     : true,
	},
	{
		component : props => <NotFound {...props} />,
	},
];

export const openRoutes = [
	{
		component : RegisterWorker,
		path      : "/solicitudes_imss/registrar_trabajador",
		exact     : true,
	},
	{
		component : WithdrawalWorker,
		path      : "/solicitudes_imss/baja_trabajador",
		exact     : true,
	},
	{
		component : NewSalary,
		path      : "/solicitudes_imss/nuevo_salario",
		exact     : true,
	},
	{
		component : Disabilities,
		path      : "/solicitudes_imss/incapacidades",
		exact     : true,
	},
	{
		component : EmploymentCredit,
		path      : "/solicitudes_imss/crédito_nominas",
		exact     : true,
	},
];

export const openRoutesPaths = openRoutes.map(i => i.path);

export default routes;
