/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import { authHeader } from "../helpers";

const apiUrl = process.env.REACT_APP_SYSCONTROL_API;

const JSONApplicationHeaders = {
	"Content-Type" : "application/json",
	Accept         : "application/json"
};

const handleResponse = async (res) => {
	const data = await res.clone().json();
	if (!res.ok || data.message === "error") {
		const error =  data.message || data.label || "Ha ocurrido un error";
		return Promise.reject(error);
	}
	return data;
};

const sendToken = async (data) => {
	const requestOptions = {
		method  : "POST",
		headers : JSONApplicationHeaders,
		body    : JSON.stringify({ token : data.token }),
	};

	const response = await fetch(`${apiUrl}/user/${data.userId}/message_token`, requestOptions);

	return response;
};

const getCustomerData = async (customerId) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const response = await fetch(`${apiUrl}/customer/find/${customerId}`, requestOptions);

	return response;
};

const getEmployeeData = async (employeeId) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const response = await fetch(`${apiUrl}/employee/${employeeId}`, requestOptions);

	return response;
};

const login = async (username, password, type) => {
	const requestOptions = {
		method  : "POST",
		headers : JSONApplicationHeaders,
		body    : JSON.stringify({
			username,
			password,
			type
		}),
	};

	const response = await fetch(`${apiUrl}/login`, requestOptions);

	return response;
};

const getAllAccounts = async (userId) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const response = await fetch(`${apiUrl}/user/customer_accounts/${userId}`, requestOptions);

	return response;
};

const getAllCustomerPendings = async (customerId, status = null) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	let url = `${apiUrl}/pendings/list?customer_id=${customerId}`;
	url = status ? `${url}?status=${status}` : url;
	const response  = await fetch(url, requestOptions).then(handleResponse);

	return response;
};

const getStatusAccount = async (customerId) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = `${apiUrl}/payment/account_status/${customerId}`;

	const response  = await fetch(url, requestOptions).then(handleResponse);

	return response;
};

const getFilesCustomer = async (customerId, categoryId = "", column = "id", order = "ASC", params = {}) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = new URL(`${apiUrl}/documents`);
	url.search = new URLSearchParams({
		customer_id : customerId,
		category    : categoryId,
		column,
		order,
		...params
	});
	const response  = await fetch(url.href, requestOptions).then(handleResponse);

	return response;
};

const getPendingns = async (queryString) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};


	const url = new URL(`${apiUrl}/pendings/list`);
	url.search = new URLSearchParams(queryString);

	const response  = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};

const createPending = async (pending) => {
	const requestOptions = {
		method  : "POST",
		headers : JSONApplicationHeaders,
		body    : JSON.stringify(pending),
	};
	const response = await fetch(`${apiUrl}/pendings`, requestOptions);

	return response;
};

const updatePending = async (id, pending) => {
	const requestOptions = {
		method  : "PUT",
		headers : JSONApplicationHeaders,
		body    : JSON.stringify(pending),
	};
	const response = await fetch(`${apiUrl}/pendings/${id}`, requestOptions);

	return response;
};

const postPendingLog = async (pending) => {
	const requestOptions = {
		method  : "POST",
		headers : JSONApplicationHeaders,
		body    : JSON.stringify(pending),
	};
	const response = await fetch(`${apiUrl}/pendings/log`, requestOptions);

	return response;
};

const updateUserData = (id, newUserData) => {
	const bodyData = new FormData();

	Object.keys(newUserData).map(key => bodyData.append(key, newUserData[key]));

	bodyData.append("_method", "PUT");

	const requestOptions = {
		method  : "POST",
		headers : authHeader(),
		body    : bodyData,
	};

	return fetch(`${apiUrl}/user/edit/${id}`, requestOptions);
};

const changePassword = (id, oldPassword, newPassword) => {
	const requestOptions = {
		method  : "POST",
		headers : JSONApplicationHeaders,
		body    : JSON.stringify({
			id,
			old : oldPassword,
			new : newPassword,
		}),
	};

	return fetch(`${apiUrl}/change_password`, requestOptions);
};

const getCustomerBranchOffices = async (uParams) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const params = {
		...uParams,
		type : "CUSTOMER"
	};

	const url = new URL(`${apiUrl}/branch_office`);
	url.search = new URLSearchParams(params);

	const response = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};

const getEmployerRegistrations = async (customerId) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = `${apiUrl}/requester_registration?customer_id=${customerId}`;
	const response = await fetch(url, requestOptions).then(handleResponse);
	return response;
};

const createRequest = async (request, ...files) => {
	const bodyData = new FormData();
	Object.keys(request).map(key => bodyData.append(key, request[key]));
	// eslint-disable-next-line guard-for-in
	for (const i in files) bodyData.append(`file${i}`, files[i]);
	bodyData.append("_method", "POST");

	const requestOptions = {
		method  : "POST",
		headers : authHeader(),
		body    : bodyData,
	};

	return fetch(`${apiUrl}/imss_request_details`, requestOptions);
};

// TODO: Change when migrate creation from details to controller
const createRequestController = async (request, ...files) => {
	const bodyData = new FormData();
	Object.keys(request).map(key => bodyData.append(key, request[key]));
	// eslint-disable-next-line guard-for-in
	for (const i in files) bodyData.append(`file${i}`, files[i]);
	bodyData.append("_method", "POST");

	const requestOptions = {
		method  : "POST",
		headers : authHeader(),
		body    : bodyData,
	};

	return fetch(`${apiUrl}/imss_request_controller`, requestOptions);
};

const getIMSSRequests = async (params, extra_details = true) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = new URL(`${apiUrl}/imss_request_controller`);
	url.search = new URLSearchParams({ ...params, extra_details });

	const response = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};

const getIMSSRequestDetails = async (id) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = `${apiUrl}/imss_request_controller/${id}`;
	const response = await fetch(url, requestOptions).then(handleResponse);
	return response;
};

const getIMSSRequestLog = async (id) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = `${apiUrl}/imss_request_log?imss_request_id=${id}`;
	const response = await fetch(url, requestOptions).then(handleResponse);
	return response;
};

const getLogsDocuments = async (params, extra_details = true) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = new URL(`${apiUrl}/imss_request_log_docs`);
	url.search = new URLSearchParams(params);

	const response = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};


const postRequestProcess = async (request, ...files) => {
	const bodyData = new FormData();
	Object.keys(request).map(key => bodyData.append(key, request[key]));
	// eslint-disable-next-line guard-for-in
	for (const i in files) bodyData.append(`file${i}`, files[i]);
	bodyData.append("_method", "POST");

	const requestOptions = {
		method  : "POST",
		headers : authHeader(),
		body    : bodyData,
	};
	return fetch(`${apiUrl}/imss_request_log`, requestOptions);
};

const cancelRequest = async (id, params) => {
	const requestOptions = {
		method  : "DELETE",
		headers : JSONApplicationHeaders,
	};

	const url = new URL(`${apiUrl}/imss_request_controller/${id}`);
	url.search = new URLSearchParams(params);

	const response = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};

const getResponsibleCustomers = async (id) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = `${apiUrl}/employee/customers/${id}`;
	const response = await fetch(url, requestOptions).then(handleResponse);
	return response;
};

const getCustomers = async (params) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = new URL(`${apiUrl}/customer`);
	url.search = new URLSearchParams(params);

	const response = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};

const getEmployees = async (params) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = new URL(`${apiUrl}/employee`);
	url.search = new URLSearchParams(params);

	const response = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};


const getCustomerEmployees = async (id, params) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};
	const url = new URL(`${apiUrl}/customer/showCustomerEmployees/${id}`);
	url.search = new URLSearchParams(params);

	const response = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};

const getComplianceAdvanceCount = async (customerId, params = {}) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = new URL(`${apiUrl}/compliance/get_accountanting_advance_count/${customerId}`);
	url.search = new URLSearchParams(params);
	const response = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};

const getCompliance = async (complianceId, params) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const url = new URL(`${apiUrl}/compliance/get_compliance/${complianceId}`);
	url.search = new URLSearchParams(params);
	const response = await fetch(url.href, requestOptions).then(handleResponse);
	return response;
};

const userService = {
	sendToken,
	getCustomerData,
	getCustomers,
	getEmployeeData,
	login,
	getPendingns,
	createPending,
	updatePending,
	postPendingLog,
	getFilesCustomer,
	getStatusAccount,
	getAllAccounts,
	getAllCustomerPendings,
	updateUserData,
	changePassword,
	createRequest,
	createRequestController,
	getCustomerBranchOffices,
	getIMSSRequests,
	getIMSSRequestDetails,
	getIMSSRequestLog,
	getLogsDocuments,
	postRequestProcess,
	getEmployerRegistrations,
	getResponsibleCustomers,
	getCustomerEmployees,
	getEmployees,
	cancelRequest,
	getComplianceAdvanceCount,
	getCompliance
};

export default userService;
