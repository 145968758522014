import React               from "react";
import PropTypes           from "prop-types";
import { connect }         from "react-redux";

// Import Own Components
import "./Loading.scss";

const BlockProcessLoading = ({ isLoading }) => {
	if (!isLoading) {
		return null;
	}

	return (
		<div className="loading">
			<div className="icon-container">
				<i className="far fa-spinner" />
			</div>
		</div>
	);
};

const mapStateToProps = ({ loadingReducer : { isLoading } }) => ({ isLoading });

BlockProcessLoading.propTypes = {
	isLoading : PropTypes.bool,
};

BlockProcessLoading.defaultProps = {
	isLoading : false,
};

export default connect(mapStateToProps, null)(BlockProcessLoading);
