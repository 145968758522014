/* eslint-disable max-len */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
	Box, Button, Grid, Tooltip
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { format }		from "date-fns";
import esLocale			from "date-fns/locale/es";

const useRowStyles = makeStyles({
	root : {
		"& > *" : {
			borderBottom : "unset",
		},
	},
});
function Row({ row, handleCancellation }) {
	const [open, setOpen] = useState(false);
	const classes = useRowStyles();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{row.nrp}
				</TableCell>
				<TableCell>{row.delegation}</TableCell>
				<TableCell align="right">{row.branch_offices.reduce((accum, i) => accum + i.workers_count, 0)}</TableCell>
				<TableCell align="right">{format(new Date(row.created_at.replace(" ", "T")), "dd/MMMM/yyyy", { locale : esLocale })}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom : 0, paddingTop : 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Typography variant="h6" gutterBottom component="div">
                Sucursales
							</Typography>
							<Table size="small" aria-label="branch_office">
								<TableHead>
									<TableRow>
										<TableCell>Nombre</TableCell>
										<TableCell>Descripción</TableCell>
										<TableCell>Dirección</TableCell>
										<TableCell align="right">Empleados</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.branch_offices.map(branchOffice => (
										<TableRow key={branchOffice.id}>
											<TableCell>{branchOffice.name}</TableCell>
											<TableCell>{branchOffice.description}</TableCell>
											<TableCell>
												{`${branchOffice.street} #${branchOffice.outdoor_number}, ${branchOffice.city}`}
											</TableCell>
											<TableCell align="right">
												{branchOffice.workers_count}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							<Grid
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
								style={{ paddingTop : "1rem" }}
							>
								<Grid item xs={4}>
									<Tooltip
										title={
											row.branch_offices.reduce((acc, i) => acc + i.workers_count, 0) > 0
												? "Debes dar de baja a todos los empleados de estas sucursales para poder dar de baja el registro patronal"
												: "Da clic para iniciar un proceso de baja de registro patronal"
										}
										placement="top-start"
										arrow
									>
										<Button
											className="btn-green"
											variant="outlined"
											color="primary"
											onClick={e => handleCancellation(e, row.id, row.branch_offices.reduce((acc, i) => acc + i.workers_count, 0) <= 0)}
										>
											<Typography variant="h6" component="legend">SOLICITAR BAJA DE REGISTRO PATRONAL</Typography>
										</Button>
									</Tooltip>
								</Grid>
							</Grid>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>

		</React.Fragment>
	);
}

Row.propTypes = {
	row                : PropTypes.object.isRequired,
	handleCancellation : PropTypes.func.isRequired
};

export default Row;
