import React from "react";
import PropTypes	from "prop-types";

// import own components
import ModuleContainer	from "../../../components/ModuleContainer";
import EmployerRegistration   from "../../../components/Requests/EmployerRegistrationWithdrawal";

const EmployerRegistrationWithdrawalPage = ({ match }) => (
	<div>
		<ModuleContainer
			title="Baja de Registro Patronal"
			color="#f3f0f0"
			background="#13322b"
			icon="far fa-building"
			md={11}
			lg={11}
		>
			<EmployerRegistration
				match={match}
			/>
		</ModuleContainer>
	</div>
);

EmployerRegistrationWithdrawalPage.propTypes = {
	match	: PropTypes.object.isRequired,
};

export default EmployerRegistrationWithdrawalPage;
