/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect }            from "react-redux";
import {
	Button,
	Grid,
	Typography,
	TextField,
	Dialog,
	Slide
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import swal				from "sweetalert2";

// Import userService
import userService		from "../../../config/services/userService";

// import own components
import RequestSelector	from "../RequestSelector";
import { initState, columns } from "./const";
import "./EmployerRegistration.scss";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const EmployerRegistration = ({ employeeId, loggedIn, match }) => {
	const [state, setState]                 				= useState(initState);
	const [openSelector, setOpenSelector]					= useState(false);
	const [filter, setFilter]								= useState("");
	const [customers, setCustomers]							= useState([]);
	const [branchOffices, setBranchOffices]					= useState([]);
	const [selectedBranchOffices, setSelectedBranchOffices]	= useState([]);
	const [open, setOpen] 									= useState(false);

	const handleRequestSelector = () => {
		setOpenSelector(!openSelector);
	};

	const loading = open && customers.length === 0;

	useEffect(() => {
		(async () => {
			setState({
				...state,
				formData : {
					...state.formData,
					responsible_id : employeeId,
				}
			});
		})();
	}, []);

	const handleChange = name => (d) => {
		const value = d && d.target ? d.target.value : d && d.id ? d.id : d;

		setState({
			...state,
			formData : {
				...state.formData,
				[name] : value,
			}
		});
	};

	const clearForm = () => {
		setFilter("");
		setCustomers([]);
		setBranchOffices([]);
		setSelectedBranchOffices([]);
		setState(initState);
	};

	const getCustomers = async () => {
		const response = await userService.getCustomers({
			page               : 1,
			per_page           : 20,
			with_branch_office : true,
			type               : "activo",
			filter
		});
		if (response.status === 200) {
			setCustomers(response.data);
		} else {
			swal.fire("!Error!", "Error al cargar los clientes", "error");
		}
	};

	useEffect(() => {
		getCustomers();
	}, [filter]);

	const getCustomerBranchOffices = async (customerId) => {
		const response = await userService.getCustomerBranchOffices({
			page         : 1,
			per_page     : 20,
			customer_id  : customerId,
			employer_reg : true,
		});
		if (response.status === 200) {
			const branches = response.data.filter(i => !i.employer_registration_id);
			setBranchOffices(branches);
		} else {
			swal.fire("!Error!", "Error al cargar las sucursales", "error");
		}
	};

	const setCustomer = (customerId) => {
		handleChange("customer_id")(customerId);
		getCustomerBranchOffices(customerId);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setState({
			...state,
			submitted : true,
		});
		try {
			if (!state.formData.customer_id) {
				swal.fire("¡Hey!", "Es necesario seleccionar un cliente", "warning");
				return;
			}
			if (!selectedBranchOffices.length) {
				swal.fire("¡Hey!", "Es necesario seleccionar al menos una sucursal", "warning");
				return;
			}
			swal.fire({
				title             : "Confirmación",
				text              : "¿Seguro que deseas iniciar este proceso?",
				icon              : "info",
				showCancelButton  : true,
				confirmButtonText : "Iniciar",
				cancelButtonText  : "Cancelar",
			}).then(async (result) => {
				if (result.isConfirmed) {
					const response = await userService.createRequestController({
						request     : JSON.stringify({ ...state.formData, branch_offices : selectedBranchOffices }),
						requestType : "EMPLOYER_REGISTRATION",
					});
					if (response.status === 200) {
						swal.fire("!Enviado!", "Tu solicitud ha sido enviada correctamente!", "success");
						clearForm();
					} else {
						const body = await response.json();
						swal.fire("!Error!", body.error, "error");
					}
				}
			});
		} catch (err) {
			swal.fire(`Error ${err}`, "error");
		}
	};

	return (
		<>
			<div className="EmployerRegistration">
				<div className="topHeader" />
				<Grid container item xs={11} alignItems="flex-end" justify="flex-end">
					<Grid item xs={4}>
						<Button className="btn-green" variant="outlined" color="primary" onClick={handleRequestSelector}>
						CAMBIAR TIPO DE SOLICITUD
						</Button>
					</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignItems="center"
				>
					<Grid item xs={11}>
						<Typography variant="h5" component="h2" style={{ paddingBottom : "1rem", textAlign : "center" }}>
                        Iniciar solicitud de alta de registro patronal
						</Typography>
					</Grid>
					<Grid item md={6}>
						<Autocomplete
							open={open}
							onOpen={() => {
								setOpen(true);
							}}
							onClose={() => {
								setOpen(false);
							}}
							onChange={(e, newValue) => setCustomer(newValue ? newValue.id : null)}
							getOptionSelected={(option, value) => option.name === value.name}
							getOptionLabel={option => option.name}
							options={customers}
							inputValue={filter}
							onInputChange={(event, newInputValue) => {
								setFilter(newInputValue);
							}}
							noOptionsText="No hay más clientes"
							loadingText="Cargando..."
							loading={loading}
							renderInput={params => (
								<TextField
									{...params}
									label="Clientes"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete : "new-password",
									}}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<br />
				<br />
				{branchOffices.length ? (
					<Grid
						container
						justify="center"
						alignItems="center"
						style={{ height : 300 }}
					>
						<DataGrid
							rows={branchOffices}
							columns={columns}
							pageSize={5}
							checkboxSelection
							autoPageSize
							disableColumnMenu
							disableColumnSelector
							disableExtendRowFullWidth
							disableSelectionOnClick
							onSelectionModelChange={e => setSelectedBranchOffices(e.selectionModel)}
						/>
					</Grid>
				) : null}

				<br />
				<Grid
					container
					justify="center"
					alignItems="center"
				>
					<Grid item xs={6}>
						<Button
							className="btn-green submitButton"
							variant="outlined"
							color="primary"
							onClick={handleSubmit}
							disabled={!selectedBranchOffices.length}
						>
							<Typography variant="h6" component="legend">INICIAR PROCESO PARA ALTA DE REGISTRO PATRONAL</Typography>
						</Button>
					</Grid>
				</Grid>
			</div>
			<Dialog
				open={openSelector}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleRequestSelector}
				aria-labelledby="Crear nueva solicitud"
				aria-describedby="Crear nueva solicitud"
			>
				<RequestSelector match={match} modal />
			</Dialog>
		</>
	);
};

EmployerRegistration.propTypes = {
	employeeId : PropTypes.number.isRequired,
	match      : PropTypes.object.isRequired,
	loggedIn   : PropTypes.bool,
};

EmployerRegistration.defaultProps = {
	loggedIn : false,
};

const mapStateToProps = ({ authReducer: { customerData, loggedIn } }) => ({
	employeeId : customerData.id,
	loggedIn,
});

export default connect(mapStateToProps, null)(EmployerRegistration);
