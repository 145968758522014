/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
import React, {  useState } from "react";
import PropTypes            from "prop-types";
import Dropzone             from "react-dropzone";
import { connect }          from "react-redux";
import swal                 from "sweetalert2";
import {
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	IconButton,
	ListItemSecondaryAction,
	Avatar,
	Button,
	Select,
	InputLabel,
	MenuItem,
	FormControl
} from "@material-ui/core";
import { makeStyles }  from "@material-ui/core/styles";
import fileService     from  "../../config/services/fileService";
import ModuleContainer from "../../components/ModuleContainer";

import "./LoadFile.scss";

const useStyles = makeStyles(theme => ({
	formControl : {
		padding : `${theme.spacing(1)}px ${theme.spacing(3)}px`,
		width   : "100%",
	},
	container : {
		padding : theme.spacing(3),
	},
	containerInstuctions : {
		margin : `${theme.spacing(10)}px 0px ${theme.spacing(5)}px 0px`,
	},
	topHeader : {
		height          : "0.5rem",
		width           : "100%",
		backgroundColor : theme.palette.primaryShades[600],
	},
	instrutions : {
		fontWeight : "bold",
		border     : "1px solid #efe4e4",
		background : "#fbfbfb",
		color      : "#6e97cc",
		padding    : "1.5rem",
	}
}));

const LoadFile = ({
	customerId,
	userId
}) => {
	const classes = useStyles();
	const date    = new Date();
	date.setMonth(date.getMonth() - 1);

	const initState = {
		fecha : (date.getMonth() + 1),
		año   : date.getFullYear(),
		files : []
	};

	const [state, setState] = useState(initState);

	const handleChange = event => () => setState(oldState => ({
		...oldState,
		fecha : event.target.value,
	}));

	const handleChangeAño = event => () => setState(oldState => ({
		...oldState,
		año : event.target.value,
	}));

	const processform = () => {
		if (state.files.length === 0) {
			swal.fire("Error", "No se han seleccionado archivos", "warning");
			return false;
		}

		if (state.año === "" || state.fecha === "") {
			swal.fire("Error", "No se ha seleccionado fecha ó año", "warning");
			return false;
		}

		state.files.forEach(async (file) => {
			const fecha  = `${state.año}/${state.fecha}/01`;
			const params = {
				name         : file.name,
				categoryId   : 1,
				dateDocument : fecha,
				type         : "customer",
				storage      : "SAT",
				user         : userId,
				customerId,
				file
			};

			const resultado =  await fileService.postFile(params);
			if (resultado.status === 200) {
				swal.fire("Envio de archivos", "operacion correcta", "success");
				setState(initState);
			}
		});
		return true;
	};

	const onDrop = (file) => {
		let newFiles = state.files;
		file.forEach((item) => {
			newFiles.push(item);
		});
		setState({
			...state,
			files : newFiles
		});
	};

	const deleteFile = (index) => {
		let newFiles = state.files;
		newFiles.splice(index, 1);
		setState({
			...state,
			files : newFiles
		});
	};

	return (
		<div className="loadfile">
			<ModuleContainer
				title="Enviar estado de cuenta"
				color="#f3f0f0"
				background="#d45a08"
				icon="fas fa-cloud-upload-alt"
			>
				<div className={classes.topHeader} />
				<Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
					<Grid item container alignItems="center" justify="center" direction="column">
						<Grid item className={classes.instrutions}>
							<legend>
								Carga tu estado de cuenta mas vigente, puedes descargarlo en el portal de tu banco, o bien tomar una foto y subirla aquí
								<i className="far fa-hand-point-down" />
							</legend>
						</Grid>
						<Grid item>
							<Dropzone onDrop={onDrop}>
								{({ getRootProps, getInputProps }) => (
									<section>
										<div className="dropzone" {...getRootProps()}>
											<input {...getInputProps()} />
											<i className="fas fa-image fa-3x" />
											<p>Cargar archivo o imagén</p>
										</div>
									</section>
								)}
							</Dropzone>
							<ul>
								<List>
									{ state.files.map((file, index) => (
										<ListItem key={file.name}>
											<ListItemAvatar>
												<Avatar>
													<i className="far fa-file-check" />
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary={(file.name.length > 15) ? `${file.name.substring(0, 15)}...` : file.name}
											/>
											<ListItemSecondaryAction>
												<IconButton edge="end" aria-label="delete" onClick={() => deleteFile(index)}>
													<i className="fas fa-trash-alt" />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
								</List>
							</ul>
						</Grid>
						<Grid item container alignItems="center" justify="center">
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="select-mes">Mes del estado de cuenta</InputLabel>
								<Select
									value={state.fecha}
									onChange={e => handleChange(e)()}
									inputProps={{
										name : "Mes",
										id   : "select-mes",
									}}
									required
								>
									<MenuItem value={1}>Enero</MenuItem>
									<MenuItem value={2}>Febrero</MenuItem>
									<MenuItem value={3}>Marzo</MenuItem>
									<MenuItem value={4}>Abril</MenuItem>
									<MenuItem value={5}>Mayo</MenuItem>
									<MenuItem value={6}>Junio</MenuItem>
									<MenuItem value={7}>Julio</MenuItem>
									<MenuItem value={8}>Agosto</MenuItem>
									<MenuItem value={9}>Septiembre</MenuItem>
									<MenuItem value={10}>Octubre</MenuItem>
									<MenuItem value={11}>Noviembre</MenuItem>
									<MenuItem value={12}>Dicimebre</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item container alignItems="center" justify="center">
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="select-año">Año</InputLabel>
								<Select
									value={state.año}
									onChange={e => handleChangeAño(e)()}
									inputProps={{
										name : "Año",
										id   : "select-año",
									}}
									required
								>
									<MenuItem value={2015}>2015</MenuItem>
									<MenuItem value={2016}>2016</MenuItem>
									<MenuItem value={2017}>2017</MenuItem>
									<MenuItem value={2018}>2018</MenuItem>
									<MenuItem value={2019}>2019</MenuItem>
									<MenuItem value={2020}>2020</MenuItem>
									<MenuItem value={2021}>2021</MenuItem>
									<MenuItem value={2022}>2022</MenuItem>
									<MenuItem value={2023}>2023</MenuItem>
									<MenuItem value={2024}>2024</MenuItem>
									<MenuItem value={2025}>2025</MenuItem>
									<MenuItem value={2026}>2026</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Button
						onClick={() => { processform(); }}
						color="primary"
						variant="contained"
						href="#contained-buttons"
						disabled={!state.files.length}
					>
						<i className="fas fa-location-arrow" />
						{"Enviar Archivo"}
					</Button>
				</Grid>
			</ModuleContainer>
		</div>
	);
};

LoadFile.propTypes = {
	customerId : PropTypes.number.isRequired,
	userId     : PropTypes.number.isRequired
};

const mapStateToProps = ({ authReducer : { userData, customerData } }) => ({
	customerId : customerData.id,
	userId     : userData.user_id,
});

export default connect(mapStateToProps, null)(LoadFile);
