import { composeWithDevTools }          from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk                            from "redux-thunk";

// Import Own Components
import rootReducer              from "./reducers";
import { loadState, saveState } from "./persistor";

const isProduction = process.env.NODE_ENV === "production";

const Store = createStore(
	rootReducer,
	loadState() || {},
	isProduction ? applyMiddleware(thunk) : composeWithDevTools(applyMiddleware(thunk))
);

Store.subscribe(() => {
	saveState(Store.getState());
});

export const getStoreData = () => Store.getState();

export default Store;
