/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {
	useState,
	useEffect
} from "react";
import PropTypes        from "prop-types";
import { connect }		from "react-redux";
import {
	Grid,
	Typography,
	LinearProgress,
	Box,
	Button
}                      from "@material-ui/core";
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineOppositeContent
}					from "@material-ui/lab";
import swal			from "sweetalert2";
import { format }	from "date-fns-tz";
import esLocale		from "date-fns/locale/es";

// Import userService
import userService from "../../../config/services/userService";

// import own components
import CircularProgressWithLabel    	from "../../CircularProgressWithLabel";
import ListFiles 						from "../../ListFiles/ListFiles";
import { mapLogs,  disabilitiesTypes } 	from "./const";
import { mapRequestType, mapStatus }	from "../const";
import useStyles                    	from "./styles";

const RequestHistory = ({
	requestId,
	requestData,
	userType,
	onClose,
	employeeId,
}) => {
	const classes = useStyles();
	const [state, setState] = useState();
	const [files, setFiles] = useState();
	const [graphicsDetails, setGraphicsDetails] = useState({
		timeline     : [],
		statusCount  : 0,
		currentCount : 0,
	});

	useEffect(() => {
		(async () => {
			const data = await userService.getIMSSRequestLog(requestId);
			if (data.status !== 200) {
				swal.fire("¡Error!", "No se han podido cargar los datos de la solicitud", "error");
			}
			const docs = await userService.getLogsDocuments({
				request_id	: requestId,
				user_type 	: userType,
			});
			setFiles(docs.data);
			setState(data.data);
		})();
	}, []);

	useEffect(() => {
		if (requestData && state && Object.keys(requestData).length && Object.keys(state).length) {
			const { posibleStatus } = mapLogs[requestData.request_type];
			let timeline			= [];
			let timeline_extra		= [];
			const timeline_current	= state.map((item) => {
				if (userType === "customer" && posibleStatus[item.status].showToCustomer) {
					return {
						key        : item.status,
						created_at : item.created_at,
						name       : posibleStatus[item.status].customerName,
					};
				}
				if (userType === "employee") {
					return {
						key        : item.status,
						employee   : `${item.employee.name} ${item.employee.lastname}`,
						created_at : item.created_at,
						name       : posibleStatus[item.status].name
					};
				}
			}).filter(i => i !== undefined);
			if (timeline_current[0].key !== "REQUEST_FINISHED" && timeline_current[0].key !== "REQUEST_CANCELED") {
				const active_keys = timeline_current.map(i => i.key);
				timeline_extra = Object.entries(posibleStatus)
					.filter(([key, value]) => value.default && !active_keys.includes(key) && ((userType === "customer" && value.showToCustomer) || (userType === "employee"))).map(([key, value]) => {
						if (userType === "customer" && value.showToCustomer) {
							return {
								key,
								name : value.customerName,
							};
						}
						if (userType === "employee") {
							return {
								key,
								name : value.name
							};
						}
					});
			}
			timeline = [...timeline_current.reverse(), ...timeline_extra];
			const currentCount = timeline_current.length;
			const statusCount = timeline.length;
			setGraphicsDetails({
				timeline,
				statusCount,
				currentCount : timeline_current[timeline_current.length - 1].key !== "REQUEST_CANCELED" ? currentCount : 0,
			});
		}
	}, [requestData, state]);

	if (!requestData.imss_request && !state) {
		return (
			<Box style={{ width : "100%" }}>
				<LinearProgress />
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="caption" component="div" color="textSecondary">
						Cargando datos de la solicitud
					</Typography>
				</Box>
			</Box>
		);
	}

	const handleCancel = async (event) => {
		onClose();
		swal.fire({
			showCancelButton   : true,
			confirmButtonColor : "#3085d6",
			cancelButtonColor  : "#d33",
			confirmButtonText  : "Si, cancelarla",
			title              : "¿Cancelar?",
			text               : "No se puede revertir la acción",
			icon               : "warning",
			cancelButtonText   : "No hacer nada",
		})
			.then(async (result) => {
				if (result.isConfirmed) {
					const response = await userService.cancelRequest(
						requestId,
						{
							responsible_id : employeeId
						}
					);
					if (response.status === 200) {
						swal.fire({
							title             : "!Cancelado!",
							text              : "Tu solicitud ha sido cancelada correctamente",
							icon              : "success",
							timer             : 1500,
							showConfirmButton : false,
						});
					} else {
						swal.fire("!Error!", response.label, "error");
					}
				}
			})
			.catch(swal.noop);
	};

	return (
		<>
			<div className={classes.topHeader} />
			<Grid container className={classes.MainContainer}>
				<Grid item container alignItems="stretch" justify="flex-start" xs={12} spacing={2}>
					<Grid container item lg={6} xs={12} justify="center">
						<Grid item>
							<CircularProgressWithLabel value={100 / graphicsDetails.statusCount * graphicsDetails.currentCount} size={200} />
						</Grid>
					</Grid>
					<Grid item container lg={6} xs={12} direction="column" justify="flex-start">
						<Grid item className={classes.text}>
							<Typography variant="h5">Resumen</Typography>
							{requestData.request_type
							&& (
								<Typography component="span" variant="body1" className={classes.block} color="textPrimary">
							Tipo de Movimiento:
									{" "}
									{mapRequestType[requestData.request_type].name}
								</Typography>
							)}
							{requestData.request_type === "DISABILITIES"
							&& (
								<Typography component="span" variant="body1" className={classes.block} color="textPrimary">
							Tipo de incapacidad:
									{" "}
									{disabilitiesTypes[requestData.imss_request.disability_type]}
								</Typography>
							)}
							{requestData.status
							&& (
								<Typography component="span" variant="body1" className={classes.block} color="textPrimary">
							Estatus:
									{" "}
									{mapStatus[requestData.status].name}
								</Typography>
							)}
							{requestData.customer
							&& (
								<Typography component="span" variant="body1" className={classes.block} color="textPrimary">
							Cliente:
									{" "}
									{requestData.customer.name}
								</Typography>
							)}
							{requestData.imss_request
							&& (
								<Typography component="span" variant="body1" className={classes.block} color="textPrimary">
							Empleado:
									{" "}
									{requestData.imss_request.worker_name}
									{" "}
									{requestData.imss_request.worker_lastname ? requestData.imss_request.worker_lastname : ""}
								</Typography>
							)}
							{userType === "employee" && requestData.responsible_employee
							&& (
								<Typography component="span" variant="body1" className={classes.block} color="textPrimary">
							Atiende:
									{" "}
									{`${requestData.responsible_employee.name} ${requestData.responsible_employee.lastname}`}
								</Typography>
							)}
							{requestData.movement_date
							&& (
								<Typography component="span" variant="body1" className={classes.block} color="textPrimary">
							Fecha de movimiento:
									{" "}
									{format(new Date(requestData.movement_date.replace(" ", "T")), "dd/MMMM/yyyy", { locale : esLocale })}
								</Typography>
							)}
							{ userType === "employee"
								? (
									<Button
										className={`${classes.marginButton} ${classes.cancelButton}`}
										variant="outlined"
										onClick={handleCancel}
										disabled={requestData.status === "CANCELED" || requestData.status === "FINISHED"}
									>
                                Cancelar Solicitud
									</Button>
								) : null
							}
						</Grid>
					</Grid>
				</Grid>
				<Grid container item alignItems="stretch" justify="flex-start" xs={12} spacing={2}>
					<Grid item container lg={6} xs={12} justify="center">
						<Grid item>
							<Timeline align="left">
								{
									graphicsDetails.timeline.map((value, idx) => {
										let date;
										if (value.created_at) {
											date = format(new Date(`${value.created_at.replace(" ", "T")}Z`), "dd/MMMM/yyyy hh:mm aaa",
												{ timezone : Intl.DateTimeFormat().resolvedOptions().timeZone, locale : esLocale });
										}
										return (
											<TimelineItem key={value.name}>
												<TimelineOppositeContent>
													<Typography>{value.name}</Typography>
												</TimelineOppositeContent>
												<TimelineSeparator>
													<TimelineDot color={date ? "primary" : "grey"} />
													<TimelineConnector />
												</TimelineSeparator>
												<TimelineContent>
													<Typography color="textSecondary">{date}</Typography>
													{value.employee
														&& <Typography color="textSecondary">{value.employee}</Typography>
													}
												</TimelineContent>
											</TimelineItem>
										);
									})
								}
							</Timeline>
						</Grid>
					</Grid>
					<Grid item lg={6} xs={12}>
						<Grid item className={classes.text}>
							<Typography variant="h5">Archivos</Typography>
							<Grid container direction="row" justify="flex-start" alignItems="center">
								{
									files && files.length ? (
										<ListFiles
											{...{ files }}
										/>
									) : null
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

RequestHistory.propTypes = {
	requestId  	: PropTypes.number.isRequired,
	userType   	: PropTypes.string.isRequired,
	onClose     : PropTypes.func.isRequired,
	employeeId  : PropTypes.number.isRequired,
	requestData	: PropTypes.object,
};


RequestHistory.defaultProps = {
	requestData	: {}
};

const mapStateToProps = ({ authReducer: { customerData } }) => ({
	employeeId : customerData.id,
});

export default connect(mapStateToProps, null)(RequestHistory);
