/* eslint-disable no-console */
/* eslint-disable consistent-return */
export const loadState = () => {
	try {
		const stringifiedData = localStorage.getItem("state");

		if (stringifiedData === null) {
			return undefined;
		}

		return JSON.parse(stringifiedData);
	} catch (err) {
		console.error("[Persistor] An exception has ocurred while loading the state: ", err);
		return undefined;
	}
};

export const saveState = (state) => {
	const storage = {
		loadingReducer : state.loadingReducer,
		authReducer    : state.authReducer,
		drawerReducer  : state.drawerReducer,
	};
	try {
		const stringifiedData = JSON.stringify(storage);

		localStorage.setItem("state", stringifiedData);
	} catch (err) {
		console.error("[Persistor] An exception has ocurred while saving the state: ", err);
		return undefined;
	}
};
