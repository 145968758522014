import React, {
	useState,
	useEffect
} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const CircularDeterminateLoading = (props) => {
	const [progress, setProgress] = useState(50);

	useEffect(() => {
		const tick = () => {
			// Reset when reaching 100%
			setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
		};

		const timer = setInterval(tick, 20);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<CircularProgress variant="determinate" value={progress} />
	);
};

export default CircularDeterminateLoading;
