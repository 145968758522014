import React from "react";
import PropTypes from "prop-types";
import { connect }              from "react-redux";
import {
	Switch,
	Route
} from "react-router-dom";

import AppRoutes, { routesEmployees } from "../../config/routes";
// Import Layouts
import CustomerLayout   from "../CustomerLayout";
import EmployeeLayout   from "../EmployeeLayout";


const SwitchLayout = ({ userType, customerData }) => {
	const canUseEmployerRoutes = route => (!route.employer
		|| (route.employer && customerData
		&& customerData.employer_registration
		&& customerData.employer_registration.length));


	switch (userType) {
		case "customer":
			return (
				<CustomerLayout>
					<Switch>
						{ AppRoutes.map(route => canUseEmployerRoutes(route)
						&& (
							<Route {...route} key={route.path || "not_found"} />
						)) }
					</Switch>
				</CustomerLayout>
			);
		case "employee":
			return (
				<EmployeeLayout>
					<Switch>
						{ routesEmployees.map(route => (
							<Route {...route} key={route.path || "not_found"} />
						)) }
					</Switch>
				</EmployeeLayout>
			);
		default:
			return <></>;
	}
};

SwitchLayout.propTypes = {
	userType     : PropTypes.string.isRequired,
	customerData : PropTypes.object.isRequired,
};


const mapStateToProps = ({ authReducer : { type, customerData } }) => ({
	userType : type,
	customerData
});

export default connect(mapStateToProps, null)(SwitchLayout);
