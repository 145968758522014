import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Grid,
	withStyles
} from "@material-ui/core";

import slug from "../../../config/helpers/slug";

import ModuleContainer from "../../../components/ModuleContainer";
import CardDocument    from "../../../components/CardDocuments";
import fileService     from "../../../config/services/fileService";

import "./Categories.scss";

const styles = theme => ({
	root : {
		flexGrow : 1,
		padding  : 24,
	},
	paper : {
		padding   : theme.spacing(2),
		textAlign : "center",
		color     : theme.palette.text.secondary,
	},
});
const Categories = ({
	match : {
		params : {
			categoryId,
		},
	},
}) => {
	const colors = [
		"#ADEE77",
		"#77EEE7",
		"#77A0EE",
		"#CAD83B",
		"#F5E829",
		"#EE7777",
		"#D6D5C6",
		"#AD77EE",
		"#EE77B1",
		"#EEB477",
	];
	const [categories, setCategories] = useState();

	useEffect(() => {
		(async () => {
			try {
				const subCategoryResponse = await fileService.getCategories(categoryId);
				setCategories({
					categories : subCategoryResponse.data
				});
			} catch (err) {
				// console.log(err);
			}
		})();
	}, []);

	return (
		<div className="Categories">
			<ModuleContainer
				title="Documentos > categorias"
				color="#f3f0f0"
				background="#ab2577"
				icon="far fa-folder-tree"
			>
				<div className="topHeader" />
				<Grid container direction="row" justify="flex-start" alignItems="center">
					{
						categories && (
							categories.categories.map(subCategory => (
								<CardDocument
									key={subCategory.id}
									name={subCategory.name}
									link={`/documentos/categorias.${categoryId}/${slug(subCategory.name)}.${subCategory.id}`}
									categoryId={categoryId}
									subCateogoryId={subCategory.id}
									icon="fas fa-folder"
									color={colors[Math.floor(Math.random() * (colors.length))]}
								/>
							))
						)
					}
				</Grid>
			</ModuleContainer>
		</div>
	);
};

Categories.propTypes = {
	match : PropTypes.object.isRequired,
};

export default withStyles(styles)(Categories);
