export const mapLogs = {
	REGISTER_WORKER : {
		posibleStatus : {
			REQUEST_RECEIVED : {
				showToCustomer : true,
				name           : "Solicitud realizada",
				customerName   : "Solicitud realizada",
				default        : true,
			},
			PAYROLL_REGISTER : {
				showToCustomer : false,
				name           : "Alta en nóminas",
				default        : true,
			},
			PAYROLL_SUA_CONNECTION : {
				showToCustomer : false,
				name           : "Conexión Nominas/SUA",
				default        : true,
			},
			SUA_IDSE_CONNECTION : {
				showToCustomer : true,
				name           : "Conexión SUA/IDSE",
				customerName   : "En revisión por IMSS",
				default        : true,
			},
			IMSS_APPROBATION : {
				showToCustomer : false,
				name           : "Solicitud aprobada por el IMSS",
				default        : true,
			},
			IMSS_REJECTION : {
				showToCustomer : true,
				name           : "Solicitud rechazada por el IMSS",
				customerName   : "Solicitud rechazada por el IMSS",
			},
			REQUEST_FINISHED : {
				showToCustomer : true,
				name           : "Solicitud finalizada",
				customerName   : "Solicitud finalizada",
				default        : true,
			},
			REQUEST_CANCELED : {
				showToCustomer : true,
				name           : "Solicitud cancelada",
				customerName   : "Solicitud cancelada",
			},
		},
	},
	WITHDRAWAL : {
		posibleStatus : {
			REQUEST_RECEIVED : {
				showToCustomer : true,
				name           : "Solicitud realizada",
				customerName   : "Solicitud realizada",
				default        : true,
			},
			PAYROLL_REGISTER : {
				showToCustomer : false,
				name           : "Baja en nominpaq",
				default        : true,
			},
			PAYROLL_SUA_CONNECTION : {
				showToCustomer : false,
				name           : "Conexión Nominas/SUA",
				default        : true,
			},
			SUA_IDSE_CONNECTION : {
				showToCustomer : true,
				name           : "Conexión SUA/IDSE",
				customerName   : "En revisión por IMSS",
				default        : true,
			},
			IMSS_APPROBATION : {
				showToCustomer : false,
				name           : "Solicitud aprobada por el IMSS",
				default        : true,
			},
			IMSS_REJECTION : {
				showToCustomer : true,
				name           : "Solicitud rechazada por el IMSS",
				customerName   : "Solicitud rechazada por el IMSS",
			},
			REQUEST_FINISHED : {
				showToCustomer : true,
				name           : "Solicitud finalizada",
				customerName   : "Solicitud finalizada",
				default        : true,
			},
			REQUEST_CANCELED : {
				showToCustomer : true,
				name           : "Solicitud cancelada",
				customerName   : "Solicitud cancelada",
			},
		},
	},
	NEW_SALARY : {
		posibleStatus : {
			REQUEST_RECEIVED : {
				showToCustomer : true,
				name           : "Solicitud realizada",
				customerName   : "Solicitud realizada",
				default        : true,
			},
			PAYROLL_REGISTER : {
				showToCustomer : false,
				name           : "Alta en nóminas",
				default        : true,
			},
			PAYROLL_SUA_CONNECTION : {
				showToCustomer : false,
				name           : "Conexión Nominas/SUA",
				default        : true,
			},
			SUA_IDSE_CONNECTION : {
				showToCustomer : true,
				name           : "Conexión SUA/IDSE",
				customerName   : "En revisión por IMSS",
				default        : true,
			},
			IMSS_APPROBATION : {
				showToCustomer : false,
				name           : "Solicitud aprobada por el IMSS",
				default        : true,
			},
			IMSS_REJECTION : {
				showToCustomer : true,
				name           : "Solicitud rechazada por el IMSS",
				customerName   : "Solicitud rechazada por el IMSS",
			},
			REQUEST_FINISHED : {
				showToCustomer : true,
				name           : "Solicitud finalizada",
				customerName   : "Solicitud finalizada",
				default        : true,
			},
			REQUEST_CANCELED : {
				showToCustomer : true,
				name           : "Solicitud cancelada",
				customerName   : "Solicitud cancelada",
			},
		},
	},
	DISABILITIES : {
		posibleStatus : {
			REQUEST_RECEIVED : {
				showToCustomer : true,
				name           : "Solicitud realizada",
				customerName   : "Solicitud realizada",
				default        : true,
			},
			PAYROLL_REGISTER : {
				showToCustomer : false,
				name           : "Alta en nóminas",
				default        : true,
			},
			PAYROLL_SUA_CONNECTION : {
				showToCustomer : false,
				name           : "Conexión con SUA",
				default        : true,
			},
			REQUEST_OCCUPATIONAL_RISK : {
				showToCustomer : false,
				name           : "Solicitud de probable riesgo de trabajo",
				default        : true,
			},
			DISABILITY_VOUCHER_FILE : {
				showToCustomer : false,
				name           : "Comprobante de incapacidad",
				default        : true,
			},
			OCCUPATIONAL_RISK_QUALIFICATION : {
				showToCustomer : false,
				name           : "Calificación de riesgo de trabajo",
				default        : true,
			},
			INABILITY_TERMINATION : {
				showToCustomer : false,
				name           : "Terminación de incapacidad",
				default        : true,
			},
			REQUEST_FINISHED : {
				showToCustomer : true,
				name           : "Solicitud finalizada",
				customerName   : "Solicitud finalizada",
				default        : true,
			},
			REQUEST_CANCELED : {
				showToCustomer : true,
				name           : "Solicitud cancelada",
				customerName   : "Solicitud cancelada",
			},
			PROBABLE_WORK_RISK_ST7 : {
				showToCustomer : false,
				name           : "Aviso de probable accidente de trabajo (ST-7)",
				default        : false,
			},
			SUBSEQUENT_DECISION : {
				showToCustomer : false,
				name           : "Elección de incapacidad subsecuente",
				default        : true,
			},
		},
	},
	EMPLOYMENT_CREDIT : {
		posibleStatus : {
			REQUEST_RECEIVED : {
				showToCustomer : true,
				name           : "Solicitud realizada",
				customerName   : "Solicitud realizada",
				default        : true,
			},
			PAYROLL_REGISTER : {
				name    : "Alta en nóminas",
				default : true,
			},
			PAYROLL_SUA_CONNECTION : {
				name    : "Conexión Nominas/SUA",
				default : true,
			},
			CREDIT_RETENTION_VOUCHER_FILE : {
				name    : "Se adjunto aviso de retención",
				default : true,
			},
			REQUEST_FINISHED : {
				showToCustomer : true,
				name           : "Solicitud finalizada",
				customerName   : "Solicitud finalizada",
				default        : true,
			},
			REQUEST_CANCELED : {
				showToCustomer : true,
				name           : "Solicitud cancelada",
				customerName   : "Solicitud cancelada",
			},
		},
	},
	EMPLOYER_REGISTRATION : {
		posibleStatus : {
			REQUEST_RECEIVED : {
				showToCustomer : true,
				name           : "Solicitud realizada",
				customerName   : "Solicitud realizada",
				default        : true,
			},
			REQUEST_EMPLOYER_DOCUMENTS : {
				showToCustomer : false,
				name           : "Solicitud documentación del cliente",
				default        : true,
			},
			REGISTER_AT_SAT : {
				showToCustomer : false,
				name           : "Alta en el SAT",
				default        : true,
			},
			SAVE_SAT_ACK : {
				showToCustomer : false,
				name           : "Almacenado acuse de alta en el SAT",
				default        : false,
			},
			REGISTER_AT_IMSS_DESK : {
				showToCustomer : false,
				name           : "Alta en el escritorio virtual IMSS",
				default        : true,
			},
			SAVE_TIP : {
				showToCustomer : true,
				name           : "Almacenada Tarjeta Patronal",
				customerName   : "Enviada Tarjeta Patronal",
				default        : true,
			},
			REQUEST_PAYROLL_TAX_DOCUMENTS : {
				showToCustomer : false,
				name           : "Solicitud de documentación para el impuesto sobre nómina",
				default        : true,
			},
			REGISTER_PAYROLL_TAX : {
				showToCustomer : false,
				name           : "Alta del impuesto sobre nómina",
				default        : true,
			},
			SAVE_PAYROLL_TAX_ACK : {
				showToCustomer : false,
				name           : "Guardar hoja de impuesto sobre nómina",
				default        : true,
			},
			REQUEST_FINISHED : {
				showToCustomer : true,
				name           : "Solicitud finalizada",
				customerName   : "Solicitud finalizada",
				default        : true,
			},
			REQUEST_CANCELED : {
				showToCustomer : true,
				name           : "Solicitud cancelada",
				customerName   : "Solicitud cancelada",
			},
		},
	},
	EMPLOYER_REGISTRATION_WITHDRAWAL : {
		posibleStatus : {
			REQUEST_RECEIVED : {
				showToCustomer : true,
				name           : "Solicitud realizada",
				customerName   : "Solicitud realizada",
				default        : true,
			},
			WITHDRAWAL_AT_SAT : {
				showToCustomer : false,
				name           : "Baja en el SAT",
				default        : true,
			},
			SAVE_SAT_ACK : {
				showToCustomer : false,
				name           : "Guardar acuse de baja en el SAT",
				default        : true,
			},
			WITHDRAWAL_CUSTOMER_DOCS : {
				showToCustomer : false,
				name           : "Entrega de documentación al cliente para realizar baja",
				default        : true,
			},
			SAVE_EMPLOYER_WITHDRAWAL_ACK : {
				showToCustomer : false,
				name           : "Guardado acuse de baja patronal",
				default        : true,
			},
			WITHDRAWAL_PAYROLL_TAX : {
				showToCustomer : false,
				name           : "Baja del impuesto sobre nómina",
				default        : true,
			},
			SAVE_WITHDRAWAL_PAYROLL_TAX_ACK : {
				showToCustomer : false,
				name           : "Guardado de acuse de baja del impuesto sobre nómina",
				default        : true,
			},
			REQUEST_FINISHED : {
				showToCustomer : true,
				name           : "Solicitud finalizada",
				customerName   : "Solicitud finalizada",
				default        : true,
			},
			REQUEST_CANCELED : {
				showToCustomer : true,
				name           : "Solicitud cancelada",
				customerName   : "Solicitud cancelada",
			},
		},
	},
};

export const disabilitiesTypes = {
	GENERAL_ILLNESS   : "Enfermedad general",
	MATERNITY         : "Maternidad",
	OCCUPATIONAL_RISK : "Riesgo de trabajo",
};
