import React, {
	useState,
	useEffect
} from "react";
import { Route }       from "react-router-dom";
import SwipeableRoutes from "react-swipeable-routes";
import {
	Tabs,
	Tab,
	makeStyles,
	Card
} from "@material-ui/core";

// Import Own Components
import { history }         from "../../../config/helpers";
import DisplayCustomerData from "./DisplayCustomerData.jsx";
import DisplayFiscalData   from "./DisplayFiscalData.jsx";
import "./GeneralDataCard.scss";

const useStyles = makeStyles(theme => ({
	tab : {
		flexGrow         : 1,
		backgroundColor  : theme.palette.grey[200],
		boxShadow        : theme.shadows[8],
		color            : theme.palette.grey[800],
		textTransform    : "none",
		borderRadius     : "5%",
		"&.Mui-selected" : {
			background : theme.palette.primary.main,
			color      : theme.palette.common.white,
		},
		transition : theme.transitions.create("all", {
			duration : theme.transitions.duration.short,
			easing   : theme.transitions.easing.easeInOut,
		}),
	},
	TabIndicator : {
		backgroundColor : "transparent",
	},
}));

const GeneralDataCard = (props) => {
	const classes = useStyles();
	const routes  = ["/perfil", "/perfil/datos_fiscales"];

	const [currentRouteValue, setCurrentRouteValue] = useState(
		routes.indexOf(history.location.pathname)
	);

	useEffect(() => {
		const callToUnlisten = history.listen((location, action) => {
			setCurrentRouteValue(routes.indexOf(location.pathname));
		});

		return () => {
			callToUnlisten();
		};
	}, []);

	if (currentRouteValue === -1) {
		history.push("/no_se_ha_encontrado");
	}

	return (
		<div className="GeneralDataCard">
			<Tabs
				value={currentRouteValue}
				indicatorColor="primary"
				TabIndicatorProps={{
					className : classes.TabIndicator,
				}}
				className="Tabs"
			>
				<Tab
					label="Información"
					className={`${classes.tab} tab`}
					onClick={() => history.push("/perfil")}
				/>
				<Tab
					label="Datos Fiscales"
					className={`${classes.tab} tab`}
					onClick={() => history.push("/perfil/datos_fiscales")}
				/>
			</Tabs>

			<Card className="Card">
				<SwipeableRoutes>
					<Route path="/perfil" exact component={DisplayCustomerData} />
					<Route path="/perfil/datos_fiscales" exact component={DisplayFiscalData} />
				</SwipeableRoutes>
			</Card>

			<div className="spacer">
				&nbsp;
			</div>
		</div>
	);
};

export default GeneralDataCard;
