const apiUrl = process.env.REACT_APP_SYSCONTROL_API;

const JSONApplicationHeaders = {
	"Content-Type" : "application/json",
	Accept         : "application/json"
};

const handleResponse = async (res) => {
	const data = await res.clone().json();
	if (!res.ok || data.message === "error") {
		const error =  data.message || data.label || "Ha ocurrido un error";
		return Promise.reject(error);
	}
	return data;
};

const postFile = async (params) => {
	const data = new FormData();

	data.append("name", params.name);
	data.append("customer_id", params.customerId);
	data.append("category_id", params.categoryId);
	data.append("date_document", params.dateDocument);
	data.append("type", params.type);
	data.append("storage", params.storage);
	data.append("file", params.file);
	data.append("user", params.user);

	const requestOptions = {
		method : "POST",
		body   : data
	};

	const url = `${apiUrl}/documents`;
	const response  = await fetch(url, requestOptions);

	return response;
};

const getCategories = async (dependencyId) => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const response  = await fetch(`${apiUrl}/category?dependency_id=${dependencyId}&perPage=100`, requestOptions).then(handleResponse);

	return response;
};

const getDependencies = async () => {
	const requestOptions = {
		method  : "GET",
		headers : JSONApplicationHeaders,
	};

	const response  = await fetch(`${apiUrl}/dependency`, requestOptions).then(handleResponse);

	return response;
};


const fileService = {
	postFile,
	getCategories,
	getDependencies,
};

export default fileService;
