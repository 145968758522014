import React from "react";
import PropTypes	from "prop-types";

// import own components
import ModuleContainer	from "../../../components/ModuleContainer";
import RegisterWorker   from "../../../components/Requests/RegisterWorker";

const RegisterWorkerPage = ({ match }) => (
	<div>
		<ModuleContainer
			title="Tramite IMSS > Alta de Empleado"
			color="#f3f0f0"
			background="#13322b"
			icon="fas fa-user-hard-hat"
			md={11}
			lg={11}
		>
			<RegisterWorker
				match={match}
			/>
		</ModuleContainer>
	</div>
);

RegisterWorkerPage.propTypes = {
	match	: PropTypes.object.isRequired,
};

export default RegisterWorkerPage;
