/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import React, {
	useState,
	useEffect,
	useRef
} from "react";
import PropTypes	from "prop-types";
import { format }	from "date-fns";
import { connect }	from "react-redux";
import {
	FormControl,
	MenuItem,
	TextField,
	Grid,
	Divider,
	Typography,
	Box,
	LinearProgress
} from "@material-ui/core";
// import sub 				from "date-fns/sub";
import swal				from "sweetalert2";
import esLocale			from "date-fns/locale/es";

// Import userService
import userService		from "../../../config/services/userService";

// import own components
import CustomerIdentifier	from "../Components/CustomerIdentifier";
import FormButtons			from "../Components/FormButtons";
import EmployeeIdentifier   from "../Components/EmployeeIdentifier";
import CustomDatePicker		from "../Components/CustomDatePicker";
import {
	withdrawalCauses,
	initState,
	validFormInit,
	statusLabel
}		from "./const";
import "./WithdrawalWorker.scss";

// TODO: Create Service for BranchOffice and modify places

const WithdrawalWorker = ({
	customerData, loggedIn, match, mode, requestData,
}) => {
	if (mode === "VIEW" && !requestData.imss_request) {
		return (
			<Box style={{ width : "100%" }}>
				<LinearProgress />
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="caption" component="div" color="textSecondary">
						Cargando datos de la solicitud
					</Typography>
				</Box>
			</Box>

		);
	}

	const [withdrawalData, setWithdrawalData]	= useState(initState);
	const [validForm, setValidForm]				= useState(validFormInit);

	const isDisabled = () => (mode === "VIEW");

	useEffect(() => {
		if (mode === "VIEW" && requestData.imss_request) {
			const request = {
				customerName        	: requestData.imss_request.customer_name,
				branchOffice        	: requestData.imss_request.branch_office,
				workerName          	: requestData.imss_request.worker_name,
				workerLastName      	: requestData.imss_request.worker_lastname,
				workerRFC           	: requestData.imss_request.worker_rfc,
				withdrawalCause     	: requestData.imss_request.withdrawal_cause,
				withdrawalOtherCause	: requestData.imss_request.withdrawal_other_reason,
				details             	: requestData.imss_request.details,
				movementDate        	: requestData.movement_date,
			};

			setWithdrawalData({
				...withdrawalData,
				formData : {
					...request
				}
			});
		}
	}, [requestData]);

	const handleValidation = async (name, valid) => {
		await setValidForm({
			...validForm,
			[name] : valid,
		});
	};

	const identifierRef = useRef(null);
	const employeeRef = useRef(null);

	const clearForm = () => {
		identifierRef.current.cleanValues();
		employeeRef.current.cleanValues();
		setWithdrawalData(initState);
	};

	const handleChange = name => (d) => {
		if (name === "branchOfficeId") {
			handleValidation(name, true);
			setWithdrawalData({
				...withdrawalData,
				formData : {
					...withdrawalData.formData,
					...d
				}
			});
			return;
		}
		const value = d && d.target ? d.target.value : d;
		handleValidation(name, true);
		setWithdrawalData({
			...withdrawalData,
			formData : {
				...withdrawalData.formData,
				[name] : value,
			}
		});
	};

	const handleChangeMulti = (obj) => {
		setWithdrawalData({
			...withdrawalData,
			formData : {
				...withdrawalData.formData,
				...obj
			}
		});
	};

	const setEmployee = (data) => {
		if (!data) {
			setWithdrawalData({
				...withdrawalData,
				formData : {
					...withdrawalData.formData,
					workerRFC : "",
				}
			});
			return;
		}

		setWithdrawalData({
			...withdrawalData,
			formData : {
				...withdrawalData.formData,
				workerName     : data.name,
				workerLastName : data.lastname,
				workerRFC      : data.rfc,
				workerId       : data.id,

			}
		});
	};

	const generateConfirmationHTML = (formData) => {
		let html = "<table style='width: 100%;border-collapse: collapse;'><tbody>";
		const lhtml = "<tr style='border-bottom: 1px solid #e3e3e3;'><td style=\"text-align:left;\">";
		const mhtml = "</td><td style=\"text-align:right;word-wrap:anywhere;\">";
		const rhtml = "</td></tr>";
		for (const key in formData) {
			if (formData.customerName && key === "customerName") {
				html += lhtml + statusLabel.customerName + mhtml + formData.customerName + rhtml;
			} else if (formData.withdrawalCause && key === "withdrawalCause") {
				html += lhtml + statusLabel.withdrawalCause + mhtml + withdrawalCauses[formData[key]] + rhtml;
			} else if (key === "movementDate") {
				html += lhtml + statusLabel.movementDate + mhtml
					+ format(new Date(formData[key]), "dd-MMMM-yyyy", { locale : esLocale }) + rhtml;
			} else if (key === "branchOfficeId" || key === "customerId" || key === "workerId") {
				continue;
			} else {
				html += lhtml + statusLabel[key] + mhtml + formData[key] + rhtml;
			}
		}

		return `${html}</tbody></table>`;
	};

	const formValidate = async () => new Promise((resolve) => {
		const validate = {
			validate	: false,
			error  		: "Debes completar el formulario"
		};
		const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

		const validFormSync = {};
		Object.assign(validFormSync, validFormInit);
		for (const key in validFormInit) {
			if (!withdrawalData.formData[key]) {
				validFormSync[key] = false;
			}
		}
		setValidForm(validFormSync);

		if ((withdrawalData.formData.customerName
			|| withdrawalData.formData.customerId)
			&& (withdrawalData.formData.branchOffice
			|| withdrawalData.formData.branchOfficeId)
			&& withdrawalData.formData.workerName
			&& withdrawalData.formData.workerRFC
			&& withdrawalData.formData.withdrawalCause) {
			if (!withdrawalData.formData.workerRFC.match(re)) {
				validate.error = "El RFC no es Valido";
				handleValidation("workerRFC", false);
			}
			if (withdrawalData.formData.withdrawalCause === "OTHER" && !withdrawalData.formData.withdrawalOtherCause) {
				validate.error = "Es necesario describir el motivo de la baja";
				handleValidation("withdrawalOtherCause", false);
			} else {
				validate.validate = true;
			}
			resolve(validate);
		}

		resolve(validate);
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		setWithdrawalData({
			...withdrawalData,
			submitted : true,
		});

		try {
			const validateForm = await formValidate();
			if (validateForm.validate) {
				const formData = {};
				for (const key in withdrawalData.formData) {
					if (withdrawalData.formData[key]) {
						formData[key] = withdrawalData.formData[key];
					}
				}
				swal.fire({
					title             : "Revisar Datos",
					html              : generateConfirmationHTML(formData),
					icon              : "info",
					width             : 700,
					showCancelButton  : true,
					confirmButtonText : "Enviar",
					cancelButtonText  : "Cancelar",
				}).then(async (result) => {
					if (result.isConfirmed) {
						const response = await userService.createRequest({
							request     : JSON.stringify(formData),
							requestType : "WITHDRAWAL",
						});
						if (response.status === 200) {
							swal.fire("!Enviado!", "Tu solicitud ha sido enviada correctamente!", "success");
							clearForm();
						} else {
							swal.fire("!Error!", response.label, "error");
						}
					}
				});
			} else {
				swal.fire("Error", validateForm.error, "warning");
			}
		} catch (error) {
			swal.fire(`Error ${error}`, "error");
		}
	};


	return (
		<div className="WithdrawalWorker">
			<div className="topHeader" />
			<CustomerIdentifier
				handleChange={handleChange}
				validForm={validForm}
				status={withdrawalData}
				requireBranchOffice
				match={match}
				mode={mode}
				isDisabled={isDisabled}
				handleChangeMulti={handleChangeMulti}
				ref={identifierRef}
				clearForm={clearForm}
			/>

			{/* Separator */}
			<Grid
				container
				justify="center"
				alignItems="center"
			>
				<Grid item xs={11}>
					<Divider />
				</Grid>
			</Grid>

			{/* Worker Data */}
			<Grid
				container
				spacing={2}
				justify="center"
				alignItems="flex-start"
			>
				<Grid item md={8} xs={11} container spacing={2} justify="center">
					<Grid item xs={11}>
						<Typography variant="h5" component="h2" style={{ paddingBottom : "1rem", textAlign : "center" }}>
							Datos del Trabajador a Dar de Baja
						</Typography>
					</Grid>
					<Grid item md={6} container direction="column" spacing={2}>
						<Grid item xs>
							<EmployeeIdentifier
								key={withdrawalData.formData.customerId}
								handleChange={handleChange}
								validForm={validForm}
								status={withdrawalData}
								mode={mode}
								isDisabled={isDisabled}
								setEmployee={setEmployee}
								ref={employeeRef}
								customerId={withdrawalData.formData.customerId}
								branchOfficeId={withdrawalData.formData.branchOfficeId}
								selectEmployee
								branchOfficeRequired
							/>
						</Grid>
						<Grid item xs>
							<FormControl className="formControl">
								<TextField
									error={!validForm.withdrawalCause}
									InputProps={{
										readOnly : isDisabled(),
									}}
									select={!isDisabled()}
									required
									variant="outlined"
									id="withdrawalCause"
									label="Causa de Baja"
									value={withdrawalData.formData.withdrawalCause && !isDisabled()
										? withdrawalData.formData.withdrawalCause
										: withdrawalData.formData.withdrawalCause && isDisabled()
											? withdrawalCauses[withdrawalData.formData.withdrawalCause] : ""
									}
									onChange={handleChange("withdrawalCause")}
								>
									{
										Object.entries(withdrawalCauses).map(
											(value, idx) => <MenuItem key={idx} value={value[0]}>{value[1]}</MenuItem>
										)
									}
								</TextField>
							</FormControl>
						</Grid>
						{withdrawalData.formData.withdrawalCause === "OTHER" && (
							<Grid item xs>
								<FormControl className="formControl">
									<TextField
										error={!validForm.withdrawalOtherCause}
										InputProps={{
											readOnly : isDisabled(),
										}}
										required
										variant="outlined"
										id="withdrawalOtherCause"
										label="Detalla el motivo de la baja"
										value={withdrawalData.formData.withdrawalOtherCause && withdrawalData.formData.withdrawalCause === "OTHER"
											 ? withdrawalData.formData.withdrawalOtherCause : ""}
										onChange={handleChange("withdrawalOtherCause")}
									/>
								</FormControl>
							</Grid>
						)}
					</Grid>
					<Grid item md={6} container direction="column" spacing={2}>
						<Grid item xs>
							<TextField
								error={!validForm.workerRFC}
								InputProps={{
									readOnly : isDisabled(),
									style    : { textTransform : "uppercase" }
								}}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								inputProps={{
									minLength   : 12,
									maxLength   : 13,
									placeholder : "AAAA000000AA0",
									className  	: "validation"
								}}
								value={withdrawalData.formData.workerRFC ? withdrawalData.formData.workerRFC : ""}
								required
								className="formControl"
								id="worker-rfc"
								label="RFC del Trabajador"
								onChange={e => handleChange("workerRFC")(e.target.value.toUpperCase())}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								id="details"
								InputProps={{
									readOnly : isDisabled(),
								}}
								value={withdrawalData.formData.details ? withdrawalData.formData.details : ""}
								className="formControl"
								onChange={handleChange("details")}
								label="Detalles u Observaciones"
								multiline
								rows={4}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={3} xs={11} container spacing={2} direction="column" justify="center">
					<CustomDatePicker handleChange={handleChange} movementDate={withdrawalData.formData.movementDate} mode={mode} isDisabled={isDisabled} />
					{mode !== "VIEW" && (
						<FormButtons handleSubmit={handleSubmit} match={match} />
					)}
				</Grid>
			</Grid>
			<hr style={{ border : "1rem solid #fff" }} />
		</div>
	);
};

WithdrawalWorker.propTypes = {
	match       	: PropTypes.object.isRequired,
	customerData	: PropTypes.object,
	loggedIn    	: PropTypes.bool,
	mode        	: PropTypes.string,
	requestData 	: PropTypes.object,
};

WithdrawalWorker.defaultProps = {
	customerData : {},
	loggedIn     : false,
	mode         : "NEW",
	requestData  : {},
};


const mapStateToProps = ({ authReducer: { customerData, loggedIn } }) => ({
	customerData,
	loggedIn
});

export default connect(mapStateToProps, null)(WithdrawalWorker);
