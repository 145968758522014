/* eslint-disable react/jsx-curly-brace-presence */
import React, {
	useEffect,
	useState
} from "react";
import PropTypes   from "prop-types";
import { connect } from "react-redux";
import {
	Typography,
	withStyles
} from "@material-ui/core";

// Import Own Components
import AccountData                    from "./AccountData.jsx";
import { CircularDeterminateLoading } from "../Loading";
import userService                    from "../../config/services/userService";
import "./ChangeAccount.scss";

const styles = theme => ({
	greyColor : {
		color : theme.palette.grey[700],
	},
});

const ChangeAccount = ({ customerData, userData, classes }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [accounts, setAccounts]   = useState(null);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);

				const response = await userService.getAllAccounts(userData.user_id);

				if (!response.ok) {
					throw Error("An exception has occurred: ", response);
				}

				const responseData = (await response.json()).response;

				setAccounts(Array.isArray(responseData) ? responseData : []);
			} catch (err) {
				// console.log("err", err);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [userData]);

	return (
		<div className="ChangeAccount">
			<div className="title">
				<i className={`${classes.greyColor} fas fa-user-friends`} />
				<Typography
					variant="h5"
					component="h4"
					className={classes.greyColor}
				>
					{"Tus cuentas"}
				</Typography>
			</div>

			<div className="AccountsList">
				{ (!accounts || !accounts.length || accounts.length <= 0) ? (
					<>
						<AccountData
							title={customerData.name}
							subheader="Estás usando esta cuenta"
							file_id={userData.file_id}
							id={customerData.id}
						/>
						<Typography
							variant="subtitle1"
							component="span"
						>
							{"No se han encontrado más cuentas"}
						</Typography>
					</>
				) : (
					accounts.map(({ name, id }) => (
						<AccountData
							title={name}
							subheader={id === customerData.id ? "Estás usando esta cuenta" : "Cuenta inactiva"}
							id={id}
						/>
					))
				) }
			</div>

			{ isLoading && (
				<div className="ChangeAccountLoading">
					<CircularDeterminateLoading />
				</div>
			) }
		</div>
	);
};

ChangeAccount.propTypes = {
	customerData : PropTypes.object.isRequired,
	userData     : PropTypes.object.isRequired,
	classes      : PropTypes.object.isRequired,
};

const mapStateToProps = ({ authReducer : { customerData, userData } }) => ({ customerData, userData });

export default connect(mapStateToProps, null)(
	withStyles(styles)(ChangeAccount)
);
