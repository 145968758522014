/* eslint-disable camelcase */
import React,
{
	useState,
	useEffect
}					from "react";
import PropTypes				from "prop-types";
import { connect }  			from "react-redux";
import { bindActionCreators }	from "redux";
import {
	Fab,
	Grid,
	Dialog,
	Slide,
	List,
	ListItem,
	Typography,
	ListItemText
}	from "@material-ui/core";
import AddIcon			from "@material-ui/icons/Add";
import swal				from "sweetalert2";
import { format }		from "date-fns-tz";
import esLocale			from "date-fns/locale/es";
import ModalActions		from "../../config/store/actions/modalActions.js";

// Import userService
import userService		from "../../config/services/userService";

// import own components
import ModuleContainer					from "../ModuleContainer";
import CircularProgressWithLabel		from "../CircularProgressWithLabel";
import RequestSelector					from "./RequestSelector";
import RequestDetails					from "./RequestDetails";
import useStyles						from "./styles";
import {
	mapStatus, mapRequestType,
	disabilitiesTypes
}	from "./const";
import "./Requests.scss";


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Requests = ({
	match, customerId, modalActions, userType
}) => {
	const [openSelector, setOpenSelector]	= useState(false);
	const [openDetails, setOpenDetails]		= useState(false);
	const [reload, setReload]				= useState(false);
	const [requests, setRequests]			= useState([]);

	const handleRequestSelector = () => {
		setOpenSelector(!openSelector);
	};

	const handleOpenDetails = () => {
		if (openDetails) {
			setReload(!reload);
		}
		setOpenDetails(!openDetails);
	};

	const classes = useStyles();

	useEffect(() => {
		(
			async () => {
				const data = await userService.getIMSSRequests({ customer_id : customerId });
				if (data.status !== 200) {
					swal.fire("¡Error!", "Ha ocurrido un error al cargar las solicitudes", "error");
					return;
				}
				setRequests(data.data);
			}
		)();
	}, [reload]);

	const showDetails = e => (id, name, request_type) => {
		handleOpenDetails();
		modalActions.openModal({
			title     : `Solicitud: ${name}`,
			iconTitle : mapRequestType[request_type].icon,
			md        : 11,
			lg        : 11,
			onClose   : handleOpenDetails,
			component : <RequestDetails
				requestId={id}
				requestType={request_type}
				userType={userType}
			/>
		});
	};


	return (
		<div className="Requests">
			<ModuleContainer
				title="SOLICITUDES PARA IMSS"
				color="#f3f0f0"
				background="#13322b"
				icon="fas fa-inbox-in"
			>
				<div className="topHeader" />

				<List component="nav" className={classes.root}>
					{requests.length ? requests.map((request) => {
						const newDate = new Date(`${request.created_at.replace(" ", "T")}Z`);
						const movDate = new Date(`${request.movement_date.replace(" ", "T")}Z`);
						const date = format(movDate, "dd/MMMM/yyyy",
							{ timezone : Intl.DateTimeFormat().resolvedOptions().timeZone, locale : esLocale });
						const day = format(newDate, "dd",
							{ timezone : Intl.DateTimeFormat().resolvedOptions().timeZone, locale : esLocale });
						const month = format(newDate, "LLL",
							{ timezone : Intl.DateTimeFormat().resolvedOptions().timeZone, locale : esLocale });
						const year = format(newDate, "yy",
							{ timezone : Intl.DateTimeFormat().resolvedOptions().timeZone, locale : esLocale });
						const statusMapped = mapStatus[request.status];

						return (
							<div key={request.id}>
								<ListItem
									button
									onClick={e => showDetails(e)(request.id, request.name, request.request_type)}
									alignItems="center"
									divider
								>
									<ListItemText className={classes.dateRequired}>
										<Typography variant="h3" align="center" className={`${classes.marginText} ${classes.block}`}>
											{day}
										</Typography>
										<Typography variant="h6" align="center" className={`${classes.marginText} ${classes.folioFont}`}>
											{`${month} - ${year}`}
										</Typography>
									</ListItemText>
									<ListItemText
										className={classes.pendingDetails}
										primary={request.name}
										secondary={(
											<>
												{ request.imss_request && request.imss_request.worker_name ? (
													<Typography component="span" variant="body1" className={classes.block} color="textPrimary">
														{`Nombre del Empleado: ${request.imss_request.worker_name}`}
													</Typography>
												) : null}
												{request.request_type === "DISABILITIES"
													&& (
														<Typography component="span" variant="body1" className={classes.block} color="textPrimary">
													Tipo de incapacidad:
															{" "}
															{disabilitiesTypes[request.imss_request.disability_type]}
														</Typography>
													)
												}
												<Typography component="span" variant="body2" className={classes.block} color="textPrimary">
													{`Fecha de la solicitud: ${date}`}
												</Typography>
											</>
										)}
									/>
									<ListItemText
										className={classes.dateRequired}
									>
										<Grid
											container
											direction="column"
											justify="flex-start"
											alignItems="center"
										>
											<Grid item>
												<CircularProgressWithLabel
													primaryClass={`${statusMapped.className} progressCircle`}
													secondaryClass={`${statusMapped.className}-back`}
													labelClassName="progressCircleLabel"
													value={statusMapped.progress}
													size={45}
												/>
											</Grid>
											<Grid item>
												<Typography
													variant="h6"
													align="center"
													className={`${classes.marginText}
													${classes.statusFont}`}
												>
													{statusMapped.name}
												</Typography>
											</Grid>
										</Grid>
									</ListItemText>
								</ListItem>
							</div>
						);
					}) : (
						<Typography
							variant="h5"
							align="center"
							className={`${classes.marginText}`}
						>
								No tienes ninguna solicitud
						</Typography>
					)}
				</List>
			</ModuleContainer>

			<Dialog
				open={openSelector}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleRequestSelector}
				aria-labelledby="Crear nueva solicitud"
				aria-describedby="Crear nueva solicitud"
			>
				<RequestSelector match={match} modal />
			</Dialog>
			<Fab
				variant="extended"
				color="primary"
				className={classes.fab}
				aria-label="Nueva Solicitud"
				onClick={handleRequestSelector}
			>
				<AddIcon className="extendedIcon" />
				<span className="fabText">Nueva Solicitud</span>
			</Fab>
		</div>
	);
};


Requests.propTypes = {
	match        : PropTypes.object.isRequired,
	customerId   : PropTypes.number.isRequired,
	modalActions : PropTypes.object.isRequired,
	userType     : PropTypes.string.isRequired,
};

const mapStateToProps = ({ authReducer: { customerData, type } }) => ({
	customerId	: customerData.id,
	userType  	: type,
});

const mapDispatchToProps = dispatch => ({ modalActions : bindActionCreators(ModalActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
