/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle
} from "react";
import { connect }	from "react-redux";
import {
	FormControl,
	MenuItem,
	TextField,
	// Button,
	Grid,
	Dialog,
	Slide
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import swal 			from "sweetalert2";

// import own components
import RequestSelector	from "../../RequestSelector";

// Import userService
import userService		from "../../../../config/services/userService";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CustomerIdentifier = (props, ref) => {
	const {
		customerData,
		loggedIn,
		handleChange,
		handleChangeMulti,
		validForm,
		status,
		requireBranchOffice,
		match,
		mode,
		isDisabled,
		type,
		clearForm
	} = props;
	const [branchOffices, setBranchOffices] = useState([]);
	const [filter, setFilter]				= useState("");
	const [customers, setCustomers]			= useState([]);
	const [open, setOpen]					= useState(false);
	const [openSelector, setOpenSelector]	= useState(false);

	const handleRequestSelector = () => {
		setOpenSelector(!openSelector);
	};

	useImperativeHandle(ref, () => ({
		cleanValues : () => {
			setBranchOffices([]);
			setFilter("");
			setCustomers([]);
		}
	}));

	const selectBranchOffice = (e) => {
		const { value } = e.target;
		handleChange("branchOfficeId")({
			branchOfficeId	: value,
			branchOffice  	: branchOffices.filter(i => i.id === value)[0].name,
		});
	};

	const getCustomerBranchOffices = async (customerId = customerData.id, customerName = null) => {
		if (loggedIn) {
			try {
				const response = await userService.getCustomerBranchOffices(
					{
						customer_id                : customerId,
						with_employer_registration : true
					}
				);
				if (response.data) {
					if (response.data.length === 1) {
						let temp = {
							branchOfficeId	: response.data[0].id,
							branchOffice  	: response.data[0].name,
						};
						if (customerName) {
							temp = {
								...temp,
								customerId,
								customerName
							};
						}
						handleChangeMulti(temp);
					}
					setBranchOffices(response.data);
				}
			} catch (error) {
				swal.fire(`Error ${error}`, "error");
			}
		}
	};

	const setCustomer = ([customerId, customerName]) => {
		if (requireBranchOffice) getCustomerBranchOffices(customerId, customerName);
		else {
			handleChangeMulti({
				customerId,
				customerName
			});
		}
	};

	useEffect(() => {
		if (loggedIn && type === "customer") {
			if (requireBranchOffice && mode !== "VIEW") getCustomerBranchOffices(customerData.id, customerData.name);
			else {
				handleChangeMulti({
					customerId   : customerData.id,
					customerName : customerData.name
				});
			}
		}
	}, []);

	const getCustomers = async () => {
		const response = await userService.getCustomers({
			page                      	: 1,
			per_page                  	: 20,
			type                      	: "activo",
			with_employer_registration : true,
			filter
		});
		if (response.status === 200) {
			setCustomers(response.data);
		} else {
			swal.fire("!Error!", "Error al cargar los clientes", "error");
		}
	};

	useEffect(() => {
		getCustomers();
	}, [filter]);

	return (
		<>
			<Grid
				container
				spacing={3}
				justify="center"
				alignItems="center"
			>
				<Grid item md={4} xs={11}>
					{type === "customer" || mode === "VIEW" || !loggedIn ? (
						<TextField
							required
							error={!validForm.customerName && !validForm.customerId}
							className="formControl"
							id="customer-name"
							label="Nombre del Cliente o RFC"
							value={mode !== "VIEW" && customerData && type === "customer" ? customerData.name
								: status.formData.customerName ? status.formData.customerName : ""}
							InputProps={{
								readOnly : loggedIn,
							}}
							onChange={handleChange("customerName")}
							variant="outlined"
						/>
					) : (
						<Autocomplete
							open={open}
							onOpen={() => {
								setOpen(true);
							}}
							onClose={() => {
								setOpen(false);
							}}
							className="formControl"
							onChange={(e, newValue, reason) => {
								if (reason === "clear") { clearForm(); return; }
								setCustomer(newValue && newValue.id && newValue.name
									? [newValue.id, newValue.name] : [null, null]);
							}}
							getOptionSelected={(option, value) => option.name === value.name}
							getOptionLabel={option => option.name}
							options={customers}
							inputValue={filter}
							onInputChange={(event, newInputValue, reason) => {
								if (reason === "clear") { clearForm(); return; }
								setFilter(newInputValue);
							}}
							noOptionsText="No hay más clientes"
							renderInput={params => (
								<TextField
									{...params}
									label="Clientes"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete : "new-password",
									}}
								/>
							)}
						/>
					)}
				</Grid>
				{ requireBranchOffice ? (
					<Grid item md={4} xs={11}>
						{ loggedIn && mode !== "VIEW" ? (
							<FormControl className="formControl">
								<TextField
									error={!validForm.branchOfficeId}
									select={!isDisabled()}
									required
									variant="outlined"
									disabled={!branchOffices.length}
									id="branch-office-select"
									label="Sucursal de Empleado"
									value={status.formData.branchOfficeId
										? status.formData.branchOfficeId : ""}
									onChange={selectBranchOffice}
									inputProps={{
										onFocus	: () => { if (type === "customer") { getCustomerBranchOffices(); } }
									}}
								>
									{
										branchOffices.map(b => (
											<MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
										))
									}
								</TextField>
							</FormControl>
						)
							: (
								<TextField
									error={!validForm.branchOffice}
									required
									id="branch-office-text"
									label="Sucursal del Empleado"
									variant="outlined"
									multiline
									className="formControl"
									rowsMax={3}
									value={status.formData.branchOffice
										? status.formData.branchOffice : ""}
									onChange={handleChange("branchOffice")}
									inputProps={{
										readOnly	: isDisabled(),
									}}
								/>
							)}
					</Grid>
				) : (<Grid item md={4} xs={11} />)
				}
				<Grid item md={3} xs={11}>
					{/* {mode !== "VIEW" && false && (
						<Button className="btn-green" variant="outlined" color="primary" onClick={handleRequestSelector}>
						CAMBIAR TIPO DE SOLICITUD
						</Button>
					)} */}
				</Grid>
			</Grid>
			<Dialog
				open={openSelector}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleRequestSelector}
				aria-labelledby="Crear nueva solicitud"
				aria-describedby="Crear nueva solicitud"
			>
				<RequestSelector match={match} modal />
			</Dialog>
		</>
	);
};

const mapStateToProps = ({ authReducer: { customerData, loggedIn, type } }) => ({
	customerData,
	loggedIn,
	type
});

export default connect(mapStateToProps, null, null, { forwardRef : true })(forwardRef(CustomerIdentifier));
