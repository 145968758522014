import {
	OPEN_MODAL,
	CLOSE_MODAL
} from "../constants/actionTypes";

const openModal  = config => ({
	type    : OPEN_MODAL,
	payload : config,
});

const closeModal = () => ({ type : CLOSE_MODAL });

const modalActions = {
	openModal,
	closeModal,
};

export default modalActions;
