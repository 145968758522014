import { makeStyles }	from "@material-ui/core/styles";

export default makeStyles(theme => ({
	MainContainer : {
		display        : "flex",
		flexDirection  : "column",
		justifyContent : "space-between",
		heigth         : "auto",
		minHeight      : "50vh",
		minWidth   	   : "100%"
	},
	inputContainer : {
		padding : "1rem 1.2rem",
	},
	rating1 : {
		width      : 200,
		display    : "flex",
		alignItems : "center",
	},
	svg : {
		margin : "0 0.5rem",
	},
	form : {
		width     : "100%",
		marginTop : "1rem",
	}
}));
