import React from "react";
import PropTypes	from "prop-types";

// import own components
import ModuleContainer	from "../../../components/ModuleContainer";
import EmploymentCredit	from "../../../components/Requests/EmploymentCredit";

const EmploymentCreditPage = ({ match }) => (
	<div>
		<ModuleContainer
			title="Tramite IMSS > Crédito de Empleado"
			color="#f3f0f0"
			background="#13322b"
			icon="fas fa-money-bill-alt"
			md={11}
			lg={11}
		>
			<EmploymentCredit
				match={match}
			/>
		</ModuleContainer>
	</div>
);

EmploymentCreditPage.propTypes = {
	match	: PropTypes.object.isRequired,
};

export default EmploymentCreditPage;
