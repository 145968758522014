export const initState = {
	submitted : false,
	formData  : {
		customerId     : null,
		branchOfficeId : null,
		creditFile     : null,
		creditType     : "",
	},
};

export const statusLabel = {
	customerName   : "Cliente",
	customerId     : "Cliente",
	branchOffice   : "Sucursal",
	branchOfficeId : "Sucursal",
	workerName     : "Nombre Empleado",
	workerLastName : "Apellido Empleado",
	creditFile     : "Documento",
	creditType     : "Tipo de Incapacidad",
	details        : "Detalles u Observaciones",
};

export const creditTypes = {
	CREDIT_RECORD     : "Alta de crédito",
	CREDIT_WITHDRAWAL : "Baja de crédito",
	CREDIT_UPDATE     : "Modificación de crédito",
};

export const validFormInit = {
	customerName   : true,
	customerId     : true,
	branchOffice   : true,
	branchOfficeId : true,
	workerName     : true,
	workerLastName : true,
	creditType     : true,
};
