import React          from "react";
import { makeStyles } from "@material-ui/core";

// Import Own Components
import { HeroCard, GeneralDataCard } from "../../components/Profile";
import "./Profile.scss";

const useStyles = makeStyles(theme => ({
	header : {
		height        : "13rem",
		"& svg, & h1" : {
			color : theme.palette.common.white,
		},
	},
	cards : {
		position                       : "absolute",
		top                            : theme.spacing(11),
		width                          : "100%",
		[theme.breakpoints.down("sm")] : { maxWidth : "90%" },
		[theme.breakpoints.up("md")]   : { maxWidth : "85%" },
		[theme.breakpoints.up("lg")]   : { maxWidth : "60%" },
		transition                     : theme.transitions.create("all", {
			duration : theme.transitions.duration.short,
		}),
	},
}));

const Profile = (props) => {
	const classes = useStyles();

	return (
		<div className="Profile">
			<div className={`${classes.header} header`}>
				<h1>
					<i className="fas fa-user" />
					Perfil
				</h1>
			</div>

			<div className={`${classes.cards} Cards`}>
				<HeroCard />
				<GeneralDataCard />
			</div>
		</div>
	);
};

export default Profile;
