import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
	configuredHeight : {
		height : `${theme.spacing(8)}px !important`,
	},
	whiteColor : {
		color : theme.palette.common.white,
	},
	upperColorBg : {
		backgroundColor : theme.palette.secondary.main,
	},
	lowerColorBg : {
		backgroundColor : theme.palette.primary.main,
	},
	grayColor : {
		color : "#f7f3f3",
	},
	button : {
		transform  : "scale(1.4)",
		margin     : "auto 2%",
		fontWeight : 700,
	},
	iconButton : {
		width  : "50px",
		height : "50px",
	},
	fontLink : {
		fontSize : "calc(0.4vw + 1.2vh)",
	},
	titleModal : {
		fontSize : "calc(1.5vw + 1.5vh)",
	},
}));
