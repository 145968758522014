import { format }   from "date-fns";

export const withdrawalCauses = {
	END_CONTRACT            : "Termino de Contrato",
	VOLUNTARY_SEPARATION    : "Separación Voluntaria",
	JOB_ABANDONMENT         : "Abandono de Empleo",
	DEATH                   : "Defunción",
	CLOSURE                 : "Clausura",
	ABSENTEEISM             : "Ausentismo",
	TERMINATION_OF_CONTRACT : "Rescisión de Contrato",
	RETIREMENT              : "Jubilación",
	PENSION                 : "Pensión",
	OTHER                   : "Otros",
};

export const statusLabel = {
	customerName         : "Cliente",
	customerId           : "Cliente",
	branchOffice         : "Sucursal",
	branchOfficeId       : "Sucursal",
	workerName           : "Nombre Trabajador",
	workerLastName       : "Apellido Empleado",
	workerRFC            : "RFC Trabajador",
	withdrawalCause      : "Motivo de Baja",
	withdrawalOtherCause : "Descripción de la baja",
	details              : "Detalles u Observaciones",
	movementDate         : "Fecha del Movimiento"
};

export const initState = {
	submitted : false,
	formData  : {
		workerRFC      : "",
		movementDate   : format(new Date(), "yyyy-MM-dd HH:mm"),
		customerId     : null,
		branchOfficeId : null,
	},
};

export const validFormInit = {
	customerName         : true,
	customerId           : true,
	branchOffice         : true,
	branchOfficeId       : true,
	workerName           : true,
	workerLastName       : true,
	workerRFC            : true,
	withdrawalCause      : true,
	withdrawalOtherCause : true,
};
