export const initState = {
	submitted : false,
	formData  : {
		customerId     : null,
		branchOfficeId : null,
		retentionFile  : [],
		riskFile       : [],
		disabilityType : "",
	},
};

export const statusLabel = {
	customerName   : "Cliente",
	customerId     : "Cliente",
	branchOffice   : "Sucursal",
	branchOfficeId : "Sucursal",
	workerName     : "Nombre Trabajador",
	workerLastName : "Apellido Empleado",
	retentionFile  : "Incapacidad",
	riskFile       : "Aviso de probable riesgo de trabajo",
	disabilityType : "Tipo de Incapacidad",
	details        : "Detalles u Observaciones",
};

export const disabilitiesTypes = {
	GENERAL_ILLNESS   : "Enfermedad general",
	MATERNITY         : "Maternidad",
	OCCUPATIONAL_RISK : "Riesgo de trabajo",
};

export const validFormInit = {
	customerName   : true,
	customerId     : true,
	branchOffice   : true,
	branchOfficeId : true,
	workerName     : true,
	workerLastName : true,
	retentionFile  : true,
	riskFile       : true,
	disabilityType : true,
};
