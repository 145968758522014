import { getStoreData as StoreData } from "../store";

const authHeader = () => {
	const { authReducer } = StoreData();

	return authReducer.token
		? { Authorization : `Bearer ${authReducer.token}` }
		: {};
};

export default authHeader;
