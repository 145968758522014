import { makeStyles }	from "@material-ui/core/styles";

export default makeStyles(theme => ({
	root : {
		width           : "100%",
		backgroundColor : theme.palette.background.paper,
	},
	marginText : {
		margin : "auto 0px",
	},
	folioFont : {
		fontSize : "1rem",
	},
	margin : {
		margin : theme.spacing(1),
	},
	formControl : {
		minWidth : "100%"
	},
	statusFont : {
		fontSize : "0.785rem",
	},
	drawerTitle : {
		padding : "1rem",
	},
	dateRequired : {
		width       : "7rem",
		maxWidth    : "7rem",
		minWidth    : "7rem",
		marginRight : "0.5rem",
	},
	pendingDetails : {
		borderLeft  : "1px solid #d3d3d3",
		paddingLeft : "0.5rem",
	},
	block : {
		display : "block",
	},
	topHeader : {
		height          : "0.5rem",
		width           : "100%",
		backgroundColor : "#6179dd",
	},
	fab : {
		position : "fixed",
		bottom   : theme.spacing(10),
		right    : theme.spacing(4),
		zIndex   : "1000",
	},
	filterCancel : {
		position : "absolute",
		width    : "0.9rem",
		bottom   : "5px",
		right    : "13px",
		color    : "red"
	}
}));
