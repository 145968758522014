/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, {
	useState,
	forwardRef,
	useImperativeHandle
} from "react";
import {
	TextField,
	Grid,
	Typography
} from "@material-ui/core";
import PropTypes    from "prop-types";
import NumberFormat from "react-number-format";

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
	<NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={(values) => {
			onChange({
				target : {
					value : values.value,
				},
			});
		}}
		defaultValue={0.00}
		allowNegative={false}
		decimalScale={2}
		thousandSeparator
		fixedDecimalScale
		prefix="$"
	/>
);

NumberFormatCustom.propTypes = {
	inputRef	: PropTypes.func.isRequired,
	onChange	: PropTypes.func.isRequired
};

const WorkerSalaries = (props, ref) => {
	const {
		handleChange,
		status,
		validForm,
		isDisabled,
	} = props;
	const [disabled, setDisabled] = useState([false, false, false]);
	const [values, setValues] = useState(["", "", ""]);

	useImperativeHandle(ref, () => ({
		cleanValue : () => setValues(["", "", ""])
	}));

	const handleSalaryChange = idx => (e) => {
		if (disabled[idx]) return;
		let { value } = e.target;
		value = parseFloat(value);
		let _values;
		if (value) {
			setDisabled(disabled.map((_, _idx) => (_idx !== idx)));
			switch (idx) {
				case 0:
					_values = [value, value / 2, value / 4];
					setValues(_values);
					break;
				case 1:
					_values = [value * 2, value, value / 2];
					setValues(_values);
					break;
				case 2:
					_values = [value * 4, value * 2, value];
					setValues(_values);
					break;
				default:
					// eslint-disable-next-line no-console
					console.error("Not a valid index");
					return;
			}
		} else {
			setDisabled(disabled.map(() => false));
			_values = ["", "", ""];
			setValues(_values);
		}
		const salaries = {
			monthlySalary 	: _values[0],
			biweeklySalary	: _values[1],
			weeklySalary  	: _values[2],
		};
		handleChange("salaries")(salaries);
	};


	return (
		<>
			<Grid item container>
				<Typography component="h3" style={{ padding : "1rem 0 1rem 0" }}>
					SALARIOS PARA TRABAJADOR (Cifras estimadas, falta agregar al sueldo indicado la integración de ley)
				</Typography>
				<Grid item container spacing={2}>
					<Grid item md xs={12}>
						<TextField
							required
							value={status.formData.monthlySalary
								? status.formData.monthlySalary : values[0]}
							error={!validForm.salaries}
							className="formControl"
							label="Nuevo Salario por Mes"
							variant="outlined"
							onChange={handleSalaryChange(0)}
							disabled={disabled[0]}
							InputProps={{
								inputComponent : NumberFormatCustom,
								readOnly       : isDisabled(),
							}}
						/>
					</Grid>
					<Grid item md xs={12}>
						<TextField
							required
							value={status.formData.biweeklySalary
								? status.formData.biweeklySalary : values[1]}
							error={!validForm.salaries}
							className="formControl"
							label="Nuevo Salario por Quincena"
							variant="outlined"
							onChange={handleSalaryChange(1)}
							disabled={disabled[1]}
							InputProps={{
								inputComponent : NumberFormatCustom,
								readOnly       : isDisabled(),
							}}
						/>
					</Grid>
					<Grid item md xs={12}>
						<TextField
							required
							value={status.formData.weeklySalary
								? status.formData.weeklySalary : values[2]}
							error={!validForm.salaries}
							className="formControl"
							label="Nuevo Salario por Semana"
							variant="outlined"
							onChange={handleSalaryChange(2)}
							disabled={disabled[2]}
							InputProps={{
								inputComponent : NumberFormatCustom,
								readOnly       : isDisabled(),
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default forwardRef(WorkerSalaries);
