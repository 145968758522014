import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Grid
} from "@material-ui/core";
import { connect }     from "react-redux";
import { DatePicker }  from "@material-ui/pickers";
import { format }      from "date-fns";
import userService     from "../../../config/services/userService";
import ModuleContainer from "../../../components/ModuleContainer";

import "./Files.scss";
import { filtersInit } from "./const";
import ListFiles from "../../../components/ListFiles/ListFiles";

const Files = ({
	customerId,
	match : {
		params : {
			subCategoryId,
			subCategoryName,
		},
	}
}) => {
	const [files, setFile] = useState();
	const [filters, setFilters]	= useState(filtersInit);

	const handleChangeFilter = name => (d) => {
		const value = d && d.target ? d.target.value : d;
		setFilters({
			...filters,
			[name] : value,
		});
	};

	useEffect(() => {
		(async () => {
			try {
				const filesResponseData = (await userService.getFilesCustomer(customerId, subCategoryId, "date_document", "DESC", {
					...filters
				})).data;
				setFile(filesResponseData);
			} catch (err) {
				// console.log(err);
			}
		})();
	}, [customerId, subCategoryId, filters]);

	return (
		<div className="Files">
			<ModuleContainer
				title={subCategoryName}
				color="#f3f0f0"
				background="#9b25ab"
				icon="far fa-folder-open"
			>
				<div className="topHeader" />
				<Grid container direction="row" justify="flex-start" alignItems="center">
					<Grid container item xs={12} md={6} spacing={1} alignItems="flex-end">
						<Grid item xs={1}>
							<i className="far fa-calendar-alt" />
						</Grid>
						<Grid item xs={11}>
							<DatePicker
								autoOk
								fullWidth
								format="dd-MMMM-yyyy"
								cancelLabel="CANCELAR"
								showTodayButton
								todayLabel="HOY"
								color="primary"
								orientation="portrait"
								openTo="date"
								label="Fecha inicial"
								inputVariant="standard"
								variant="dialog"
								value={filters.date_start}
								onChange={date => handleChangeFilter("date_start")(format(date, "yyyy-MM-dd HH:mm"))}
							/>
						</Grid>

					</Grid>
					<Grid container item xs={12} md={6} spacing={1} alignItems="flex-end">
						<Grid item xs={1}>
							<i className="far fa-calendar-alt" />
						</Grid>
						<Grid item xs={11}>
							<DatePicker
								autoOk
								fullWidth
								format="dd-MMMM-yyyy"
								cancelLabel="CANCELAR"
								showTodayButton
								todayLabel="HOY"
								color="primary"
								orientation="portrait"
								openTo="date"
								label="Fecha final"
								inputVariant="standard"
								variant="dialog"
								value={filters.date_end}
								onChange={date => handleChangeFilter("date_end")(format(date, "yyyy-MM-dd HH:mm"))}
							/>
						</Grid>
					</Grid>
					{
						files && files.length ? (
							<ListFiles
								 {...{ files }}
							/>
						) : (
							<Grid item container justify="center" alignItems="center">
								<h1>
									<i className="fal fa-file-search" />
									{" SIN ARCHIVOS"}
								</h1>
							</Grid>
						)
					}
				</Grid>
			</ModuleContainer>
		</div>
	);
};

Files.propTypes = {
	customerId : PropTypes.number.isRequired,
	match      : PropTypes.object.isRequired,
};

const mapStateToProps = state => (
	{
		customerId : state.authReducer.customerData.id
	}
);

export default connect(mapStateToProps, null)(Files);
