import React from "react";
import PropTypes	from "prop-types";

// import own components
import ModuleContainer	from "../../../components/ModuleContainer";
import EmployerRegistration   from "../../../components/Requests/EmployerRegistration";

const EmployerRegistrationPage = ({ match }) => (
	<div>
		<ModuleContainer
			title="Alta de Registro Patronal"
			color="#f3f0f0"
			background="#13322b"
			icon="fas fa-building"
			md={10}
			lg={7}
			xs={11}
		>
			<EmployerRegistration
				match={match}
			/>
		</ModuleContainer>
	</div>
);

EmployerRegistrationPage.propTypes = {
	match	: PropTypes.object.isRequired,
};

export default EmployerRegistrationPage;
