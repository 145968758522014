// loadingReducer
export const SET_IS_LOADING    = "IS_LOADING";

// authReducer
export const SET_USER_DATA     = "SET_USER_DATA";
export const SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA";
export const SET_EMPLOYEE_DATA = "SET_EMPLOYEE_DATA";
export const LOGIN_SUCCESS     = "LOGIN_SUCCESS";
export const CLEAR_USER        = "CLEAR_USER";

// modalReducer
export const OPEN_MODAL        = "OPEN_MODAL";
export const CLOSE_MODAL       = "CLOSE_MODAL";

// drawerReducer
export const OPEN_DRAWER       = "OPEN_DRAWER";
export const CLOSE_DRAWER      = "CLOSE_DRAWER";
