/* eslint-disable no-case-declarations */
import {
	SET_IS_LOADING
} from "../constants/actionTypes";

const loadingReducer = (state = {}, action) => {
	switch (action.type) {
		case SET_IS_LOADING:
			const { value } = action.payload;

			return {
				isLoading : value,
			};
		default:
			return state;
	}
};

export default loadingReducer;
