/* eslint-disable no-confusing-arrow */
import React, {
	useState,
	useEffect
} from "react";
import PropTypes from "prop-types";
import {
	BottomNavigation,
	BottomNavigationAction,
	withStyles
} from "@material-ui/core";

// Import Own Components
import { history, ToggleIcon } from "../../config/helpers";
import "./Footer.scss";

const styles = theme => ({
	footer : {
		boxShadow : theme.shadows[5],
		"& svg"   : {
			width  : `${theme.spacing(3)}px !important`,
			height : `${theme.spacing(2.45)}px !important`,
		},
	},
});

const Footer = ({ classes }) => {
	const routes = ["/perfil", "/solicitudes_imss"];
	const getCurrentRouteValue = pathname => pathname === "/"
		? "/"
		: routes.filter(route => pathname.includes(route))[0];

	const [value, setValue] = useState(
		getCurrentRouteValue(history.location.pathname)
	);

	useEffect(() => {
		const callToUnlisten = history.listen((location, action) => {
			setValue(getCurrentRouteValue(location.pathname));
		});

		return () => {
			callToUnlisten();
		};
	}, []);

	return (
		<div className="Footer">
			<div className="spacer">&nbsp;</div>
			<BottomNavigation
				className={`${classes.footer} BottomNavigation`}
				value={value}
				onChange={(event, newValue) => setValue(newValue)}
			>
				<BottomNavigationAction
					label="Inicio"
					value="/"
					icon={<ToggleIcon main="far fa-compass" secondary="fas fa-compass" useSecondaryWhen={value === "/"} />}
					onClick={() => history.push("/")}
				/>
				<BottomNavigationAction
					label="Solicitudes"
					value="/solicitudes_imss"
					icon={<ToggleIcon main="far fa-inbox-in" secondary="far fa-inbox-in" useSecondaryWhen={value === "/solicitudes_imss"} />}
					onClick={() => history.push("/solicitudes_imss")}
				/>
				<BottomNavigationAction
					label="Perfil"
					value="/perfil"
					icon={<ToggleIcon main="far fa-user-circle" secondary="fas fa-user-circle" useSecondaryWhen={value === "/perfil"} />}
					onClick={() => history.push("/perfil")}
				/>
			</BottomNavigation>
		</div>
	);
};

Footer.propTypes = {
	classes : PropTypes.shape({
		footer : PropTypes.string
	}).isRequired,
};

export default withStyles(styles)(Footer);
