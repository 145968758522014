/* eslint-disable react/prop-types */
import React,
{
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from "react";
import { connect }	from "react-redux";
import {
	Grid,
	TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// import services
import userService from "../../../../config/services/userService";

const EmployeeIdentifier = (props, ref) => {
	const {
		customerId,
		branchOfficeId,
		isDisabled,
		handleChange,
		status,
		validForm,
		branchOfficeRequired,
		setEmployee,
		selectEmployee,
		mode,
		loggedIn
	} = props;

	const [employees, setEmployees] = useState([]);
	const [filter, setFilter]       = useState("");
	const [open, setOpen]           = useState(false);


	useEffect(() => {
		(async () => {
			if (customerId && !branchOfficeRequired) {
				const response = await userService.getCustomerEmployees(customerId, { filter });
				setEmployees(response.data.employees);
			}
		})();
	}, [customerId]);

	useEffect(() => {
		(async () => {
			if (customerId && branchOfficeId && branchOfficeRequired) {
				const response = await userService.getCustomerEmployees(customerId, { branch_office_id : branchOfficeId, filter });
				setEmployees(response.data.employees);
			}
		})();
	}, [branchOfficeId]);

	useImperativeHandle(ref, () => ({
		cleanValues : () => {
			setEmployees([]);
			setFilter("");
		}
	}));

	return (
		<Grid item xs={12} container>
			{ mode !== "NEW" || !selectEmployee || !loggedIn ? (
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							required
							InputProps={{
								readOnly : isDisabled(),
							}}
							value={status.formData.workerName ? status.formData.workerName : ""}
							error={!validForm.workerName}
							className="formControl"
							id="worker-name"
							label="Nombre(s) del trabajador"
							onChange={handleChange("workerName")}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							required
							InputProps={{
								readOnly : isDisabled(),
							}}
							value={status.formData.workerLastName ? status.formData.workerLastName : ""}
							error={!validForm.workerLastName}
							className="formControl"
							id="worker-lastname"
							label="Apellido(s) del trabajador"
							onChange={handleChange("workerLastName")}
							variant="outlined"
						/>
					</Grid>
				</Grid>
			) : (
				<Autocomplete
					open={open}
					onOpen={() => {
						setOpen(true);
					}}
					onClose={() => {
						setOpen(false);
					}}
					className="formControl"
					onChange={(e, newValue) => setEmployee(newValue)}
					getOptionSelected={(option, value) => option.name === value.name}
					getOptionLabel={option => `${option.name} ${option.lastname}`}
					options={employees}
					disabled={!employees.length}
					inputValue={filter}
					onInputChange={(event, newInputValue) => {
						setFilter(newInputValue);
					}}
					noOptionsText="No hay más empleados"
					renderInput={params => (
						<TextField
							{...params}
							label="Selecciona al empleado"
							variant="outlined"
							inputProps={{
								...params.inputProps,
								autoComplete : "new-password",
							}}
						/>
					)}
				/>
			)}
		</Grid>
	);
};

const mapStateToProps = ({ authReducer: { loggedIn } }) => ({
	loggedIn,
});

export default connect(mapStateToProps, null, null, { forwardRef : true })(forwardRef(EmployeeIdentifier));
