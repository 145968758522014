/* eslint-disable camelcase */
/* eslint-disable no-console */
import {
	SET_USER_DATA,
	LOGIN_SUCCESS,
	SET_CUSTOMER_DATA,
	CLEAR_USER,
	SET_EMPLOYEE_DATA
} from "../constants/actionTypes";
import { history } from "../../helpers";
import userService from "../../services/userService";

const updateUserData = (id, newUserData, updateNOW = false) => async (dispatch) => {
	const setData = data => ({ type : SET_USER_DATA, payload : data });

	if (updateNOW) {
		dispatch(setData(newUserData));
	} else {
		try {
			const responseFromService = await userService.updateUserData(id, newUserData);

			if (!responseFromService.ok) {
				throw Error("An exception has occurred: ", await responseFromService.clone().json());
			}

			const { response : userData } = await responseFromService.clone().json();

			dispatch(setData({
				...userData,
				user_id : userData.id,
			}));

			return true;
		} catch (err) {
			console.error(err);
		}
	}

	return false;
};

const getCustomerData = customerId => async (dispatch) => {
	const success = responseData => ({ type : SET_CUSTOMER_DATA, payload : responseData });
	const failure = ({ type : CLEAR_USER });

	try {
		const responseFromService = await userService.getCustomerData(customerId);

		if (!responseFromService.ok) {
			throw Error("An exception has occurred: ", await responseFromService.clone().json());
		}

		const {
			response : {
				customer_info,
			},
		} = await responseFromService.json();

		dispatch(success(customer_info));

		return true;
	} catch (err) {
		dispatch(failure);
		console.error(failure);

		return false;
	}
};

const getEmployeeData = employeeId => async (dispatch) => {
	const success = responseData => ({ type : SET_EMPLOYEE_DATA, payload : responseData });
	const failure = ({ type : CLEAR_USER });

	try {
		const responseFromService = await userService.getEmployeeData(employeeId);

		if (!responseFromService.ok) {
			throw Error("An exception has occurred: ", await responseFromService.clone().json());
		}

		const { response } = await responseFromService.json();

		dispatch(success(response));

		return true;
	} catch (err) {
		dispatch(failure);
		console.error(failure);

		return false;
	}
};

const updateUserSessionData = (type, userData) => async (dispatch) => {
	if (type === "cliente" || type === "customer") {
		// Set customer data
		dispatch(getCustomerData(userData.customer_id));
	} else {
		// Set employee data
		dispatch(getEmployeeData(userData.employee_id));
	}
};

const login = (username, password, type) => async (dispatch) => {
	const success = responseData => ({ type : LOGIN_SUCCESS, payload : responseData });
	const failure = ({ type : CLEAR_USER });

	try {
		const response = await userService.login(username, password, type);

		if (!response.ok) {
			throw Error("An exception has occurred: ", response);
		}

		const {
			id_token    : token,
			user_data   : userData,
		} = await response.json();

		// Set login token
		dispatch(success({ token }));

		// Set user data
		dispatch(updateUserData(null, userData, true));

		updateUserSessionData(type, userData)(dispatch);

		return true;
	} catch (err) {
		dispatch(failure);
		return false;
	}
};

const logout = () => {
	history.push("/");
	return { type : CLEAR_USER };
};

const authActions = {
	updateUserData,
	login,
	getCustomerData,
	logout,
	updateUserSessionData
};

export default authActions;
