/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-console */
import React, {
	useEffect,
	useState
} from "react";
import PropTypes                 from "prop-types";
import { connect }               from "react-redux";
import { bindActionCreators }    from "redux";
import { green, red, lightBlue } from "@material-ui/core/colors";
import swal                      from "sweetalert2";
import {
	IconButton,
	Card,
	Button,
	TextField,
	useTheme,
	LinearProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	makeStyles
} from "@material-ui/core";

// Import Own Components
import UserImage      from "../../UserImage";
import { ToggleIcon } from "../../../config/helpers";
import AuthActions    from "../../../config/store/actions/authActions";
import userService    from "../../../config/services/userService";
import "./HeroCard.scss";

const useStyles = makeStyles(theme => ({
	linearLoading : {
		position : "fixed",
		top      : "0",
		left     : "0",
		width    : "100%",
	},
	inputs : {
		"& input" : {
			color : theme.palette.grey[600],
		},
		"& label" : {
			color : theme.palette.grey[500],
		},
		"& label.Mui-focused" : {
			color : "#153242",
		},
		"& .MuiInput-underline:after" : {
			borderBottomColor : "#153242",
		},
	},
	buttons : {
		color : theme.palette.common.white,
	},
	green : {
		backgroundColor : green[600],
		"&:hover"       : {
			backgroundColor : green[400],
		},
	},
	lightBlue : {
		backgroundColor : lightBlue[600],
		"&:hover"       : {
			backgroundColor : lightBlue[400],
		},
	},
	red : {
		backgroundColor : red[800],
		"&:hover"       : {
			backgroundColor : red[600],
		},
	},
}));

const HeroCard = ({ userData, authActions }) => {
	const classes = useStyles();
	const theme   = useTheme();

	const [editMode, setEditMode]               = useState(false);
	const [waitingResponse, setWaitingResponse] = useState(false);

	// Change Image
	const changeImage = async ({ target : { files } }) => {
		if (!waitingResponse) {
			setWaitingResponse(true);

			const updateUserDataSuccess = await authActions.updateUserData(userData.user_id, {
				image : files[0],
			});

			if (!updateUserDataSuccess) {
				console.error("Error while updating the image");
			}

			setWaitingResponse(false);
		}
	};

	// Change user data
	const [userFormData, setUserFormData] = useState({
		username : userData.username,
		email    : userData.email,
	});

	useEffect(() => {
		if (!waitingResponse) {
			setUserFormData({
				username : userData.username,
				email    : userData.email,
			});
		}
	}, [userData]);

	const handleFormChange = ({ target : { name, value } }) => {
		setUserFormData({
			...userFormData,
			[name] : value,
		});
	};

	const handleClickEdit = async () => {
		if (!editMode) {
			setEditMode(true);
		} else {
			if (!waitingResponse) {
				setWaitingResponse(true);

				const updateUserDataSuccess = await await authActions.updateUserData(userData.user_id, {
					...userFormData,
				});

				if (!updateUserDataSuccess) {
					console.error("Error while updating user data");
				}

				setWaitingResponse(false);
			}

			setEditMode(false);
		}
	};

	// Change Password
	// const [passwordFormSubmitted, setPasswordFormSubmitted] = useState(false);
	const [isPasswordDialogOpen, setIsPasswordDialogOpen]   = useState(false);

	const [passwordForm, setPasswordForm] = useState({
		oldPassword  : "",
		newPassword  : "",
		confirmation : "",
	});

	const handlePasswordFormChange = ({ target : { name, value } }) => {
		setPasswordForm({
			...passwordForm,
			[name] : value,
		});
	};

	const handlePasswordFormSubmit = async (e) => {
		e.preventDefault();

		const { oldPassword, newPassword, confirmation } = passwordForm;

		if ((oldPassword !== newPassword) && (newPassword === confirmation)) {
			// setPasswordFormSubmitted(true);
			setWaitingResponse(true);
			try {
				const response = await userService.changePassword(userData.user_id, oldPassword, newPassword);
				await response.clone().json();
				swal.fire("Correcto", "¡contraseña actualizada!", "success");
			} catch (error) {
				swal.fire("¡Error!", error, "error");
			}

			setWaitingResponse(false);
			setIsPasswordDialogOpen(false);
		}
	};

	return (
		<Card className="HeroCard Card">
			{ waitingResponse && (
				<div className={classes.linearLoading}>
					<LinearProgress color="secondary" />
				</div>
			) }

			<div className="container">
				<div className="AvatarContainer">
					<IconButton>
						<UserImage
							styles={{
								backgroundColor : theme.palette.common.white,
								color           : theme.palette.secondary.main,
								factor          : 2,
							}}
							fallbackIcon="fas fa-user-circle"
						/>
					</IconButton>
				</div>
				<div className="DisplayDataContainer">
					<div className="TextFields">
						<TextField
							label="Nombre de Usuario"
							value={userFormData.username}
							margin="normal"
							fullWidth
							className={classes.inputs}
							InputProps={{
								readOnly : !editMode,
							}}
							name="username"
							onChange={handleFormChange}
						/>
						<TextField
							label="Correo"
							value={userFormData.email}
							margin="dense"
							fullWidth
							className={classes.inputs}
							InputProps={{
								readOnly : !editMode,
							}}
							name="email"
							onChange={handleFormChange}
						/>
					</div>
					<div className="DataControllers">
						{ !editMode && (
							<label htmlFor="change-image">
								<Button
									variant="contained"
									className={`${classes.green} ${classes.buttons}`}
									component="label"
									disabled={Boolean(waitingResponse)}
								>
									<i className="far fa-image" />
									<input accept="image/*" style={{ display : "none" }} id="change-image" type="file" onChange={changeImage} />
								</Button>
							</label>
						) }
						<Button
							variant="contained"
							className={`${editMode ? classes.green : classes.lightBlue} ${classes.buttons}`}
							onClick={handleClickEdit}
							style={{ padding : "0.5%" }}
							disabled={Boolean(editMode && waitingResponse)}
						>
							<ToggleIcon main="fas fa-pencil-alt" secondary="fas fa-check" useSecondaryWhen={editMode} />
						</Button>
						{ !editMode && (
							<Button
								variant="contained"
								className={`${classes.red} ${classes.buttons}`}
								disabled={Boolean(waitingResponse)}
								onClick={() => setIsPasswordDialogOpen(true)}
							>
								<i className="fas fa-lock fa-sm" />
							</Button>
						) }
						{ editMode && (
							<Button
								variant="contained"
								className={`${classes.red} ${classes.buttons}`}
								onClick={() => setEditMode(false)}
							>
								<i className="fas fa-times" />
							</Button>
						) }

						<Dialog open={isPasswordDialogOpen} onClose={() => setIsPasswordDialogOpen(false)} aria-labelledby="password-dialog">
							<DialogTitle id="password-dialog">
								{" Cambiar contraseña "}
							</DialogTitle>
							<DialogContent>
								<TextField
									label="Antigua contraseña*"
									value={passwordForm.oldPassword}
									margin="normal"
									fullWidth
									className={classes.inputs}
									name="oldPassword"
									type="password"
									onChange={handlePasswordFormChange}
								/>
								<TextField
									label="Nueva Contraseña*"
									value={passwordForm.newPassword}
									margin="normal"
									fullWidth
									className={classes.inputs}
									name="newPassword"
									type="password"
									onChange={handlePasswordFormChange}
								/>
								<TextField
									label="Repetir Nueva contraseña*"
									value={passwordForm.confirmation}
									margin="normal"
									fullWidth
									className={classes.inputs}
									name="confirmation"
									type="password"
									onChange={handlePasswordFormChange}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									color="primary"
									type="submit"
									onClick={handlePasswordFormSubmit}
								>
									{"Aceptar"}
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				</div>
			</div>
		</Card>
	);
};

HeroCard.propTypes = {
	userData    : PropTypes.object.isRequired,
	authActions : PropTypes.object.isRequired,
};

const mapStateToProps    = ({ authReducer : { userData } }) => ({ userData });
const mapDispatchToProps = dispatch => ({ authActions : bindActionCreators(AuthActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(HeroCard);
