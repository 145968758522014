/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect }     from "react";
import PropTypes               from "prop-types";
import { connect }             from "react-redux";
import { bindActionCreators }  from "redux";
import ExitToAppIcon           from "@material-ui/icons/ExitToApp";
import {
	SwipeableDrawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Typography,
	withStyles,
	Collapse
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// Import Own Components
import UserImage               from "../UserImage";
import ChangeAccount           from "../ChangeAccount";
import { history, ToggleIcon } from "../../config/helpers";
import DrawerActions           from "../../config/store/actions/drawerActions";
import AuthActions             from "../../config/store/actions/authActions";
import ModalActions            from "../../config/store/actions/modalActions";
import "./AppDrawer.scss";

const styles = theme => ({
	appDrawer : {
		minWidth              : "300px",
		"& [role='document']" : {
			maxWidth : `${theme.spacing(49)}px`,
			width    : "80%",
		},
	},
	largeUserImage : {
		width  : `${theme.spacing(8)}px`,
		height : `${theme.spacing(8)}px`,
	},
	fallbackAvatar : {
		backgroundColor : theme.palette.secondary.main,
	},
	secondaryText : {
		color    : theme.palette.grey[600],
		fontSize : "1.1em",
	},
	icon : {
		width  : `${theme.spacing(3)}px`,
		height : `${theme.spacing(3)}px`,
		color  : theme.palette.grey[600],
	},
	nested : {
		paddingLeft : "10%",
	},
	expand : {
		transform  : "rotate(0deg)",
		transition : theme.transitions.create("transform", {
			duration : theme.transitions.duration.short,
		}),
	},
	expandOpen : {
		transform : "rotate(-180deg)",
	},
});

const AppDrawer = ({
	open,
	customerData,
	userData,
	classes,
	drawerActions,
	authActions,
	modalActions,
	userType,
}) => {
	// Listen history change for icons
	const routes               = ["/documentos", "/finanzas", "/perfil", "/consultas"];
	const getCurrentRouteValue = pathname => pathname === "/"
		? "/"
		: routes.filter(route => pathname.includes(route))[0];

	const [value, setValue] = useState(
		getCurrentRouteValue(history.location.pathname)
	);

	const [openList, setOpenList] = useState(true);

	useEffect(() => {
		const callToUnlisten = history.listen((location, action) => {
			setValue(getCurrentRouteValue(location.pathname));
		});

		return () => {
			callToUnlisten();
		};
	}, []);

	const handleRedirect = (url) => {
		drawerActions.closeDrawer();

		history.push(url);
	};

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	const handleChangeAccount = () => {
		drawerActions.closeDrawer();

		modalActions.openModal({
			title     : "Cambiar cuenta",
			iconTitle : "far fa-sync-alt",
			component : <ChangeAccount />,
		});
	};

	return (
		<SwipeableDrawer
			className={`${classes.appDrawer} AppDrawer`}
			disableBackdropTransition={!iOS}
			disableDiscovery={iOS}
			anchor="right"
			open={open}
			onClose={() => drawerActions.closeDrawer()}
			onOpen={() => drawerActions.openDrawer()}
		>
			<div className="UserProfileCard">
				<UserImage
					styles={{
						factor : 1.6,
					}}
				/>

				<Typography variant="h5" component="h3">
					{customerData.name}
				</Typography>
				<Typography
					variant="subtitle1"
					component="h3"
					className={classes.secondaryText}
				>
					{userData.email}
				</Typography>
			</div>

			<Divider />

			{ userType === "customer"
				? (
					<List>
						{/* INICIO */}
						<ListItem
							button
							onClick={() => handleRedirect("/")}
						>
							<ListItemIcon>
								<ToggleIcon main="far fa-compass" secondary="fas fa-compass" useSecondaryWhen={value === "/"} />
							</ListItemIcon>
							<ListItemText primary="Inicio" />
						</ListItem>

						{/* DOCUMENTOS */}
						<ListItem
							button
							onClick={() => handleRedirect("/documentos")}
						>
							<ListItemIcon>
								<ToggleIcon main="far fa-folder-open" secondary="fas fa-folder-open" useSecondaryWhen={value === "/documentos"} />
							</ListItemIcon>
							<ListItemText primary="Documentos" />
						</ListItem>

						{/* FINANZAS */}
						<ListItem
							button
							onClick={() => handleRedirect("/finanzas")}
						>
							<ListItemIcon>
								<ToggleIcon main="far fa-file-invoice-dollar" secondary="fas fa-file-invoice-dollar" useSecondaryWhen={value === "/finanzas"} />
							</ListItemIcon>
							<ListItemText primary="Finanzas" />
						</ListItem>

						{/* CONSULTAS */}
						<ListItem
							button
							onClick={() => handleRedirect("/consultas")}
						>
							<ListItemIcon>
								<ToggleIcon main="far fa-bell" secondary="fas fa-bell" useSecondaryWhen={value === "/consultas"} />
							</ListItemIcon>
							<ListItemText primary="Consultas" />
						</ListItem>

						{/* SOLICITUDES IMSS */}
						{customerData.employer_registration && customerData.employer_registration.length ? (
							<ListItem
								button
								onClick={() => handleRedirect("/solicitudes_imss")}
							>
								<ListItemIcon>
									<ToggleIcon main="far fa-inbox-in" secondary="far fa-inbox-in" useSecondaryWhen={value === "/solicitudes_imss"} />
								</ListItemIcon>
								<ListItemText primary="Solicitudes" />
							</ListItem>
						) : null}

						{/* PERFIL */}
						<ListItem
							button
							onClick={() => handleRedirect("/perfil")}
						>
							<ListItemIcon>
								<ToggleIcon main="far fa-user-circle" secondary="fas fa-user-circle" useSecondaryWhen={value === "/perfil"} />
							</ListItemIcon>
							<ListItemText primary="Perfil" />
						</ListItem>

						{/* CAMBIAR CUENTA */}
						<ListItem
							button
							onClick={() => handleChangeAccount()}
						>
							<ListItemIcon>
								<i className="fas fa-user-friends" />
							</ListItemIcon>
							<ListItemText primary="Cambiar cuenta" />
						</ListItem>
					</List>
				)
				: (
					<List>
						{/* INICIO */}
						<ListItem
							button
							onClick={() => handleRedirect("/")}
						>
							<ListItemIcon>
								<ToggleIcon main="far fa-compass" secondary="fas fa-compass" useSecondaryWhen={value === "/"} />
							</ListItemIcon>
							<ListItemText primary="Inicio" />
						</ListItem>

						{/* PERFIL */}
						<ListItem
							button
							onClick={() => handleRedirect("/perfil")}
						>
							<ListItemIcon>
								<ToggleIcon main="far fa-user-circle" secondary="fas fa-user-circle" useSecondaryWhen={value === "/perfil"} />
							</ListItemIcon>
							<ListItemText primary="Perfil" />
						</ListItem>

						{/* SOLICITUDES IMSS */}
						<ListItem
							button
							onClick={() => setOpenList(!openList)}
						>
							<ListItemIcon>
								<ToggleIcon main="far fa-inbox-in" secondary="fas fa-inbox-in" useSecondaryWhen={value === "/solicitudes_imss"} />
							</ListItemIcon>
							<ListItemText primary="Nóminas" />
							{openList ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={openList} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<ListItem
									button
									className={classes.nested}
									onClick={() => handleRedirect("/solicitudes_imss")}
								>
									<ListItemIcon>
										<ToggleIcon main="far fa-inbox-in" secondary="fas fa-inbox-in" useSecondaryWhen={value === "/solicitudes_imss"} />
									</ListItemIcon>
									<ListItemText primary="Solicitudes" />
								</ListItem>
							</List>
						</Collapse>

						{/* CONSULTAS */}
						<ListItem
							button
							onClick={() => handleRedirect("/consultas")}
						>
							<ListItemIcon>
								<ToggleIcon main="far fa-bell" secondary="fas fa-bell" useSecondaryWhen={value === "/consultas"} />
							</ListItemIcon>
							<ListItemText primary="Pendientes" />
						</ListItem>
					</List>
				)}

			<Divider />

			{/* SIGN OUT */}
			<List>
				<ListItem
					button
					onClick={() => {
						drawerActions.closeDrawer();
						authActions.logout();
					}}
				>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Cerrar Sesión" />
				</ListItem>
			</List>
		</SwipeableDrawer>
	);
};

AppDrawer.propTypes = {
	open          : PropTypes.bool,
	customerData  : PropTypes.object,
	userData      : PropTypes.object.isRequired,
	classes       : PropTypes.object.isRequired,
	drawerActions : PropTypes.object.isRequired,
	authActions   : PropTypes.object.isRequired,
	modalActions  : PropTypes.object.isRequired,
	userType      : PropTypes.string.isRequired,
};

AppDrawer.defaultProps = {
	open         : false,
	customerData : { name : "" },
};

const mapStateToProps = ({ drawerReducer : { open }, authReducer : { customerData, userData, type } }) => ({
	open,
	customerData,
	userData,
	userType : type
});

const mapDispatchToProps = dispatch => ({
	drawerActions : bindActionCreators(DrawerActions, dispatch),
	authActions   : bindActionCreators(AuthActions, dispatch),
	modalActions  : bindActionCreators(ModalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
	withStyles(styles)(AppDrawer)
);
