/* eslint-disable no-plusplus */
import React,
{ useState, useEffect }	from "react";
import PropTypes	from "prop-types";
import {
	Typography,
	Grid
} from "@material-ui/core";
import withWidth,
{ isWidthUp }		from "@material-ui/core/withWidth";
import { DatePicker }	from "@material-ui/pickers";
import { format, sub, add }	from "date-fns";

const CustomDatePicker = ({
	handleChange,
	movementDate,
	width,
	mode,
	isDisabled,
}) => {
	const [subDate, setSubDate] = useState(4);
	const [addDate, setAddDate] = useState(4);

	const isWeekend = date => date.getDay() === 0 || date.getDay() === 6;
	const isPastMonth = (date, today) => date.getMonth() < today.getMonth();

	const calculatePrePostDays = (today = new Date()) => {
		let pre = 1; let
			days = subDate;
		while (pre <= days) {
			if (isWeekend(sub(today, { days : pre }))) {
				days++;
			}
			pre++;
		}
		setSubDate(days);

		let post = 1;
		days = addDate;
		while (post <= days) {
			if (isWeekend(add(today, { days : post }))) {
				days++;
			}
			post++;
		}
		setAddDate(days);
	};

	useEffect(() => calculatePrePostDays(), []);


	return (
		<>
			<Grid item xs={12}>
				{isWidthUp("md", width) ? (
					<Typography variant="h6" component="h2" style={{ paddingBottom : "1rem", textAlign : "center" }}>
						Fecha del Movimiento
					</Typography>
				) : null}
				<DatePicker
					disabled={isDisabled()}
					className="formControl"
					autoOk
					fullWidth
					format="dd-MMMM-yyyy"
					shouldDisableDate={e => isWeekend(e) || isPastMonth(e, new Date())}
					minDate={sub(new Date(), { days : subDate })}
					// maxDate={add(new Date(), { days : addDate })}
					cancelLabel="CANCELAR"
					showTodayButton
					todayLabel="HOY"
					color="primary"
					allowKeyboardControl={false}
					// leftArrowButtonProps={{
					// 	style : { display : "none" }
					// }}
					// rightArrowButtonProps={{
					// 	style : { display : "none" }
					// }}
					orientation="portrait"
					openTo="date"
					label="Fecha del Movimiento"
					inputVariant="outlined"
					variant={isWidthUp("md", width) ? "static" : "dialog"}
					value={movementDate}
					onChange={date => handleChange("movementDate")(format(date, "yyyy-MM-dd HH:mm"))}
				/>
			</Grid>
		</>
	);
};

CustomDatePicker.propTypes = {
	handleChange : PropTypes.func.isRequired,
	movementDate : PropTypes.string.isRequired,
	width        : PropTypes.string,
	mode         : PropTypes.string,
	isDisabled   : PropTypes.func,
};

CustomDatePicker.defaultProps = {
	width    		: "md",
	mode     		: "",
	isDisabled	: () => {},
};


export default withWidth()(CustomDatePicker);
