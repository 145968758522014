/* eslint-disable no-case-declarations */
import {
	OPEN_MODAL,
	CLOSE_MODAL
} from "../constants/actionTypes";

const modalReducer = (state = {}, action) => {
	switch (action.type) {
		case OPEN_MODAL:
			const { ...config } = action.payload;

			return {
				...config,
				open : true,
			};
		case CLOSE_MODAL:
			return {};
		default:
			return state;
	}
};

export default modalReducer;
