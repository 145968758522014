/* eslint-disable import/prefer-default-export */
export const directoriesProperties = [
	{
		title   	: "ALTA DE EMPLEADOS",
		icon    	: "fas fa-user-hard-hat",
		link    	: "/registrar_trabajador",
		key     	: "register",
		customer	: true,
		open    	: true
	},
	{
		title   	: "BAJA DE EMPLEADOS",
		icon    	: "far fa-user-hard-hat",
		link    	: "/baja_trabajador",
		key     	: "withdrawal",
		customer	: true,
		open    	: true
	},
	{
		title   	: "MODIFICACIÓN DE SALARIOS",
		icon    	: "fas fa-money-check-edit-alt",
		link    	: "/nuevo_salario",
		key     	: "payrolls",
		customer	: true,
		open    	: true
	},
	{
		title   	: "INCAPACIDADES",
		icon    	: "fas fa-wheelchair",
		link    	: "/incapacidades",
		key     	: "inhabilites",
		customer	: true,
		open    	: true
	},
	{
		title   	: "CRÉDITO DE EMPLEADO",
		icon    	: "fas fa-money-bill-alt",
		link    	: "/crédito_nominas",
		key     	: "employment_credit",
		customer	: true,
		open    	: true
	},
	{
		title     	: "ALTA DE REGISTRO PATRONAL",
		icon      	: "fas fa-building",
		link      	: "/alta_registro_patronal",
		key       	: "employer_registration",
		background	: "#1d16a6"
	},
	{
		title     	: "BAJA DE REGISTRO PATRONAL",
		icon      	: "far fa-building",
		link      	: "/baja_registro_patronal",
		key       	: "employer_withdrawal",
		background	: "#1d16a6"
	},
];
