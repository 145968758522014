import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
	palette : {
		primary : {
			light        : "#ffbf4e",
			main         : "#f38e16",
			dark         : "#ba6000",
			contrastText : "#fff"
		},
		secondary : {
			light        : "#c7d4ed",
			main         : "#96a3bb",
			dark         : "#68748b",
			contrastText : "#000000"
		},
		error : {
			light        : "#ff4081",
			main         : "#f50057",
			dark         : "#c51162",
			contrastText : "#fff"
		},
		primaryShades : {
			50  : "#fef3e1",
			100 : "#fce1b4",
			200 : "#facd84",
			300 : "#f9b954",
			400 : "#f8a931",
			500 : "#f79b19",
			600 : "#f39016",
			700 : "#ed8013",
			800 : "#e77110",
			900 : "#dd590d",
		},
		background : {
			paper   : "#fff",
			default : "#fafafa"
		}
	},
	typography : {
		useNextVariants : true,
		fontFamily      : "'Google Sans Regular', 'Montserrat', sans-serif",
		title           : {
			fontWeight : 800
		},
		subtitle1 : {
			fontWeight : 300,
		},
		subtitle2 : {
			fontWeight : 200,
			fontStyle  : "italic"
		},
		caption : {
			fontWeight : 400,
			color      : "#9e6e8d",
			fontSize   : 20
		}
	}
});

export default theme;
