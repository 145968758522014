/* eslint-disable no-continue */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import React, {
	useState,
	useEffect,
	useRef
} from "react";
import PropTypes  from "prop-types";
import { connect } from "react-redux";
import {
	FormControl,
	TextField,
	Button,
	Grid,
	Divider,
	Typography,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Box,
	LinearProgress
} from "@material-ui/core";
import swal					from "sweetalert2";

// Import userService
import userService			from "../../../config/services/userService";
// import own components
import FormButtons			from "../Components/FormButtons";
import CustomerIdentifier	from "../Components/CustomerIdentifier";
import EmployeeIdentifier   from "../Components/EmployeeIdentifier";
import {
	initState,
	validFormInit,
	statusLabel,
	disabilitiesTypes
}	from "./const";
import "./Disabilities.scss";


const Disabilities = ({
	customerData, loggedIn, match, mode, requestData
}) => {
	if (mode === "VIEW" && !requestData.imss_request) {
		return (
			<Box style={{ width : "100%" }}>
				<LinearProgress />
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="caption" component="div" color="textSecondary">
						Cargando datos de la solicitud
					</Typography>
				</Box>
			</Box>

		);
	}

	const [disabilitiesData, setDisabilitiesData]	= useState(initState);
	const [validForm, setValidForm] 				= useState(validFormInit);

	const isDisabled = () => (mode === "VIEW");

	useEffect(() => {
		if (mode === "VIEW" && requestData.imss_request) {
			const request = {
				customerName   	: requestData.imss_request.customer_name,
				workerName     	: requestData.imss_request.worker_name,
				workerLastName 	: requestData.imss_request.worker_lastname,
				disabilityType  : requestData.imss_request.disability_type,
				disabilityFile1	: requestData.imss_request.disability_file_id_1,
				disabilityFile2	: requestData.imss_request.disability_file_id_2,
				details         : requestData.imss_request.details,
			};

			setDisabilitiesData({
				...disabilitiesData,
				formData : {
					...request
				}
			});
		}
	}, [requestData]);

	const downloadFile = (urlFile, name) => {
		fetch(urlFile, {
			method : "get",
		}).then(response => response.blob()).then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", name);
			document.body.appendChild(link);
			link.click();
		});
	};

	const handleValidation = async (name, valid) => {
		await setValidForm({
			...validForm,
			[name] : valid,
		});
	};

	const identifierRef = useRef(null);
	const employeeRef = useRef(null);

	const clearForm = () => {
		identifierRef.current.cleanValues();
		employeeRef.current.cleanValues();
		setDisabilitiesData(initState);
	};

	const handleChange = name => (d) => {
		const value = d && d.target ? d.target.value : d;
		handleValidation(name, true);
		setDisabilitiesData({
			...disabilitiesData,
			formData : {
				...disabilitiesData.formData,
				[name] : value,
			}
		});
	};


	const handleChangeMulti = (obj) => {
		setDisabilitiesData({
			...disabilitiesData,
			formData : {
				...disabilitiesData.formData,
				...obj
			}
		});
	};

	const setEmployee = (data) => {
		setDisabilitiesData({
			...disabilitiesData,
			formData : {
				...disabilitiesData.formData,
				workerName     : data.name,
				workerLastName : data.lastname,
				workerId       : data.id,
			}
		});
	};

	const generateConfirmationHTML = (formData) => {
		let html = "<table style='width: 100%;border-collapse: collapse;'><tbody>";
		const lhtml = "<tr style='border-bottom: 1px solid #e3e3e3;'><td style=\"text-align:left;\">";
		const mhtml = "</td><td style=\"text-align:right;word-wrap:anywhere;\">";
		const rhtml = "</td></tr>";
		for (const key in formData) {
			if (formData.customerName && key === "customerName") {
				html += lhtml + statusLabel.customerName + mhtml + formData.customerName + rhtml;
			} else if (key === "customerId" || key === "workerId") {
				continue;
			} else if (key === "retentionFile") {
				if (formData[key][0]) { html += lhtml + statusLabel[key] + mhtml + formData[key][0].name + rhtml; }
			} else if (key === "riskFile") {
				if (formData[key][0]) { html += lhtml + statusLabel[key] + mhtml + formData[key][0].name + rhtml; }
			} else if (key === "disabilityType") {
				html += lhtml + statusLabel.disabilityType + mhtml + disabilitiesTypes[formData[key]] + rhtml;
			} else {
				html += lhtml + statusLabel[key] + mhtml + formData[key] + rhtml;
			}
		}

		return `${html}</tbody></table>`;
	};

	const formValidate = async () => new Promise((resolve) => {
		const validate = {
			validate	: false,
			error  		: "Debes completar el formulario"
		};


		const validFormSync = {};
		Object.assign(validFormSync, validFormInit);
		for (const key in validFormInit) {
			if (key === "retentionFile" && disabilitiesData.formData[key].length <= 0) {
				validFormSync[key] = false;
			} else if (!disabilitiesData.formData[key]) {
				validFormSync[key] = false;
			}
		}
		setValidForm(validFormSync);

		let validRetentionFile = true;
		if (disabilitiesData.formData.retentionFile.length !== 1) {
			validate.error = "Adjuntar el documento de incapacidad";
			validRetentionFile = false;
		}

		if (!validRetentionFile) {
			resolve(validate);
			return;
		}

		if ((disabilitiesData.formData.customerName
			|| disabilitiesData.formData.customerId)
			&& disabilitiesData.formData.retentionFile.length > 0) {
			validate.validate = true;
			resolve(validate);
		}

		resolve(validate);
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisabilitiesData({
			...disabilitiesData,
			submitted : true,
		});

		try {
			const validateForm = await formValidate();
			if (validateForm.validate) {
				const formData = {};
				for (const key in disabilitiesData.formData) {
					if (disabilitiesData.formData[key]) {
						formData[key] = disabilitiesData.formData[key];
					}
				}

				const files = [...formData.retentionFile];
				if (disabilitiesData.formData.riskFile && disabilitiesData.formData.riskFile.length) {
					files.push(...formData.riskFile);
				}

				swal.fire({
					title             : "Revisar Datos",
					html              : generateConfirmationHTML(formData),
					icon              : "info",
					width             : 700,
					showCancelButton  : true,
					confirmButtonText : "Enviar",
					cancelButtonText  : "Cancelar",
				}).then(async (result) => {
					if (result.isConfirmed) {
						const response = await userService.createRequest({
							request     : JSON.stringify(formData),
							requestType : "DISABILITIES",
						},
						...files);
						if (response.status === 200) {
							swal.fire("!Enviado!", "Tu solicitud ha sido enviada correctamente!", "success");
							clearForm();
						} else {
							swal.fire("!Error!", response.label, "error");
						}
					}
				});
			} else {
				swal.fire("Error", validateForm.error, "warning");
			}
		} catch (error) {
			swal.fire(`Error ${error}`, "error");
		}
	};

	return (
		<div className="Disabilities">
			<Grid container>
				<div className="topHeader" />
				<CustomerIdentifier
					handleChange={handleChange}
					validForm={validForm}
					status={disabilitiesData}
					match={match}
					mode={mode}
					isDisabled={isDisabled}
					handleChangeMulti={handleChangeMulti}
					ref={identifierRef}
					requireBranchOffice
					clearForm={clearForm}
				/>

				{/* Separator */}
				<Grid
					container
					justify="center"
					alignItems="center"
				>
					<Grid item xs={11}>
						<Divider />
					</Grid>
				</Grid>
				<Grid container spacing={2} justify="center" alignItems="flex-start">
					<Grid item md={6} container direction="column" spacing={2}>
						<Grid item xs>
							<EmployeeIdentifier
								key={disabilitiesData.formData.customerId}
								handleChange={handleChange}
								validForm={validForm}
								status={disabilitiesData}
								mode={mode}
								isDisabled={isDisabled}
								setEmployee={setEmployee}
								ref={employeeRef}
								branchOfficeRequired
								customerId={disabilitiesData.formData.customerId}
								branchOfficeId={disabilitiesData.formData.branchOfficeId}
								selectEmployee
							/>
						</Grid>
					</Grid>
					<Grid item xs={11} container spacing={2} justify="center">
						<FormControl
							component="fieldset"
							className="formControl"
							error={!validForm.disabilityType}
							required
							disabled={isDisabled()}
						>
							<FormLabel component="legend">INDICAR EL TIPO DE INCAPACIDAD</FormLabel>
							<RadioGroup
								aria-label="INDICAR EL TIPO DE INCAPACIDAD"
								value={disabilitiesData.formData.disabilityType}
								onChange={handleChange("disabilityType")}
								row
							>
								{
									Object.entries(disabilitiesTypes).map(
										(value, idx) => <FormControlLabel key={idx} value={value[0]} control={<Radio />} label={value[1]} />

									)
								}
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item xs={11} container spacing={2} direction="column" justify="center">
						<Grid item xs>
							{
								disabilitiesData.formData.disabilityType && !isDisabled() ? (
									<label htmlFor="retentionFile">
										<input
											style={{ display : "none" }}
											id="retentionFile"
											name="retentionFile"
											type="file"
											// multiple
											// accept="image/*, .pdf"
											onChange={
												e => handleChange("retentionFile")([...e.target.files])
											}
										/>
										<Button
											className={validForm.retentionFile ? "btn-file" : "error btn-file"}
											variant="outlined"
											color="primary"
											component="span"
										>
											<Grid container direction="column" item>
												<Typography component="h3" style={{ padding : "0.5rem 0 1rem 0", textAlign : "center" }}>
													Adjuntar el documento de incapacidad emitido por el Seguro Social, lo más legible posible
													<br />
												(Escanear el documento y subirlo en PDF o Imagen)
												</Typography>
												<span className="clip">
													<i className="far fa-paperclip" />
												</span>
												{
													disabilitiesData.formData.retentionFile.length > 0 ? (
														disabilitiesData.formData.retentionFile.map((file, idx) => (
															<Typography
																key={idx}
																variant="caption"
																component="h5"
																style={{ padding : "0.5rem 0 1rem 0", textAlign : "center", wordWrap : "break-word" }}
															>
																{file.name}
															</Typography>
														))

													) : null
												}

											</Grid>
										</Button>
									</label>
								) : null
							}
							{
								disabilitiesData.formData.disabilityFile1 && isDisabled()
									? (
										<Grid item xs>
											<Button
												className="btn-green"
												variant="outlined"
												color="primary"
												component="span"
												onClick={() => downloadFile(
													`${process.env.REACT_APP_SYSCONTROL_API}/storage/file/${disabilitiesData.formData.disabilityFile1}`,
													"DOC-INCAPACIDAD-1"
												)}
											>
											DESCARGAR DOCUMENTO DE INCAPACIDAD UNO
											</Button>
										</Grid>
									) : null
							}
							{
								disabilitiesData.formData.disabilityFile2 && isDisabled()
									? (
										<Grid item xs>
											<Button
												className="btn-green"
												variant="outlined"
												color="primary"
												component="span"
												onClick={() => downloadFile(
													`${process.env.REACT_APP_SYSCONTROL_API}/storage/file/${disabilitiesData.formData.disabilityFile2}`,
													"DOC-INCAPACIDAD-2"
												)}
											>
											DESCARGAR DOCUMENTO DE INCAPACIDAD DOS
											</Button>
										</Grid>
									) : null
							}
						</Grid>
						<Grid item xs>
							{
								disabilitiesData.formData.disabilityType && !isDisabled() && disabilitiesData.formData.disabilityType === "OCCUPATIONAL_RISK"
									? (
										<label htmlFor="riskFile">
											<input
												style={{ display : "none" }}
												id="riskFile"
												name="riskFile"
												type="file"
												// multiple
												// accept="image/*, .pdf"
												onChange={
													e => handleChange("riskFile")([...e.target.files])
												}
											/>
											<Button
												className={validForm.riskFile ? "btn-file" : "error btn-file"}
												variant="outlined"
												color="primary"
												component="span"
											>
												<Grid container direction="column" item>
													<Typography component="h3" style={{ padding : "0.5rem 0 1rem 0", textAlign : "center" }}>
													[Opcional] Adjuntar el aviso de probable riesgo emitido por el Seguro Social, lo más legible posible
														<br />
													(Escanear el documento y subirlo en PDF o Imagen)
													</Typography>
													<span className="clip">
														<i className="far fa-paperclip" />
													</span>
													{
														disabilitiesData.formData.riskFile.length > 0 ? (
															disabilitiesData.formData.riskFile.map((file, idx) => (
																<Typography
																	key={idx}
																	variant="caption"
																	component="h5"
																	style={{ padding : "0.5rem 0 1rem 0", textAlign : "center", wordWrap : "break-word" }}
																>
																	{file.name}
																</Typography>
															))

														) : null
													}

												</Grid>
											</Button>
										</label>
									) : null
							}
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="details"
								InputProps={{
									readOnly : isDisabled(),
								}}
								value={disabilitiesData.formData.details ? disabilitiesData.formData.details : ""}
								className="formControl"
								onChange={handleChange("details")}
								label="Detalles u Observaciones"
								multiline
								rows={4}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid item md={3} xs={11} container spacing={2} direction="column" justify="flex-end">
						{mode !== "VIEW" && (
							<FormButtons handleSubmit={handleSubmit} match={match} />
						)}
					</Grid>
				</Grid>
				<hr style={{ border : "1rem solid #fff" }} />
			</Grid>
		</div>
	);
};

Disabilities.propTypes = {
	match     			: PropTypes.object.isRequired,
	customerData	: PropTypes.object,
	loggedIn    	: PropTypes.bool,
	mode      			: PropTypes.string,
	requestData		: PropTypes.object
};

Disabilities.defaultProps = {
	customerData	: {},
	loggedIn   		: false,
	mode      			: "NEW",
	requestData		: {}
};

const mapStateToProps = ({ authReducer: { customerData, loggedIn } }) => ({
	customerData,
	loggedIn
});

export default connect(mapStateToProps, null)(Disabilities);
