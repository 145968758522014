import React, { useEffect, useState } from "react";
import PropTypes                 from "prop-types";
import {
	Grid,
	Checkbox,
	FormControlLabel,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button
} from "@material-ui/core";
import { connect }               from "react-redux";
import { DatePicker }            from "@material-ui/pickers";
import { format }                from "date-fns";
import { CheckCircleOutlined, ExpandMore, RadioButtonUnchecked } from "@material-ui/icons";
import ListFiles                 from "../ListFiles/ListFiles";
import { history }               from "../../config/helpers";
import userService               from "../../config/services/userService";

import "./Compliance.scss";

const CompliancePage = ({
	match : {
		params : {
			complianceType,
			complianceId
		},
	}
}) => {
	const [date, setDate] = useState("");
	const [loaded, setLoaded] = useState(false);
	const [items, setItems] = useState([]);

	if (complianceType !== "contador" && complianceType !== "cliente") {
		history.push("/");
	}

	const convertFileToDocument = file => ({
		file,
		id            : file.id,
		file_id       : file.id,
		name          : `${file.path.split("/")[5]}.${file.extension}`,
		date_document : file.path.split("/")[6].replace(/-/g, "/"),
		created_at    : file.path.split("/")[6].replace(/-/g, "/"),
	});

	const changeComplianceType = () => {
		history.push(`/cumplimineto/${complianceType === "cliente" ? "contador" : "cliente"}/${complianceId}`);
	};

	const mapItems = (compliance) => {
		const complianceMonth = (new Date(date)).getMonth() + 1;
		let mapedItems = [
			{
				key     : 1,
				title   : "Se guardarón tus estados de cuenta",
				type    : "contador",
				show    : compliance.customer.use_account_statements,
				checked : compliance.received_account_statements,
				files   : compliance.received_account_statements ? {
					title : "Estados de cuenta",
					files : compliance.account_statements_files.map(file => convertFileToDocument(file))
				} : undefined,
				details : compliance.missing_account_statements && !compliance.received_account_statements ? {
					title   : "No se cargarón los estados de cuenta",
					details : compliance.missing_account_statements
				} : undefined
			},
			{
				key     : 2,
				title   : "Se guardó el papel de trabajo",
				type    : "contador",
				show    : true,
				checked : compliance.tax_work_paper,
				files   : compliance.tax_work_paper ? {
					title : "Papel de trabajo",
					files : [convertFileToDocument(compliance.tax_work_paper_file)]
				} : undefined,
			},
			{
				key     : 3,
				title   : "Se guardó la balanza",
				type    : "contador",
				show    : compliance.customer.tax_regimes.filter(i => i.id === 2 || i.id === 3 || i.id === 5 || i.id === 6).length > 0,
				checked : compliance.customer_balance,
				files   : compliance.customer_balance ? {
					title : "Balanza",
					files : [convertFileToDocument(compliance.customer_balance_file)]
				} : undefined,
			},
			{
				key     : 4,
				title   : "Autorización de pago correspondiente",
				type    : "cliente",
				show    : compliance.taxes_to_pay,
				checked : compliance.customer_authorization,
				details : compliance.customer_un_authorization ? {
					title   : "Detalles del por que no se realizó el pago",
					details : compliance.customer_un_authorization_text
				} : undefined
			},
			{
				key     : 5,
				title   : "Se guardó el acuse de la declaración",
				type    : "contador",
				show    : true,
				checked : compliance.ack_of_statement,
				files   : compliance.ack_of_statement ? {
					title : "Acuse de la declaración",
					files : [convertFileToDocument(compliance.ack_of_statement_file)]
				} : undefined,
			},
			{
				key     : 6,
				title   : "Se guardó formato de pago",
				type    : "cliente",
				show    : true,
				checked : compliance.payment_format,
				files   : compliance.payment_format_file ? {
					title : "Formato de pago",
					files : [convertFileToDocument(compliance.payment_format_file)]
				} : undefined,
			},
			{
				key     : 7,
				title   : "DIOT",
				type    : "contador",
				show    : compliance.customer.tax_regimes.filter(i => i.id === 2 || i.id === 3 || i.id === 4 || i.id === 5 || i.id === 6).length > 0,
				checked : compliance.customer_balance,
				files   : compliance.customer_balance ? {
					title : "DIOT",
					files : [convertFileToDocument(compliance.customer_balance_file)]
				} : undefined,
			},
			{
				key     : 8,
				title   : "Opinión de cumplimiento",
				type    : "contador",
				show    : true,
				checked : compliance.compliance_opinion,
				files   : compliance.compliance_opinion_file ? {
					title : "Opinión de cumplimiento",
					files : [convertFileToDocument(compliance.compliance_opinion_file)]
				} : undefined,
			},
			{
				key     : 9,
				title   : "Buzón tributario",
				type    : "contador",
				show    : true,
				checked : compliance.tax_mailbox,
				files   : compliance.tax_mailbox_file ? {
					title : "Buzón tributario",
					files : [convertFileToDocument(compliance.tax_mailbox_file)]
				} : undefined,
			},
			{
				key     : 18,
				title   : "Pago de IMSS",
				type    : "cliente",
				show    : complianceMonth % 2 === 0 && compliance.customer.has_employees,
				checked : compliance.imss_payment,
				files   : compliance.imss_payment_file ? {
					title : "Buzón tributario",
					files : [convertFileToDocument(compliance.imss_payment_file)]
				} : undefined,
			},
			{
				key     : 19,
				title   : "Pago de Infonavit",
				type    : "cliente",
				show    : complianceMonth % 2 !== 0 && compliance.customer.has_employees,
				checked : compliance.infonavit_payment,
				files   : compliance.infonavit_payment_file ? {
					title : "Buzón tributario",
					files : [convertFileToDocument(compliance.infonavit_payment_file)]
				} : undefined,
			}
		];

		mapedItems = mapedItems.filter(i => i.type === complianceType && i.show);

		setItems(mapedItems);
	};

	const getCompliance = async () => {
		const resp = await userService.getCompliance(complianceId, { date : date instanceof Date ? date.toJSON() : date });
		mapItems(resp.data);
		return resp;
	};

	useEffect(() => {
		(async () => {
			const resp = await getCompliance();
			setDate(new Date(resp.data.created_at));
			setLoaded(true);
		})();
	}, []);

	useEffect(() => {
		if (!loaded) return;
		(async () => {
			await getCompliance();
		})();
	}, [date, complianceType]);

	const handleChangeState = () => (d) => {
		const value = d && d.target ? d.target.value : d;
		setDate(value);
	};


	if (!items.length) return <></>;


	return (
		<Grid className="CompliancePage" direction="row" container justifyContent="center" alignItems="center">
			<Button variant="outlined" color="primary" onClick={changeComplianceType}>
				Cambiar a avance de
				{" "}
				{complianceType === "cliente" ? "contador" : "cliente"}
			</Button>
			<Grid item xs={11}>
				<DatePicker
					views={["year", "month"]}
					autoOk
					fullWidth
					format="MMMM-yyyy"
					cancelLabel="Cancelar"
					showTodayButton
					todayLabel="Hoy"
					color="primary"
					orientation="portrait"
					openTo="month"
					minDate={new Date("2021-01-01")}
					maxDate={new Date()}
					label="Fecha de cumplimiento"
					inputVariant="standard"
					variant="dialog"
					value={date}
					onChange={val => handleChangeState()(format(val, "yyyy-MM-dd HH:mm"))}
				/>
			</Grid>
			{ items.map(item => (
				<Grid item xs={11} id={item.key}>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<FormControlLabel
								onClick={event => event.stopPropagation()}
								onFocus={event => event.stopPropagation()}
								control={(
									<Checkbox
										icon={<RadioButtonUnchecked />}
										checkedIcon={<CheckCircleOutlined />}
										checked={item.checked}
										color="primary"
									/>
								)}
								label={item.title}
							/>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container direction="row">
								{ item.files && item.files.files.length > 0 ? (
									<Grid container item>
										<Grid item xs={12}>
											<h3>{ item.files.title }</h3>
										</Grid>
										<Grid item xs={12}>
											<ListFiles files={item.files.files} />
										</Grid>
									</Grid>
								) : null }
								{
									item.details ? (
										<Grid container item>
											<Grid item xs={12}>
												<h3>{ item.details.title }</h3>
											</Grid>
											<Grid item xs={12}>
												<p>{item.details.details}</p>
											</Grid>
										</Grid>
									) : null
								}
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>
			)) }

		</Grid>
	);
};


CompliancePage.propTypes = {
	match : PropTypes.object.isRequired
};

const mapStateToProps = ({ authReducer : { userData, customerData, type } }) => ({
	customerId : customerData.id,
	userId     : userData.user_id,
	userType   : type,
});

export default connect(mapStateToProps, null)(CompliancePage);
