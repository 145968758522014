/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React,
{ useState, useEffect } from "react";
import PropTypes		from "prop-types";
import { connect }		from "react-redux";
import {
	Stepper,
	Step,
	Button,
	Typography,
	LinearProgress,
	Box,
	FormControlLabel,
	Checkbox,
	TextField,
	Grid,
	StepButton,
	StepConnector
}  from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import swal           from "sweetalert2";

// Import userService
import userService from "../../../config/services/userService";

// Import self components
import UploadButton from "./UploadButton";
import useStyles    from "./styles";
import { stepsMap } from "./const";

const StyledStepConnector = withStyles({
	alternativeLabel : {
		top   : 10,
		left  : "calc(-50% + 16px)",
		right : "calc(50% + 16px)",
	},
	active : {
		"& $line" : {
			borderColor : "#f48a4a",
		},
	},
	completed : {
		"& $line" : {
			borderColor : "#f48a4a",
		},
	},
	line : {
		borderColor    : "#eaeaf0",
		borderTopWidth : 3,
		borderRadius   : 1,
	},
})(StepConnector);

const RequestProcess = ({
	requestId, requestData, employeeId, onClose
}) => {
	const classes = useStyles();

	const [activeStep, setActiveStep] = useState(0);
	const [steps, setSteps]           = useState([]);
	const [state, setState]           = useState([]);
	const [data, setData]             = useState({ imss_rejection : false });
	const [files, setFiles]           = useState([]);
	const [check, setCheck]           = useState(false);
	const [completed, setCompleted]   = useState(new Set());
	const [dutyStep, setDutyStep]     = useState(null);

	const handleStep = step => () => setActiveStep(step);
	const isStepComplete = step => completed.has(step);
	const isStepActive = step => step === dutyStep;
	const isStepDisabled = step => !completed.has(step) && step !== dutyStep;

	useEffect(() => {
		if (requestData && Object.keys(requestData).length) {
			if (requestData.request_type === "DISABILITIES") {
				setSteps(stepsMap.DISABILITIES[requestData.imss_request.disability_type]);
			} else {
				setSteps(stepsMap[requestData.request_type]);
			}
		}
	}, [requestData]);

	useEffect(() => {
		if (steps && steps.length && state.length > 0 && activeStep) {
			if (state[state.length - 1].status === "IMSS_REJECTION") {
				if (steps[activeStep].decision) {
					const newData = {
						...data,
						[steps[activeStep].decision.model] : true,
					};
					if (steps[activeStep].decision.details) {
						newData[steps[activeStep].decision.details.model] = requestData.rejection_details;
					}
					setFiles([state[state.length - 1].validation_file]);
					setData(newData);
				}
				setActiveStep(state.length - 2);
				setDutyStep(state.length - 2);
			}
		}
	}, [dutyStep]);

	const getIMSSRequestLogs = async () => {
		const logs = await userService.getIMSSRequestLog(requestId);
		if (logs.status !== 200) {
			swal.fire("¡Error!", "No se han podido cargar los datos de la solicitud", "error");
		}
		setState(logs.data.reverse());

		setActiveStep(logs.data.length - 1);
		setDutyStep(logs.data.length - 1);
		setCompleted(new Set([...Array(logs.data.length - 1).keys()]));
	};

	useEffect(() => {
		getIMSSRequestLogs();
	}, []);

	useEffect(() => {
		setFiles([]);
		if (isStepComplete(activeStep)) {
			if (state[activeStep + 1].validation_file) {
				setFiles([state[activeStep + 1].validation_file]);
			}

			if (steps[activeStep].key === "SAVE_TIP" && requestData.employer_registration) {
				setData({
					nrp        : requestData.employer_registration.nrp,
					delegation : requestData.employer_registration.delegation,
					...data,
				});
			}
		}
	}, [activeStep]);

	const handleNext = () => {
		const newCompleted = new Set(completed);
		newCompleted.add(activeStep);
		setCompleted(newCompleted);
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};

	const isNextButtonDisabled = () => activeStep >= steps.length
            || (steps[activeStep] && steps[activeStep].continueWith === "file" && !files.length)
			|| (steps[activeStep] && steps[activeStep].continueWith === "check" && !check)
			|| isStepComplete(activeStep);

	const postRequestProcess = async (event) => {
		if (!check && steps[activeStep].continueWith === "check") {
			swal.fire("¡Hey!", "Debes completar el paso para continuar", "warning");
		} else if (!files && steps[activeStep].continueWith === "file") {
			swal.fire("¡Hey!", "Debes completar el paso para continuar", "warning");
		}

		const step = steps[activeStep];
		let status = step.key;

		if (status === "IMSS_APPROBATION" && data.imss_rejection) {
			status = "IMSS_REJECTION";
			if (
				step.decision
					&& step.decision.details
					&& !data[step.decision.details.model]
			) {
				swal.fire(
					"¡Hey!",
					"Es necesario agregar el motivo por la que el IMSS rechazó la solicitud",
					"warning"
				);
				return;
			}
		}

		if (steps[activeStep].inputFields) {
			const inputs = steps[activeStep].inputFields;
			for (let i = 0; i < inputs.length; i++) {
				const input = inputs[i];
				if (input.required && !data[input.name]) {
					swal.fire(
						"¡Hey!",
						`Es necesario completar el campo ${input.title}`,
						"warning"
					);
					return;
				}
				if ((input.maxLength || input.minLength)
					&& (data[input.name].length > input.maxLength
					|| data[input.name].length < input.minLength)) {
					swal.fire("¡Hey!", `El campo ${input.title}`, "warning");
					return;
				}
			}
		}

		try {
			const formData = {
				imss_request          : requestId,
				employee_id           : employeeId,
				show_file_to_customer : steps[activeStep].show_file,
				status,
			};
			if (Object.keys(data).length) formData.inputData = JSON.stringify(data);
			const response = await userService.postRequestProcess(
				formData,
				...files
			);
			if (response.status === 200) {
				swal.fire({
					title : "¡Bien!",
					text  :
							status === "IMSS_REJECTION"
								? "Se ha cancelado la solicitud y generado una nueva"
								: "Se ha concluido el paso",
					icon              : "success",
					timer             : status === "IMSS_REJECTION" ? 2500 : 1000,
					showConfirmButton : false,
				});
				setFiles([]);
				setCheck(false);
				setData({});
				handleNext();
				getIMSSRequestLogs();
			} else {
				swal.fire("!Error!", response.label, "error");
			}
		} catch (error) {
			swal.fire(`Error ${error}`, "error");
		}
	};

	const handleCancel = async (event) => {
		onClose();
		swal.fire({
			showCancelButton   : true,
			confirmButtonColor : "#3085d6",
			cancelButtonColor  : "#d33",
			confirmButtonText  : "Si, cancelarla",
			title              : "¿Cancelar?",
			text               : "No se puede revertir la acción",
			icon               : "warning",
			cancelButtonText   : "No hacer nada",
		})
			.then(async (result) => {
				if (result.isConfirmed) {
					const response = await userService.cancelRequest(
						requestId,
						{
							responsible_id : employeeId
						}
					);
					if (response.status === 200) {
						swal.fire({
							title             : "!Cancelado!",
							message           : "Tu solicitud ha sido cancelada correctamente",
							type              : "success",
							icon              : "success",
							timer             : 1500,
							showConfirmButton : false,
						});
					} else {
						swal.fire("!Error!", response.label, "error");
					}
				}
			})
			.catch(swal.noop);
	};

	if (!requestData.imss_request && !state) {
		return (
			<Box style={{ width : "100%" }}>
				<LinearProgress />
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="caption" component="div" color="textSecondary">
						Cargando datos de la solicitud
					</Typography>
				</Box>
			</Box>
		);
	}

	return (
		<>
			<div className={classes.topHeader} />
			<Grid className={classes.root} container direction="column" justify="flex-start" alignItems="stretch">

				<Stepper activeStep={activeStep} alternativeLabel nonLinear connector={<StyledStepConnector />}>
					{steps.map(({ label }, index) => (
						<Step
							key={label}
							completed={isStepComplete(index)}
							disabled={isStepDisabled(index)}
							active={isStepActive(index)}
						>
							<StepButton
								className={index === activeStep ? classes.selectedStep : ""}
								onClick={handleStep(index)}
								completed={isStepComplete(index)}
								disabled={isStepDisabled(index)}
							>
								{label}
							</StepButton>
						</Step>
					))}
				</Stepper>


				{/* <Typography className={classes.instructions}>{steps[activeStep].label}</Typography> */}
				<div className={classes.boxMargin}>
					{
						steps[activeStep] ? steps[activeStep].continueWith === "check" || steps[activeStep].continueWith === "terminate"
							? (
								<FormControlLabel
									disabled={isStepComplete(activeStep)}
									control={(
										<Checkbox
											color="primary"
											style={{ margin : "20px 0 10px 15px", transform : "scale(2)" }}
											checked={check || isStepComplete(activeStep)}
											onChange={() => setCheck(!check)}
										/>
									)}
									label={steps[activeStep].message}
									labelPlacement="end"
								/>
							) : steps[activeStep].continueWith === "file" ? (
								<UploadButton
									download={isStepComplete(activeStep)}
									downloadFileTittle="Descargar comprobante"
									files={files}
									setFiles={setFiles}
									fileMessage={steps[activeStep].fileLoadButtonText ? steps[activeStep].fileLoadButtonText : "Carga aquí el comprobante"}
									title={steps[activeStep].message}
								/>
							) : null
							: null
					}
					{
						steps[activeStep] && steps[activeStep].inputFields
							&& steps[activeStep].inputFields.length > 0 ? (
								<div>
									<Typography variant="h6" component="h6" color="textSecondary">
									Completa los siguientes campos
									</Typography>
									{steps[activeStep].inputFields.map(input => (
										<Grid item xs={12} key={input.name}>
											<Typography component="h3" style={{ padding : "1rem 0 1rem 0" }}>{input.title}</Typography>
											<TextField
												id="details"
												style={{ width : "100%" }}
												InputProps={{
													readOnly : isStepComplete(activeStep),
												}}
												// eslint-disable-next-line react/jsx-no-duplicate-props
												inputProps={{
													maxLength : input.maxLength || 90,
													minLength : input.minLength || 0
												}}
												value={data[input.name] ? data[input.name] : ""}
												className="formControl"
												onChange={({ target:{ value } }) => setData({ ...data, [input.name] : value })}
												label={input.title}
												variant="outlined"
												required={input.required}
												placeholder={input.placeholder}
											/>
										</Grid>
									))}
								</div>
							) : null
					}

					{
						steps[activeStep] && steps[activeStep].decision && steps[activeStep].decision.type === "check" ? (
							<Grid container>
								<Grid item xs={12}>
									<FormControlLabel
										disabled={isStepComplete(activeStep)}
										control={(
											<Checkbox
												color="primary"
												style={{ margin : "20px 0 10px 15px", transform : "scale(2)" }}
												checked={data[steps[activeStep].decision.model]}
												onChange={() => setData({
													...data,
													[steps[activeStep].decision.model]
													: !data[steps[activeStep].decision.model]
												})}
											/>
										)}
										label={steps[activeStep].decision.label}
										labelPlacement="end"
									/>
								</Grid>
								{
									steps[activeStep] && steps[activeStep].decision
									&& steps[activeStep].decision.details && data[steps[activeStep].decision.model]
										? (
											<TextField
												style={{ width : "100%" }}
												InputProps={{
													readOnly : isStepComplete(activeStep),
												}}
												value={data[steps[activeStep].decision.details.model] ? data[steps[activeStep].decision.details.model] : ""}
												className="formControl"
												onChange={({ target:{ value } }) => setData({ ...data, [steps[activeStep].decision.details.model] : value })}
												label={steps[activeStep].decision.details.label}
												multiline
												required={steps[activeStep].decision.required}
												rows={4}
												variant="outlined"
											/>
										) : null
								}
							</Grid>
						) : null
					}


				</div>

				<Grid container item justify="flex-end" direction="row" style={{ position : "relative", bottom : "1rem" }}>
					{ activeStep < steps.length
						? (
							<Button
								className={`${classes.marginButton} ${classes.cancelButton}`}
								variant="outlined"
								onClick={handleCancel}
								disabled={activeStep >= steps.length}
							>
                                Cancelar Solicitud
							</Button>
						) : null
					}
					<Button
						className={classes.marginButton}
						variant="contained"
						color="primary"
						onClick={postRequestProcess}
						disabled={isNextButtonDisabled()}
					>
						{steps[activeStep] && steps[activeStep].continueWith === "file" ? "Siguiente paso"
							: activeStep >= steps.length ? "Proceso finalizado"
								: activeStep === steps.length - 1 ? "Finalizar solicitud" : "Siguiente paso"}
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

RequestProcess.propTypes = {
	requestId  	: PropTypes.number.isRequired,
	employeeId  : PropTypes.number.isRequired,
	onClose     : PropTypes.func.isRequired,
	requestData	: PropTypes.object,
};


RequestProcess.defaultProps = {
	requestData	: {}
};

const mapStateToProps = ({ authReducer: { customerData } }) => ({
	employeeId : customerData.id,
});

export default connect(mapStateToProps, null)(RequestProcess);
