/* eslint-disable max-len */
/* eslint-disable camelcase */
import React,
{ useState, useEffect }	from "react";
import PropTypes			from "prop-types";
import swal					from "sweetalert2";
import {
	AppBar,
	Tabs,
	Tab
}				from "@material-ui/core";
import { useTheme }			from "@material-ui/core/styles";
import SwipeableViews		from "react-swipeable-views";
// Import userService
import userService from "../../../config/services/userService";

// import own components
import RegisterWorker	from "../RegisterWorker";
import WithdrawalWorker	from "../WithdrawalWorker";
import NewSalary		from "../NewSalary";
import Disabilities		from "../Disabilities";
import RequestHistory	from "../RequestHistory";
import RequestProcess	from "../RequestProcess";
import useStyles		from "./styles";
import "./RequestDetails.scss";

function TabPanel({
	children, value, index, ...other
}) {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && (
				children
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children : PropTypes.node.isRequired,
	index    : PropTypes.any.isRequired,
	value    : PropTypes.any.isRequired,
};

const RequestDetails = ({
	onClose,
	requestId,
	requestType,
	userType,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const [state, setState] = useState({});
	const [value, setValue] = React.useState(0);

	useEffect(() => {
		(async () => {
			const data = await userService.getIMSSRequestDetails(requestId);
			if (data.status !== 200) {
				swal.fire("¡Error!", "No se han podido cargar los datos de la solicitud", "error");
			}
			setState(data.data);
		})();
	}, []);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	const a11yProps = index => ({
		id              : `full-width-tab-${index}`,
		"aria-controls" : `full-width-tabpanel-${index}`,
		value           : index,
	});

	const switchRequestView = (type = requestType) => {
		switch (type) {
			case "REGISTER_WORKER":
				return (
					<RegisterWorker
						match={{ url : "" }}
						mode="VIEW"
						requestData={state}
					/>
				);
			case "WITHDRAWAL":
				return (
					<WithdrawalWorker
						match={{ url : "" }}
						mode="VIEW"
						requestData={state}
					/>
				);
			case "NEW_SALARY":
				return (
					<NewSalary
						match={{ url : "" }}
						mode="VIEW"
						requestData={state}
					/>
				);
			case "DISABILITIES":
				return (
					<Disabilities
						match={{ url : "" }}
						mode="VIEW"
						requestData={state}
					/>
				);
			default:
				return null;
		}
	};

	const haveCustomerData = () => requestType === "REGISTER_WORKER"
		|| requestType === "WITHDRAWAL" || requestType === "NEW_SALARY"
		|| requestType === "DISABILITIES";

	return (
		<div className={`${classes.MainContainer} RequestDetails`}>
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					aria-label="Seleccione una sección"
					variant="scrollable"
					scrollButtons="on"
				>
					<Tab label="Resumen" {...a11yProps(0)} />
					{haveCustomerData() && <Tab label="Información de la solicitud" {...a11yProps(1)} />}
					{userType === "employee" && state.status !== "CANCELED" && <Tab label="Avance del proceso" {...a11yProps(2)} />}
				</Tabs>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={value}
				onChangeIndex={handleChangeIndex}
			>
				<TabPanel value={value} index={0} dir={theme.direction}>
					<RequestHistory requestId={requestId} requestData={state} userType={userType} onClose={onClose} />
				</TabPanel>
				{haveCustomerData() ? (
					<TabPanel value={value} index={1} dir={theme.direction}>
						{switchRequestView()}
					</TabPanel>
				) : <></>}
				{userType === "employee" && state.status !== "CANCELED" ? (
					<TabPanel value={value} index={2} dir={theme.direction}>
						<RequestProcess onClose={onClose} requestId={requestId} requestData={state} />
					</TabPanel>
				) : <></>}
			</SwipeableViews>
		</div>
	);
};

RequestDetails.propTypes = {
	requestId  	: PropTypes.number.isRequired,
	onClose    	: PropTypes.func,
	userType    : PropTypes.string.isRequired,
	requestType	: PropTypes.oneOf(["REGISTER_WORKER", "WITHDRAWAL", "NEW_SALARY", "DISABILITIES", "EMPLOYER_REGISTRATION", "EMPLOYER_REGISTRATION_WITHDRAWAL"]),
};

RequestDetails.defaultProps = {
	requestType : "",
	onClose     : () => {}
};

export default RequestDetails;
