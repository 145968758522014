/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
export const stepsMap = {
	REGISTER_WORKER : [
		{
			label        : "Inicio del alta",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado el proceso de alta en el sistema de nóminas de contpaq",
			key       : "PAYROLL_REGISTER",
			show_file : false,
		},
		{
			label        : "Conexión Nóminas/SUA",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado el proceso de conexión nóminas/SUA",
			key       : "PAYROLL_SUA_CONNECTION",
			show_file : false,
		},
		{
			label        : "Conexión SUA/IDSE",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado el proceso de conexión SUA/IDSE",
			key       : "SUA_IDSE_CONNECTION",
			show_file : false,
		},
		{
			label        : "Aprobación o rechazo del IMSS",
			continueWith : "file",
			message      :
					"Da siguiente cuando hayas guardado el comprobante de la aprobación o rechazo del IMSS",
			key       : "IMSS_APPROBATION",
			show_file : false,
			decision  : {
				type  : "check",
				label :
						"Marca la casilla si el IMSS rechazó la solicitud, esto cancelara la solicitud y creara otra con los mismos datos.",
				model   : "imss_rejection",
				details : {
					label : "Escribe la razón por la que el IMSS rechazó la solicitud.",
					model : "imss_rejection_details",
				},
			},
		},
		{
			label        : "Finalizar solicitud",
			continueWith : "terminate",
			message      :
					"Marca la casilla y da finalizar cuando verifiques que los archivos y el proceso sean correctos",
			key       : "REQUEST_FINISHED",
			show_file : true,
		},
	],
	WITHDRAWAL : [
		{
			label        : "Baja en nominpaq",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado la baja en nominpaq",
			key       : "PAYROLL_REGISTER",
			show_file : false,
		},
		{
			label        : "Conexión nominpaq/SUA",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado la conexión nominpaq/SUA",
			key       : "PAYROLL_SUA_CONNECTION",
			show_file : false,
		},
		{
			label        : "Conexión SUA/IDSE",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado la conexión SUA/IDSE",
			key       : "SUA_IDSE_CONNECTION",
			show_file : false,
		},
		{
			label        : "Aprobación o rechazo del IMSS",
			continueWith : "file",
			message      :
					"Da siguiente cuando hayas guardado el comprobante de la aprobación o rechazo del IMSS",
			key       : "IMSS_APPROBATION",
			show_file : false,
			decision  : {
				type  : "check",
				label :
"Marca la casilla si el IMSS rechazó la solicitud, esto cancelara la solicitud y creara otra con los mismos datos.",
				model   : "imss_rejection",
				details : {
					label : "Escribe la razón por la que el IMSS rechazó la solicitud.",
					model : "imss_rejection_details",
				},
			},
		},
		{
			label        : "Finalizar solicitud",
			continueWith : "terminate",
			message      :
"Marca la casilla y da finalizar cuando verifiques que los archivos y el proceso sean correctos",
			key       : "REQUEST_FINISHED",
			show_file : true,
		},
	],
	NEW_SALARY : [
		{
			label        : "Alta en nominpaq",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado la modificación en nominpaq",
			key       : "PAYROLL_REGISTER",
			show_file : false,
		},
		{
			label        : "Conexión nominpaq/SUA",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado la conexión nominpaq/SUA",
			key       : "PAYROLL_SUA_CONNECTION",
			show_file : false,
		},
		{
			label        : "Conexión SUA/IDSE",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado la conexión SUA/IDSE",
			key       : "SUA_IDSE_CONNECTION",
			show_file : false,
		},
		{
			label        : "Aprobación o rechazo del IMSS",
			continueWith : "file",
			message      :
					"Da siguiente cuando hayas guardado el comprobante de la aprobación o rechazo del IMSS",
			key       : "IMSS_APPROBATION",
			show_file : false,
			decision  : {
				type  : "check",
				label :
"Marca la casilla si el IMSS rechazó la solicitud, esto cancelara la solicitud y creara otra con los mismos datos.",
				model   : "imss_rejection",
				details : {
					label : "Escribe la razón por la que el IMSS rechazó la solicitud.",
					model : "imss_rejection_details",
				},
			},
		},
		{
			label        : "Finalizar solicitud",
			continueWith : "terminate",
			message      :
"Marca la casilla y da finalizar cuando verifiques que los archivos y el proceso sean correctos",
			key       : "REQUEST_FINISHED",
			show_file : true,
		},
	],
	EMPLOYMENT_CREDIT : [
		{
			label        : "Alta en nominpaq",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado la modificación en nominpaq",
			key       : "PAYROLL_REGISTER",
			show_file : false,
		},
		{
			label        : "Conexión nominpaq/SUA",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas finalizado la conexión nominpaq/SUA",
			key       : "PAYROLL_SUA_CONNECTION",
			show_file : false,
		},
		{
			label        : "Aviso de retención",
			continueWith : "file",
			message      :
					"Da siguiente cuando hayas guardado el aviso de retención de crédito actual",
			key       : "CREDIT_RETENTION_VOUCHER_FILE",
			show_file : false,

		},
		{
			label        : "Finalizar solicitud",
			continueWith : "terminate",
			message      :
					"Marca la casilla y da finalizar cuando verifiques que los archivos y el proceso sean correctos",
			key       : "REQUEST_FINISHED",
			show_file : true,
		},
	],
	DISABILITIES : {
		GENERAL_ILLNESS : [
			{
				label        : "Alta en nóminas",
				continueWith : "check",
				message      :
						"Marca la casilla y da siguiente cuando hayas procesado la incapacidad en nominpaq",
				key       : "PAYROLL_REGISTER",
				show_file : false,
			},
			{
				label        : "Incapacidad por enfermedad general",
				continueWith : "file",
				message      :
						"Da siguiente cuando hayas guardado el comprobante de incapacidad por enfermedad general",
				key       : "DISABILITY_VOUCHER_FILE",
				show_file : false,
			},
			{
				label        : "Conexión con SUA",
				continueWith : "check",
				message      :
						"Marca la casilla y da siguiente cuando hayas realizado los registros en SUA",
				key       : "PAYROLL_SUA_CONNECTION",
				show_file : false,
			},
			{
				label        : "Terminación de incapacidad",
				continueWith : "check",
				message      :
						"Da siguiente cuando hayas guardado el comprobante de término de incapacidad por enfermedad general",
				key       : "INABILITY_TERMINATION",
				show_file : false,
			},
			{
				label        : "Finalizar solicitud",
				continueWith : "terminate",
				message      :
						"Marca la casilla y da finalizar cuando verifiques que los archivos y el proceso sean correctos",
				key       : "REQUEST_FINISHED",
				show_file : true,
			},
		],
		MATERNITY : [
			{
				label        : "Alta en nóminas",
				continueWith : "check",
				message      :
						"Marca la casilla y da siguiente cuando hayas procesado la incapacidad en nominpaq",
				key       : "PAYROLL_REGISTER",
				show_file : false,
			},
			{
				label        : "Incapacidad por maternidad",
				continueWith : "file",
				message      :
						"Da siguiente cuando hayas guardado el comprobante de incapacidad por maternidad",
				key       : "DISABILITY_VOUCHER_FILE",
				show_file : false,
			},
			{
				label        : "Conexión con SUA",
				continueWith : "check",
				message      :
						"Marca la casilla y da siguiente cuando hayas realizado los registros en SUA",
				key       : "PAYROLL_SUA_CONNECTION",
				show_file : false,
			},
			{
				label        : "Terminación de incapacidad",
				continueWith : "check",
				message      :
						"Da siguiente cuando hayas guardado el comprobante de término de incapacidad por maternidad",
				key       : "INABILITY_TERMINATION",
				show_file : false,
			},
			{
				label        : "Finalizar solicitud",
				continueWith : "terminate",
				message      :
"Marca la casilla y da finalizar cuando verifiques que los archivos y el proceso sean correctos",
				key       : "REQUEST_FINISHED",
				show_file : true,
			},
		],
		OCCUPATIONAL_RISK : [
			{
				label        : "Alta en nóminas",
				continueWith : "check",
				message      :
						"Marca la casilla y da siguiente cuando hayas procesado la incapacidad en nominpaq",
				key       : "PAYROLL_REGISTER",
				show_file : false,
			},
			{
				label        : "Incapacidad por riesgo de trabajo",
				continueWith : "file",
				message      :
						"Da siguiente cuando hayas guardado el comprobante de incapacidad por riesgo de trabajo",
				key       : "DISABILITY_VOUCHER_FILE",
				show_file : false,
			},
			{
				label        : "Conexión con SUA",
				continueWith : "check",
				message      :
						"Marca la casilla y da siguiente cuando hayas realizado los registros en SUA",
				key       : "PAYROLL_SUA_CONNECTION",
				show_file : false,
			},
			{
				label        : "Terminación de incapacidad",
				continueWith : "check",
				message      :
						"Da siguiente cuando hayas guardado el comprobante de término de incapacidad por riesgo de trabajo",
				key       : "INABILITY_TERMINATION",
				show_file : false,
			},
			{
				label        : "Finalizar solicitud",
				continueWith : "terminate",
				message      :
						"Marca la casilla y da finalizar cuando verifiques que los archivos y el proceso sean correctos",
				key       : "REQUEST_FINISHED",
				show_file : true,
			},
		],
	},
	EMPLOYER_REGISTRATION : [
		{
			label        : "Solicitar documentación del cliente",
			continueWith : "check",
			message      : "Marca la casilla y da siguiente cuando hayas recabado toda la documentación del cliente",
			key          : "REQUEST_EMPLOYER_DOCUMENTS",
			show_file    : false,
		},
		{
			label        : "Alta en el SAT",
			continueWith : "check",
			message      :
// eslint-disable-next-line max-len
"Marca la casilla y da siguiente cuando en la cédula del SAT de este cliente aparezca la obligación de retención de salarios",
			key       : "REGISTER_AT_SAT",
			show_file : false,
		},
		// {
		// 	label        : "Guardar acuse de alta en el SAT",
		// 	continueWith : "file",
		// 	message      : "Subir acuse de alta de SAT",
		// 	key          : "SAVE_SAT_ACK",
		// 	show_file    : true,
		// },
		{
			label        : "Alta en el escritorio virtual IMSS",
			continueWith : "check",
			message      :
		"Marca la casilla y da siguiente cuando hayas finalizado el alta en el escritorio virtual del IMSS",
			key       : "REGISTER_AT_IMSS_DESK",
			show_file : false,
		},
		{
			label        : "Tarjeta Patronal",
			continueWith : "file",
			message      :
		"Guarda tarjeta patronal, escribe el número de registro patronal y delegación, después da siguiente",
			key         : "SAVE_TIP",
			show_file   : true,
			inputFields : [
				{
					type        : "text",
					maxLength   : 11,
					minLength   : 11,
					title       : "Número de registro patronal",
					placeholder : "Escribe el número de registro patronal",
					name        : "nrp",
					required    : true,
				},
				{
					type        : "text",
					formType    : "textarea",
					title       : "Delegación y Subdelegación",
					placeholder :
							"Escribe la delegación (y subdelegación en caso de existir) del registro patronal",
					name     : "delegation",
					required : true,
				},
			],
		},
		{
			label        : "Solicitar documentación para el impuesto sobre nómina",
			continueWith : "check",
			message      :
			"Marca la casilla y da siguiente cuando hayas recibido la documentación completa para realizar el alta",
			key       : "REQUEST_PAYROLL_TAX_DOCUMENTS",
			show_file : false,
		},
		// {
		// 	label        : "Alta del impuesto sobre nómina",
		// 	continueWith : "check",
		// 	message      : "Marca la casilla y da continuar cuando hayas finalizado este paso",
		// 	key          : "REGISTER_PAYROLL_TAX",
		// 	show_file    : false,
		// },
		{
			label        : "Guardar hoja de impuesto sobre nómina",
			continueWith : "file",
			message      :
					"Da siguiente cuando hayas guardado el comprobante del alta del impuesto sobre nómina",
			key                : "SAVE_PAYROLL_TAX_ACK",
			fileLoadButtonText : "Cargar aquí hoja de impuesto sobre nómina",
			show_file          : true,
		},
		{
			label        : "Solicitud finalizada",
			continueWith : "terminate",
			message      :
					"Marca la casilla y da finalizar cuando verifiques que los archivos y el proceso sean correctos",
			key       : "REQUEST_FINISHED",
			show_file : false,
		},
	],
	EMPLOYER_REGISTRATION_WITHDRAWAL : [
		// {
		// 	label        : "Baja en el SAT",
		// 	continueWith : "check",
		// 	message      : "Marca la casilla y da continuar cuando hayas finalizado la solicitud del SAT",
		// 	key          : "WITHDRAWAL_AT_SAT",
		// 	show_file    : false,
		// },
		{
			label        : "Baja de obligación en SAT",
			continueWith : "file",
			message      :
"Da siguiente cuando hayas guardado el comprobante del SAT donde conste que el cliente ya no tiene esta obligación",
			key       : "SAVE_SAT_ACK",
			show_file : true,
		},
		{
			label        : "Entregar documentación al cliente para realizar baja",
			continueWith : "check",
			message      :
					"Marca la casilla y da siguiente cuando hayas recabado los documentos para la baja ante el IMSS",
			key       : "WITHDRAWAL_CUSTOMER_DOCS",
			show_file : false,
		},
		{
			label        : "Guardar acuse de baja patronal",
			continueWith : "file",
			message      :
"Da siguiente cuando hayas guardado el comprobante aceptado por el IMSS",
			key       : "SAVE_EMPLOYER_WITHDRAWAL_ACK",
			show_file : true,
		},
		{
			label        : "Baja del impuesto sobre nómina",
			continueWith : "check",
			message      :
"Marca la casilla y da siguiente cuando hayas recabado los documentos para la baja en el impuesto del estado",
			key       : "WITHDRAWAL_PAYROLL_TAX",
			show_file : false,
		},
		{
			label        : "Guardar acuse de baja del impuesto sobre nómina",
			continueWith : "file",
			message      :
					"Da siguiente cuando hayas guardado el comprobante de la baja en el impuesto del estado",
			key       : "SAVE_WITHDRAWAL_PAYROLL_TAX_ACK",
			show_file : true,
		},
		{
			label        : "Solicitud finalizada",
			continueWith : "terminate",
			message      :
					"Marca la casilla y da finalizar cuando verifiques que los archivos y el proceso sean correctos",
			key       : "REQUEST_FINISHED",
			show_file : false,
		},
	],
};
