/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import React, {
	useState,
	useEffect,
	useRef
} from "react";
import { format }	from "date-fns";
import PropTypes	from "prop-types";
import { connect }	from "react-redux";
import {
	TextField,
	Grid,
	Divider,
	Typography,
	Box,
	LinearProgress
} from "@material-ui/core";
import swal				from "sweetalert2";
import esLocale			from "date-fns/locale/es";

// Import userService
import userService		from "../../../config/services/userService";

// import own components
import FormButtons			from "../Components/FormButtons";
import CustomerIdentifier	from "../Components/CustomerIdentifier";
import WorkerSalary			from "../Components/WorkerSalaries";
import EmployeeIdentifier   from "../Components/EmployeeIdentifier";
import CustomDatePicker		from "../Components/CustomDatePicker";
import {
	initState,
	validFormInit,
	statusLabel
}	from "./const";
import "./NewSalary.scss";

const NewSalary = ({
	customerData, loggedIn, match, mode, requestData
}) => {
	if (mode === "VIEW" && !requestData.imss_request) {
		return (
			<Box style={{ width : "100%" }}>
				<LinearProgress />
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="caption" component="div" color="textSecondary">
						Cargando datos de la solicitud
					</Typography>
				</Box>
			</Box>

		);
	}

	const [salaryData, setSalaryData]	= useState(initState);
	const [validForm, setValidForm]		= useState(validFormInit);

	const isDisabled = () => (mode === "VIEW");

	useEffect(() => {
		if (mode === "VIEW" && requestData.imss_request) {
			const request = {
				customerName  	: requestData.imss_request.customer_name,
				workerName    	: requestData.imss_request.worker_name,
				workerLastName	: requestData.imss_request.worker_lastname,
				workerRFC     	: requestData.imss_request.worker_rfc,
				monthlySalary 	: parseFloat(requestData.imss_request.monthly_salary),
				biweeklySalary	: parseFloat(requestData.imss_request.biweekly_salary),
				weeklySalary  	: parseFloat(requestData.imss_request.weekly_salary),
				details       	: requestData.imss_request.details,
				movementDate  	: requestData.movement_date,
			};

			setSalaryData({
				...salaryData,
				formData : {
					...request
				}
			});
		}
	}, [requestData]);

	const handleValidation = async (name, valid) => {
		await setValidForm({
			...validForm,
			[name] : valid,
		});
	};

	const salariesRef = useRef(null);
	const identifierRef = useRef(null);
	const employeeRef = useRef(null);

	const clearForm = () => {
		salariesRef.current.cleanValue();
		identifierRef.current.cleanValues();
		employeeRef.current.cleanValues();
		setSalaryData(initState);
	};

	const handleChange = name => (d) => {
		if (name === "salaries") {
			handleValidation(name, true);
			setSalaryData({
				...salaryData,
				formData : {
					...salaryData.formData,
					...d
				}
			});
			return;
		}
		handleValidation(name, true);
		const value = d && d.target ? d.target.value : d;
		setSalaryData({
			...salaryData,
			formData : {
				...salaryData.formData,
				[name] : value,
			}
		});
	};


	const handleChangeMulti = (obj) => {
		setSalaryData({
			...salaryData,
			formData : {
				...salaryData.formData,
				...obj
			}
		});
	};

	const setEmployee = (data) => {
		if (!data) {
			setSalaryData({
				...salaryData,
				formData : {
					...salaryData.formData,
					workerRFC      : "",
					monthlySalary  : "",
					biweeklySalary : "",
					weeklySalary   : ""
				}
			});
			salariesRef.current.cleanValue();
			return;
		}

		let salaries;
		if (data.periodicity_payment === "mensual") {
			salaries = {
				monthlySalary  : data.salary,
				biweeklySalary : data.salary / 2,
				weeklySalary   : data.salary / 4,
			};
		} else if (data.periodicity_payment === "quincenal") {
			salaries = {
				monthlySalary  : data.salary * 2,
				biweeklySalary : data.salary,
				weeklySalary   : data.salary / 2,
			};
		} else if (data.periodicity_payment === "semanal") {
			salaries = {
				monthlySalary  : data.salary * 4,
				biweeklySalary : data.salary * 2,
				weeklySalary   : data.salary / 2,
			};
		}

		setSalaryData({
			...salaryData,
			formData : {
				...salaryData.formData,
				...salaries,
				workerName     : data.name,
				workerLastName : data.lastname,
				workerId       : data.id,
				workerRFC      : data.rfc,

			}
		});
	};

	const generateConfirmationHTML = (formData) => {
		let html = "<table style='width: 100%;border-collapse: collapse;'><tbody>";
		const lhtml = "<tr style='border-bottom: 1px solid #e3e3e3;'><td style=\"text-align:left;\">";
		const mhtml = "</td><td style=\"text-align:right;word-wrap:anywhere;\">";
		const rhtml = "</td></tr>";
		for (const key in formData) {
			if (formData.customerName && key === "customerName") {
				html += lhtml + statusLabel.customerName + mhtml + formData.customerName + rhtml;
			} else if (key === "movementDate") {
				html += lhtml + statusLabel.movementDate + mhtml
					+ format(new Date(formData[key]), "dd-MMMM-yyyy", { locale : esLocale }) + rhtml;
			} else if (key === "customerId" || key === "workerId") {
				continue;
			} else if (typeof formData[key] === "number") {
				html += lhtml + statusLabel[key] + mhtml
					+ formData[key].toLocaleString("en-US", { style : "currency", currency : "MXN" }) + rhtml;
			} else {
				html += lhtml + statusLabel[key] + mhtml + formData[key] + rhtml;
			}
		}

		return `${html}</tbody></table>`;
	};

	const formValidate = async () => new Promise((resolve) => {
		const validate = {
			validate	: false,
			error  		: "Debes completar el formulario"
		};
		const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

		const validFormSync = {};
		Object.assign(validFormSync, validFormInit);
		for (const key in validFormInit) {
			if (key === "salaries") continue;
			if (!salaryData.formData[key]) {
				validFormSync[key] = false;
			}
		}

		if (!salaryData.formData.monthlySalary
			|| !salaryData.formData.biweeklySalary
			|| !salaryData.formData.weeklySalary) {
			validFormSync.salaries = false;
		}

		setValidForm(validFormSync);

		if ((salaryData.formData.customerName
			|| salaryData.formData.customerId)
			&& salaryData.formData.workerName
			&& salaryData.formData.workerRFC
			&& validFormSync.salaries) {
			if (!salaryData.formData.workerRFC.match(re)) {
				validate.error = "El RFC no es Valido";
				handleValidation("workerRFC", false);
			} else {
				validate.validate = true;
			}
			resolve(validate);
		}

		resolve(validate);
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		setSalaryData({
			...salaryData,
			submitted : true,
		});

		try {
			const validateForm = await formValidate();
			if (validateForm.validate) {
				const formData = {};
				for (const key in salaryData.formData) {
					if (salaryData.formData[key]) {
						formData[key] = salaryData.formData[key];
					}
				}
				swal.fire({
					title             : "Revisar Datos",
					html              : generateConfirmationHTML(formData),
					icon              : "info",
					width             : 700,
					showCancelButton  : true,
					confirmButtonText : "Enviar",
					cancelButtonText  : "Cancelar",
				}).then(async (result) => {
					if (result.isConfirmed) {
						const response = await userService.createRequest({
							request     : JSON.stringify(formData),
							requestType : "NEW_SALARY",
						});
						if (response.status === 200) {
							swal.fire("!Enviado!", "Tu solicitud ha sido enviada correctamente!", "success");
							clearForm();
						} else {
							swal.fire("!Error!", response.label, "error");
						}
					}
				});
			} else {
				swal.fire("Error", validateForm.error, "warning");
			}
		} catch (error) {
			swal.fire(`Error ${error}`, "error");
		}
	};

	return (
		<div className="NewSalary">
			<div className="topHeader" />
			<CustomerIdentifier
				handleChange={handleChange}
				validForm={validForm}
				status={salaryData}
				match={match}
				mode={mode}
				isDisabled={isDisabled}
				handleChangeMulti={handleChangeMulti}
				ref={identifierRef}
				clearForm={clearForm}
				requireBranchOffice
			/>

			{/* Separator */}
			<Grid
				container
				justify="center"
				alignItems="center"
			>
				<Grid item xs={11}>
					<Divider />
				</Grid>
			</Grid>
			<Grid
				container
				spacing={2}
				justify="center"
				alignItems="flex-start"
			>
				<Grid item md={8} xs={11} container spacing={2} justify="center">
					<Grid item xs={11}>
						<Typography variant="h5" component="h2" style={{ paddingBottom : "1rem", textAlign : "center" }}>
							Datos del Trabajador a Modificar el Salario
						</Typography>
					</Grid>
					<Grid item md={6} container direction="column" spacing={2}>
						<Grid item xs>
							<EmployeeIdentifier
								key={salaryData.formData.customerId}
								handleChange={handleChange}
								validForm={validForm}
								status={salaryData}
								mode={mode}
								isDisabled={isDisabled}
								setEmployee={setEmployee}
								ref={employeeRef}
								branchOfficeRequired
								customerId={salaryData.formData.customerId}
								branchOfficeId={salaryData.formData.branchOfficeId}
								selectEmployee
							/>
						</Grid>
					</Grid>
					<Grid item md={6} container direction="column" spacing={2}>
						<Grid item xs>
							<TextField
								required
								InputProps={{
									readOnly : isDisabled(),
									style    : { textTransform : "uppercase" }
								}}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								inputProps={{
									minLength   : 12,
									maxLength   : 13,
									placeholder : "AAAA000000AA0",
									className  	: "validation"
								}}
								value={salaryData.formData.workerRFC ? salaryData.formData.workerRFC : ""}
								error={!validForm.workerRFC}
								className="formControl"
								id="worker-rfc"
								label="RFC del Trabajador"
								onChange={e => handleChange("workerRFC")(e.target.value.toUpperCase())}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<WorkerSalary
						handleChange={handleChange}
						validForm={validForm}
						mode={mode}
						isDisabled={isDisabled}
						status={salaryData}
						ref={salariesRef}
					/>
					<Grid item xs={12}>
						<Typography component="h3" style={{ padding : "1rem 0 1rem 0" }}>EXTRAS</Typography>
						<TextField
							id="details"
							InputProps={{
								readOnly : isDisabled(),
							}}
							value={salaryData.formData.details ? salaryData.formData.details : ""}
							className="formControl"
							onChange={handleChange("details")}
							label="Detalles u Observaciones"
							multiline
							rows={4}
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<Grid item md={3} xs={11} container spacing={2} direction="column" justify="center">
					<CustomDatePicker handleChange={handleChange} movementDate={salaryData.formData.movementDate} mode={mode} isDisabled={isDisabled} />
					{mode !== "VIEW" && (
						<FormButtons handleSubmit={handleSubmit} match={match} />
					)}
				</Grid>
			</Grid>
			<hr style={{ border : "1rem solid #fff" }} />
		</div>
	);
};

NewSalary.propTypes = {
	match     			: PropTypes.object.isRequired,
	customerData	: PropTypes.object,
	loggedIn    	: PropTypes.bool,
	mode      			: PropTypes.string,
	requestData		: PropTypes.object
};

NewSalary.defaultProps = {
	customerData	: {},
	loggedIn   		: false,
	mode      			: "NEW",
	requestData		: {}
};


const mapStateToProps = ({ authReducer: { customerData, loggedIn } }) => ({
	customerData,
	loggedIn
});

export default connect(mapStateToProps, null)(NewSalary);
