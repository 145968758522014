/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable max-len */
import React          from "react";
import PropTypes      from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
	List,
	Divider,
	ListItem,
	Typography,
	ListItemText
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root : {
		width           : "100%",
		backgroundColor : theme.palette.background.paper,
	},
	marginText : {
		margin : "1rem 0",
	},
	folioFont : {
		fontSize : "1rem",
	},
	folioNumber : {
		marginRight : "0.5rem",
		borderRight : "1px solid #d3d3d3",
	},
	block : {
		display : "block",
	}
}));

const NoteDetails = ({
	note,
	type
}) => {
	const classes = useStyles();
	const typeUp  = type.toUpperCase();

	let title = type === "own" ? "Honorarios" : "Obligaciones";
	title = `${title} -${note[type].toLocaleString("en-US", { style : "currency", currency : "USD" })} MNX`;

	return (
		<div className={classes.root}>
			<Typography variant="h5" align="center" className={classes.marginText}>
				{title}
			</Typography>
			<Divider />
			<List component="nav" className={classes.root} aria-label="mailbox folders">
				{note.data.map((noteDetail) => {
					if (noteDetail.concept_type === typeUp && noteDetail.status === "PENDING") {
						return (
							<div key={noteDetail.id}>
								<ListItem alignItems="center" justifyContent="center">
									<ListItemText className={classes.folioNumber}>
										<Typography variant="h6" align="center" className={`${classes.marginText} ${classes.folioFont}`}>
											{"Folio"}
										</Typography>
										<Typography variant="h6" align="center" className={`${classes.marginText} ${classes.folioFont}`}>
											{noteDetail.note_id}
										</Typography>
									</ListItemText>
									<ListItemText
										primary={noteDetail.concept.name}
										secondary={(
											<>
												<Typography
													component="span"
													variant="body2"
													className={classes.block}
													color="textPrimary"
												>
													{ `Total: ${parseFloat(noteDetail.total).toLocaleString("en-US", { style : "currency", currency : "USD" })} MNX` }
												</Typography>
												<Typography
													component="span"
													variant="body2"
													className={classes.block}
													color="textPrimary"
												>
													{ `Abonado: ${parseFloat(noteDetail.deposited).toLocaleString("en-US", { style : "currency", currency : "USD" })} MNX` }
												</Typography>
												<Typography
													component="span"
													variant="body2"
													className={classes.block}
													color="error"
												>
													{`Saldo: ${(parseFloat(noteDetail.total) - parseFloat(noteDetail.deposited)).toLocaleString("en-US", { style : "currency", currency : "USD" })} MNX` }
												</Typography>
											</>
										)}
									/>
								</ListItem>
								<Divider />
							</div>
						);
					}
					return <></>;
				})}
			</List>
		</div>
	);
};

NoteDetails.propTypes = {
	note : PropTypes.object.isRequired,
	type : PropTypes.string.isRequired,
};

NoteDetails.defaultProps = {
};

export default NoteDetails;
