
import React        from "react";
import PropTypes    from "prop-types";
import {
	Button,
	Typography,
	Grid
}  from "@material-ui/core";

// Import styles
import { UploadButtonStyles } from "./styles";
import ListFiles              from "../../ListFiles/ListFiles";

const UploadButton = ({
	files, setFiles, fileMessage, title, download
}) => {
	const classes = UploadButtonStyles();

	return (
		<>
			<Typography variant="h6" component="h6" color="textSecondary" style={{ padding : "0.5rem 0 1rem 0", textAlign : "center" }}>
				{title}
			</Typography>
			{ !download ? (
				<label htmlFor="uploadButton">
					<input
						style={{ display : "none" }}
						id="uploadButton"
						name="uploadFile"
						type="file"
						onChange={
							e => setFiles([...e.target.files])
						}
					/>
					<Button
						className={classes.btnFile}
						variant="outlined"
						color="primary"
						component="span"
					>
						<Grid container direction="column" item>
							<Typography component="h3" style={{ padding : "0.5rem 0 1rem 0", textAlign : "center" }}>
								{fileMessage}
							</Typography>
							<span className={classes.clip}>
								<i className="far fa-paperclip" />
							</span>
							{
								files.map((file, idx) => (
									<Typography
										key={file.id}
										variant="caption"
										component="h5"
										style={{ padding : "0.5rem 0 1rem 0", textAlign : "center", wordWrap : "break-word" }}
									>
										{file.name}
									</Typography>
								))
							}

						</Grid>
					</Button>
				</label>
			) : (
				<Grid container direction="row" justify="center" alignItems="center">
					{
						files && files.length ? (
							<Grid item md={8}>
								<ListFiles
									{...{ files }}
								/>

							</Grid>
						) : (
							<Grid item md={8}>
								<h1>
									<i className="fal fa-file-search" />
									{" SIN ARCHIVOS"}
								</h1>
							</Grid>
						)
					}
				</Grid>

			)
			}
		</>
	);
};

UploadButton.propTypes = {
	files       : PropTypes.array.isRequired,
	setFiles    : PropTypes.func.isRequired,
	fileMessage : PropTypes.string.isRequired,
	title       : PropTypes.string.isRequired,
	download    : PropTypes.bool,
};

UploadButton.defaultProps = {
	download : false,
};

export default UploadButton;
