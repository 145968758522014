import React                  from "react";
import PropTypes              from "prop-types";
import { connect }            from "react-redux";
import { bindActionCreators } from "redux";
import MenuIcon               from "@material-ui/icons/Menu";
import {
	AppBar,
	Toolbar,
	Typography,
	IconButton
} from "@material-ui/core";

// Import Own Components
import UserImage     from "../UserImage";
import DrawerActions from "../../config/store/actions/drawerActions";
import useStyles     from "./styles";
import "./Header.scss";


const Header = ({
	customerData,
	type,
	drawerActions,
}) => {
	const classes = useStyles();

	return (
		<AppBar
			className={`${classes.root} Header`}
			position="static"
			style={{ backgroundColor : "rgb(5, 0, 43)" }}
		>
			<Toolbar>
				<>
					<UserImage />

					<Typography
						variant="subtitle1"
						component="h4"
						color="inherit"
						className={`${classes.grow} ${classes.lightColor}`}
					>
						{ customerData.name }
					</Typography>

					<IconButton
						className="Menu"
						onClick={() => drawerActions.openDrawer()}
					>
						<MenuIcon className={`${classes.lightColor} MenuIcon`} />
					</IconButton>
				</>
			</Toolbar>
		</AppBar>
	);
};

Header.propTypes = {
	customerData  : PropTypes.object,
	type          : PropTypes.string.isRequired,
	drawerActions : PropTypes.object.isRequired,
};

Header.defaultProps = {
	customerData : { name : "" },
};

const mapStateToProps = ({ authReducer : { loggedIn, customerData, type } }) => ({ loggedIn, customerData, type });
const mapDispatchToProps = dispatch => ({ drawerActions : bindActionCreators(DrawerActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Header);
