import React from "react";
import PropTypes	from "prop-types";

// import own components
import Compliance   from "../../components/Compliance";
import ModuleContainer from "../../components/ModuleContainer";

const CompliancePage = ({ match }) => {
	const { complianceType } = match.params;

	return (
		<div>
			<ModuleContainer
				title={`Cumplimiento del ${complianceType}`}
				color="#f3f0f0"
				background="#e28743"
				icon="far fa-tasks"
				md={11}
				lg={11}
			>
				<Compliance
					match={match}
				/>
			</ModuleContainer>
		</div>
	);
};

CompliancePage.propTypes = {
	match	: PropTypes.object.isRequired,
};

export default CompliancePage;
