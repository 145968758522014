import React                  from "react";
import PropTypes              from "prop-types";
import Close                  from "@material-ui/icons/Close";
import { connect }            from "react-redux";
import { bindActionCreators } from "redux";
import {
	Grid,
	Paper,
	Dialog,
	Button,
	IconButton
} from "@material-ui/core";
import Slide          from "@material-ui/core/Slide";

// Import Own Components
import ModalActions   from "../../config/store/actions/modalActions";
import useStyles      from "./styles";
import "./MobileModal.scss";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const MobileModal = ({
	open,
	title,
	maxWidth,
	iconTitle,
	component,
	modalActions,
	onClose,
	xs,
	sm,
	md,
	lg
}) => {
	const closeDialog = () => { onClose(); modalActions.closeModal(); };
	const classes = useStyles();
	return (
		<div>
			<Dialog
				fullScreen
				open={open}
				onClose={closeDialog}
				maxWidth={maxWidth}
				className="MobileModal"
				TransitionComponent={Transition}
			>
				<Grid container justify="center" alignContent="center">
					<div className="dialogContent">
						<div className="background">
							<div className={`${classes.upperColorBg} upperColor`}>&nbsp;</div>
							<div className={`${classes.lowerColorBg} lowerColor`}>&nbsp;</div>
						</div>
						<div className="headerContainer">
							<Grid container justify="center" direction="row">
								<Grid item xs={11} sm={10} md={8} lg={9} container justify="space-between" direction="row">
									<Button
										onClick={closeDialog}
										className={`${classes.grayColor} ${classes.button}`}
										aria-label="cerrar"
									>
										<span className={classes.fontLink}>
											<i className="fal fa-angle-left" />
											Volver
										</span>
									</Button>
									<IconButton
										onClick={closeDialog}
										className={`${classes.grayColor} ${classes.button} ${classes.iconButton}`}
									>
										<Close />
									</IconButton>
								</Grid>
								<Grid item xs={12} container justify="center" direction="row">
									<h1 className={`${classes.grayColor} ${classes.titleModal}`}>
										<i className={iconTitle} />
										{title.toUpperCase()}
									</h1>
								</Grid>
							</Grid>
						</div>
						<div className="container">
							<Grid container justify="center">
								<Grid item xs={xs} sm={sm} md={md} lg={lg}>
									<Paper className="childrenContainer">
										<Grid container justify="center">
											{component}
										</Grid>
									</Paper>
								</Grid>
							</Grid>
						</div>
					</div>
				</Grid>
			</Dialog>
		</div>
	);
};

MobileModal.propTypes = {
	open         : PropTypes.bool,
	maxWidth     : PropTypes.string,
	title        : PropTypes.string,
	component    : PropTypes.element,
	modalActions : PropTypes.object.isRequired,
	iconTitle    : PropTypes.string,
	xs           : PropTypes.number,
	sm           : PropTypes.number,
	md           : PropTypes.number,
	lg           : PropTypes.number,
	onClose      : PropTypes.func,
};

MobileModal.defaultProps = {
	open      : false,
	maxWidth  : "xl",
	title     : "",
	component : <></>,
	iconTitle : null,
	xs        : 11,
	md        : 10,
	sm        : 8,
	lg        : 7,
	onClose   : () => {}
};

const mapStateToProps    = ({ modalReducer }) => ({ ...modalReducer });
const mapDispatchToProps = dispatch => ({ modalActions : bindActionCreators(ModalActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(MobileModal);
