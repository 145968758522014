import React from "react";
import PropTypes	from "prop-types";

// import own components
import ModuleContainer	from "../../../components/ModuleContainer";
import NewSalary   from "../../../components/Requests/NewSalary";

const NewSalaryPage = ({ match }) => (
	<div>
		<ModuleContainer
			title="Tramite IMSS > Modificación de Salario"
			color="#f3f0f0"
			background="#13322b"
			icon="fas fa-money-check-edit-alt"
			md={11}
			lg={11}
		>
			<NewSalary
				match={match}
			/>
		</ModuleContainer>
	</div>
);

NewSalaryPage.propTypes = {
	match	: PropTypes.object.isRequired,
};

export default NewSalaryPage;
