/* eslint-disable react/jsx-curly-brace-presence */
import React, {
	useEffect,
	useState
} from "react";
import PropTypes        from "prop-types";
import { Button, Grid } from "@material-ui/core";
import { connect }      from "react-redux";

// import own components
import CardDirectory   from "../../components/CardDirectory";
import ModuleContainer from "../../components/ModuleContainer";
import userService     from "../../config/services/userService";
import NoteDetails     from "../../components/Notes/NoteDetails";
import "./StatusAccount.scss";

const StatusAccount = ({ customerId }) => {
	const initState = {
		noteDetails : {
			data       : {},
			own        : 0,
			obligation : 0,
		},
		details : {
			show : false,
			type : ""
		}
	};

	const [note, setNoteDetails] = useState(initState.noteDetails);
	const [showDetails, setShowDetails] = useState(initState.details);

	useEffect(() => {
		(async () => {
			try {
				const statusAccountResponse = await userService.getStatusAccount(customerId);

				if (statusAccountResponse) {
					const fees = statusAccountResponse.response.reduce((accum, noteDetail) => {
						if (noteDetail.concept_type === "OWN" && noteDetail.status === "PENDING") {
							accum.own += parseFloat(noteDetail.total) - parseFloat(noteDetail.deposited);
						} else if (noteDetail.concept_type === "OBLIGATION" && noteDetail.status === "PENDING") {
							accum.obligation += parseFloat(noteDetail.total) - parseFloat(noteDetail.deposited);
						}
						return accum;
					}, {
						own        : 0,
						obligation : 0,
					});
					setNoteDetails({
						data       : statusAccountResponse.response,
						obligation : fees.obligation,
						own        : fees.own,
					});
				}
			} catch (err) {
				// console.log(err);
			}
		})();
	}, [customerId]);

	const showNoteDetails = e => (type = "") => setShowDetails({
		show : !showDetails.show,
		type
	});

	return (
		<div className="StatusAccount">
			<ModuleContainer
				title="ESTADO DE CUENTA"
				color="#f3f0f0"
				background="#0a8570"
				icon="far fa-file-invoice-dollar"
			>
				{
					!showDetails.show ? (
						<>
							<div className="topHeader" />
							<CardDirectory
								key="example"
								title={`${note.own.toLocaleString("en-US", { style : "currency", currency : "USD" })} MNX`}
								text="Honorarios pendientes"
								color="#f3f0f0"
								background="#22653d"
								link=""
							>
								{
									note.data.length && (
										<Button onClick={e => showNoteDetails(e)("own")} className="buttonDetails">
											<i className="far fa-search" />
											ver detalles
										</Button>
									)
								}
							</CardDirectory>
							<CardDirectory
								key="example2"
								title={`${note.obligation.toLocaleString("en-US", { style : "currency", currency : "USD" })} MNX`}
								text="Obligaciones pendientes de pago"
								color="#f3f0f0"
								background="#7d1d7d"
								link=""
							>
								{
									note.data.length && (
										<Button onClick={e => showNoteDetails(e)("obligation")} className="buttonDetails">
											<i className="far fa-search" />
											ver detalles
										</Button>
									)
								}
							</CardDirectory>
						</>
					) : (
						<>
							<Grid container justify="space-between" className="header-container">
								<Button size="small" onClick={e => showNoteDetails(e)()}>
									<h4>
										<i className="far fa-arrow-alt-left" />
										{"Regresar"}
									</h4>
								</Button>
								<Button size="small" onClick={e => showNoteDetails(e)()}>
									<h4>
										<i className="far fa-times" />
									</h4>
								</Button>
							</Grid>
							<NoteDetails note={note} type={showDetails.type} />
						</>
					)
				}
			</ModuleContainer>
		</div>
	);
};

StatusAccount.propTypes = {
	customerId : PropTypes.number.isRequired,
};

const mapStateToProps = ({ authReducer : { customerData } }) => ({
	customerId : customerData.id,
});

export default connect(mapStateToProps, null)(StatusAccount);
