/* eslint-disable camelcase */
import React, { useState }    from "react";
import PropTypes              from "prop-types";
import { connect }            from "react-redux";
import { bindActionCreators } from "redux";
import {
	Button,
	Card,
	CardHeader,
	Avatar,
	withStyles
} from "@material-ui/core";

// Import Own Components
import AuthActions  from "../../config/store/actions/authActions";
import ModalActions from "../../config/store/actions/modalActions";
// import { history }  from "../../config/helpers";
import "./AccountData.scss";

const styles = theme => ({
	fallbackAvatar : {
		backgroundColor : "#3a8fb7",
	},
});

const AccountData = ({
	title,
	subheader,
	file_id,
	id,
	customerId,
	classes,
	authActions,
	modalActions,
}) => {
	const [useFallbackImage, setUseFallbackImage] = useState(Boolean(!file_id));

	const FallbackImage = props => (
		<Avatar
			{...props}
			className={classes.fallbackAvatar}
		>
			{ title[0].toUpperCase() }
		</Avatar>
	);

	const changeAccout = () => {
		if (customerId !== id) {
			authActions.getCustomerData(id);
		}
		// history.push("/");
		modalActions.closeModal();
	};

	return (
		<Button
			className="AccountData"
			onClick={changeAccout}
		>
			<Card className="Card">
				<CardHeader
					avatar={(
						useFallbackImage ? (
							<FallbackImage />
						) : (
							<Avatar
								alt={`${title}Pic`}
								src={`${process.env.REACT_APP_SYSCONTROL_API}/storage/file/${file_id}`}
								className={`${classes.largeUserImage} UserImage`}
								onError={(e) => {
									setUseFallbackImage(true);
								}}
							/>
						)
					)}
					title={title}
					subheader={subheader}
				/>
			</Card>
		</Button>
	);
};

AccountData.propTypes = {
	title        : PropTypes.string.isRequired,
	subheader    : PropTypes.string.isRequired,
	file_id      : PropTypes.bool,
	id           : PropTypes.number.isRequired,
	customerId   : PropTypes.number.isRequired,
	classes      : PropTypes.object.isRequired,
	authActions  : PropTypes.object.isRequired,
	modalActions : PropTypes.object.isRequired,
};

AccountData.defaultProps = {
	file_id : false,
};

const mapStateToProps = ({ authReducer : { customerData } }) => ({ customerId : customerData.id });

const mapDispatchToProps = dispatch => ({
	authActions  : bindActionCreators(AuthActions, dispatch),
	modalActions : bindActionCreators(ModalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
	withStyles(styles)(AccountData)
);
