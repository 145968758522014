/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useState } from "react";
import { connect }         from "react-redux";
import PropTypes           from "prop-types";
import Rating              from "@material-ui/lab/Rating";
import DateFnsUtils        from "@date-io/date-fns";
import { format }          from "date-fns";
import esLocale            from "date-fns/locale/es";
import swal                from "sweetalert2";
import {
	Box,
	Grid,
	Button,
	TextField,
	Typography,
	InputAdornment,
	FormControl,
	MenuItem
} from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	DateTimePicker
} from "material-ui-pickers";
import { makeStyles }        from "@material-ui/core/styles";
import NotificationImportant from "@material-ui/icons/NotificationImportant";

// Import userService
import userService from "../../config/services/userService";

// import own components
import MessageValidate from "../MessageValidate";
import "./PendingForm.scss";

const useStyles = makeStyles(theme => ({
	MainContainer : {
		display        : "flex",
		flexDirection  : "column",
		justifyContent : "space-between",
		heigth         : "auto",
		minHeight      : "50vh",
	},
	inputContainer : {
		padding : "1rem 1.2rem",
	},
	rating1 : {
		width      : 200,
		display    : "flex",
		alignItems : "center",
	},
	svg : {
		margin : "0 0.5rem",
	},
	form : {
		width     : "100%",
		marginTop : "1rem",
	}
}));

const PendingForm = ({
	mode,
	formData,
	onClose,
	userType,
	userId
}) => {
	const classes = useStyles();

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	const initState = {
		submitted : false,
		formData  : {
			...formData
		},
	};
	const [state, setState] = useState(initState);
	const [render, setRender] = useState(false);
	const [hover, setHover] = React.useState(-1);

	const priorities = {
		1 : "sin prioridad",
		2 : "baja",
		3 : "media",
		4 : "alta",
	};

	const statusList = [
		"activo",
		"en proceso",
		"pausado",
		"terminado"
	];

	const handleChange = name => (e) => {
		const { value } = e.target;
		setState({
			...state,
			formData : {
				...state.formData,
				[name] : value
			}
		});
	};

	const changeStatus = (event) => {
		handleChange("status")(event);
		const { value } = event.target;
		userService.updatePending(state.formData.id, { pendings : { status : value } });
		userService.postPendingLog({
			action_date : format(new Date(), "yyyy-MM-dd HH:mm"),
			description : `ha marcado como <b>${value}</b> este pendiente`,
			pending_id  : state.formData.id,
			user_id     : userId
		});
		setRender(true);
	};

	const getKeyByValue = (object, value) => parseInt(Object.keys(object).find(key => object[key] === value), 10);

	const formValidate = async () => new Promise((resolve) => {
		let validate = false;
		if (state.formData.name && state.formData.description && state.formData.priority && state.formData.required_date) {
			validate = true;
		}
		resolve(validate);
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		setState({
			...state,
			submitted : true,
		});

		try {
			const validateForm = await formValidate();
			if (validateForm) {
				userService.createPending({
					pendings : {
						...state.formData,
						registry_date : format(new Date(), "yyyy-MM-dd HH:mm"),
						status        : "activo",
						type          : "pendiente",
						priority      : "alta",
						period        : "nunca",
					}
				});
				swal.fire("Enviado!", "Tu consulta ha sido enviada correctamente!", "success");
				setTimeout(() => onClose(true), 3000);
			} else {
				swal.fire("Error", "Debes completar el formulario", "warning");
			}
		} catch (error) {
			swal.fire(`Error ${error}`, "error");
		}
	};

	return (
		<div className="PendingForm">
			<form
				onSubmit={handleSubmit}
				className={classes.form}
			>
				<div className={classes.MainContainer}>
					<Grid item xs={12} container alignItems="center" direction="row">
						<Grid item xs={12} md={6} className={classes.inputContainer}>
							<TextField
								disabled={mode === "show"}
								fullWidth
								id="input-name"
								label="Asunto"
								type="text"
								name="name"
								autoComplete="off"
								title="Asunto principal de la consulta"
								value={state.formData.name}
								onChange={handleChange("name")}
								InputProps={{
									startAdornment : (
										<InputAdornment position="start">
											<i className="far fa-thumbtack" />
										</InputAdornment>
									),
								}}
								required
							/>
							{state.submitted && !state.formData.name && (
								<MessageValidate message="El asunto es requerido." />
							)}
						</Grid>
						<Grid item xs={12} className={classes.inputContainer}>
							<TextField
								id="filled-textarea"
								label="Descripción"
								placeholder="Describe tu consulta o pendiente..."
								multiline
								margin="normal"
								name="message"
								autoComplete="off"
								value={state.formData.description}
								onChange={handleChange("description")}
								fullWidth
								required
								disabled={mode === "show"}
							/>
							{state.submitted && !state.formData.description && (
								<MessageValidate message="La descripción es requerida." />
							)}
						</Grid>
						<Grid item xs={12} md={6} className={classes.inputContainer}>
							<Typography component="legend">Prioridad de la consulta *</Typography>
							<div className={classes.rating1}>
								<Rating
									disabled={mode === "show"}
									name="hover-side"
									value={getKeyByValue(priorities, state.formData.priority)}
									precision={1}
									onChangeActive={(event, newHover) => {
										setHover(newHover);
									}}
									onChange={(event, newValue) => {
										setState({
											...state,
											formData : {
												...state.formData,
												priority : priorities[newValue]
											}
										});
									}}
									max={4}
									icon={<NotificationImportant />}
									required
								/>
								<Box ml={2}>
									{priorities[hover !== -1 ? hover : getKeyByValue(priorities, state.formData.priority)]}
								</Box>
							</div>
							{state.submitted && !state.formData.priority && (
								<MessageValidate message="La prioridad es requerida." />
							)}
						</Grid>
						{state.formData.status && userType !== "employee" && (
							<Grid item xs={12} md={6} className={classes.inputContainer}>
								<Typography component="legend">{`Status: ${state.formData.status}`}</Typography>
							</Grid>
						)}
						{state.formData.status && userType === "employee" && (
							<Grid item xs>
								<FormControl className={classes.inputContainer}>
									<TextField
										required
										select
										id="withdrawalCause"
										label="Estatus"
										value={state.formData.status}
										onChange={event => changeStatus(event)}
									>
										{
											statusList.map(
												(value, idx) => <MenuItem value={value}>{value}</MenuItem>
											)
										}
									</TextField>
								</FormControl>
							</Grid>
						)}
						{state.formData.customer_name && userType === "employee" && (
							<Grid item xs={12} md={6} className={classes.inputContainer}>
								<Typography component="legend">{`Cliente: ${state.formData.customer_name}`}</Typography>
							</Grid>
						)}
						{state.formData.employee_creator_name && userType === "employee" && (
							<Grid item xs={12} md={6} className={classes.inputContainer}>
								<Typography component="legend">{`Creado por: ${state.formData.employee_creator_name}`}</Typography>
							</Grid>
						)}
						{state.formData.employee_responsible_name && (
							<Grid item xs={12} md={6} className={classes.inputContainer}>
								<Typography component="legend">{`Lo atiende: ${state.formData.employee_responsible_name}`}</Typography>
							</Grid>
						)}
						<Grid item xs={12} md={6} className={classes.inputContainer}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
								<Grid container justify="space-around">
									<DateTimePicker
										className="hidden-input"
										onChange={(date) => {
											setState({
												...state,
												formData : {
													...state.formData,
													required_date : format(date, "yyyy-MM-dd HH:mm")
												}
											});
										}}
										value={state.formData.required_date}
										cancelLabel="CANCELAR"
										id="time-picker-shipping"
										margin="normal"
										label="Atender antes de:"
										fullWidth
										minDate={yesterday}
										disabled={mode === "show"}
									/>
								</Grid>
							</MuiPickersUtilsProvider>
							{state.submitted && !state.formData.required_date && (
								<MessageValidate message="La fecha es requerida." />
							)}
						</Grid>
					</Grid>
					<Grid item xs={12} container justify="space-between" alignItems="flex-end" className={classes.inputContainer}>
						<Button
							color="primary"
							className={classes.button}
							onClick={() => onClose(render)}
						>
							{" cerrar "}
						</Button>
						{mode === "save" && (
							<Button
								variant="contained"
								color="primary"
								type="submit"
								size="small"
							>
								{"Crear consulta"}
								<i className={`${classes.svg} fal fa-thumbtack`} />
							</Button>
						)}
					</Grid>
				</div>
			</form>
		</div>
	);
};

PendingForm.propTypes = {
	userType : PropTypes.string.isRequired,
	userId   : PropTypes.number.isRequired,
	formData : PropTypes.object,
	onClose  : PropTypes.func,
	mode     : PropTypes.oneOf(["save", "edit", "show"]),
};

PendingForm.defaultProps = {
	formData : {},
	mode     : "save",
	onClose  : () => {},
};

const mapStateToProps = ({ authReducer : { userData, type } }) => (
	{
		userType : type,
		userId   : userData.user_id
	}
);

export default connect(mapStateToProps, null)(PendingForm);
