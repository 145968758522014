import React        from "react";
import PropTypes    from "prop-types";
// Import Own Components
import FooterEmployee from "../../components/Footer/FooterEmployee.jsx";
import HeaderEmployee from "../../components/Header/HeaderEmployee.jsx";

const EmployeeLayout = ({ children }) => (
	<>
		<HeaderEmployee />
		<main>
			{children}
		</main>
		<FooterEmployee />
	</>
);

EmployeeLayout.propTypes = {
	children : PropTypes.node.isRequired
};


export default EmployeeLayout;
