/* eslint-disable max-len */
import React,
{
	useState,
	useEffect
}					from "react";
import PropTypes				from "prop-types";
import { connect }  			from "react-redux";
import { bindActionCreators }	from "redux";
import {
	Fab,
	Grid,
	Dialog,
	Slide,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Toolbar,
	Tooltip,
	IconButton,
	TextField,
	TablePagination,
	SwipeableDrawer,
	List,
	ListItem,
	InputLabel,
	Select,
	FormControl,
	MenuItem
}           from "@material-ui/core";
import Autocomplete   		from "@material-ui/lab/Autocomplete";
import { DatePicker }       from "@material-ui/pickers";
import AddIcon			    from "@material-ui/icons/Add";
import swal				    from "sweetalert2";
import { format }		    from "date-fns-tz";
import esLocale			    from "date-fns/locale/es";
import ModalActions		    from "../../config/store/actions/modalActions.js";

// Import userService
import userService		from "../../config/services/userService";

// import own components
import ModuleContainer					from "../ModuleContainer";
import CircularProgressWithLabel		from "../CircularProgressWithLabel";
import RequestSelector					from "./RequestSelector";
import RequestDetails					from "./RequestDetails";
import useStyles						from "./styles";
import {
	mapStatus, mapRequestType,
	filtersInit, disabilitiesTypes
}	from "./const";
import "./Requests.scss";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Requests = ({
	match, employeeId, modalActions, userType
}) => {
	const [openSelector, setOpenSelector]		= useState(false);
	const [openDetails, setOpenDetails]			= useState(false);
	const [reload, setReload]					= useState(false);
	const [requests, setRequests]				= useState([]);
	const [page, setPage]                   	= useState(0);
	const [rowsPerPage, setRowsPerPage]     	= useState(5);
	const [total, setTotal]                 	= useState(0);
	const [openDrawer, setOpenDrawer]       	= useState(false);
	const [filters, setFilters]   		    	= useState(filtersInit);
	const [customerFilter, setCustomerFilter]	= useState("");
	const [customers, setCustomers]				= useState([]);
	const [employeeFilter, setEmployeesFilter]	= useState("");
	const [employees, setEmployees]				= useState([]);
	const [branchOffices, setBranchOffices]     = useState([]);

	const handleRequestSelector = () => {
		setOpenSelector(!openSelector);
	};

	const handleOpenDetails = () => {
		if (openDetails) {
			setReload(!reload);
		}
		setOpenDetails(!openDetails);
	};

	const handleChangeFilter = name => (d) => {
		const value = d && d.target ? d.target.value : d;
		setFilters({
			...filters,
			[name] : value,
		});
	};

	const getPaginate = async (_page, perPage) => {
		const data = await userService.getIMSSRequests(
			{
				employee_id : employeeId,
				page        : _page,
				perPage,
				...filters,
			}
		);
		if (data.status !== 200) {
			swal.fire("¡Error!", "Ha ocurrido un error al cargar las solicitudes", "error");
			return;
		}
		setRequests(data.data);
		setTotal(data.pagination.total);
	};

	const getCustomerBranchOffices = async (customerId) => {
		try {
			const response = await userService.getCustomerBranchOffices(
				{
					customer_id                : customerId,
					with_employer_registration : true
				}
			);
			if (response.data) {
				setBranchOffices(response.data);
			}
		} catch (error) {
			swal.fire(`Error ${error}`, "error");
		}
	};

	const setCustomer = (customerId) => {
		handleChangeFilter("customer_id")(customerId);
		getCustomerBranchOffices(customerId);
	};

	const getCustomers = async () => {
		const response = await userService.getCustomers({
			page     : 1,
			per_page : 20,
			type   	 : "activo",
			filter   : customerFilter
		});
		if (response.status === 200) {
			setCustomers(response.data);
		} else {
			swal.fire("!Error!", "Error al cargar los clientes", "error");
		}
	};

	useEffect(() => {
		getCustomers();
	}, [customerFilter]);

	const getEmployees = async () => {
		const response = await userService.getEmployees({
			page     : 1,
			per_page : 20,
			filter   : employeeFilter
		});
		if (response.status === 200) {
			setEmployees(response.data);
		} else {
			swal.fire("!Error!", "Error al cargar los clientes", "error");
		}
	};

	useEffect(() => {
		getEmployees();
	}, [customerFilter]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		getPaginate(newPage + 1, rowsPerPage);
	};

	const toggleDrawer = open => (e) => {
		setOpenDrawer(open);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		getPaginate(1, parseInt(event.target.value, 10));
	};

	const classes = useStyles();

	useEffect(() => {
		getPaginate(page, rowsPerPage);
	}, [filters]);

	useEffect(() => {
		getPaginate(page, rowsPerPage);
	}, [reload]);

	const showDetails = e => (id, name, type) => {
		modalActions.openModal({
			title     : `Solicitud: ${name}`,
			iconTitle : mapRequestType[type].icon,
			md        : 11,
			lg        : 11,
			component : <RequestDetails
				onClose={handleOpenDetails}
				requestId={id}
				requestType={type}
				userType={userType}
			/>
		});
	};

	const exportRequests = () => {
		const url = new URL(`${process.env.REACT_APP_SYSCONTROL_API}/imss_request_export`);
		url.search = new URLSearchParams(filters);

		window.open(url.href, "_blank");
	};

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	return (
		<div className="Requests">
			<ModuleContainer
				title="SOLICITUDES PARA IMSS"
				color="#f3f0f0"
				background="#13322b"
				icon="fas fa-inbox-in"
				md={11}
				lg={11}
			>
				<div className="topHeader" />
				<Toolbar style={{ width : "100%" }}>
					<Grid container spacing={1} alignItems="flex-end">
						<Grid style={{ width : "3%" }}>
							<i className="fas fa-search" />
						</Grid>
						<Grid style={{ width : "97%" }}>
							<TextField fullWidth id="input-with-icon-grid" label="Búsqueda libre" onChange={handleChangeFilter("filter")} />
						</Grid>
					</Grid>
					<Tooltip title="Exportar reporte">
						<IconButton aria-label="export report" onClick={() => exportRequests()}>
							<i className="fas fa-file-excel" style={{ color : "#1D6F43" }} />
						</IconButton>
					</Tooltip>
					<Tooltip title="Filtros avanzados">
						<IconButton aria-label="filter list" onClick={toggleDrawer(true)}>
							<i className="far fa-filter" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Limpiar filtros">
						<IconButton aria-label="filter list" onClick={() => setFilters(filtersInit)}>
							<i className="far fa-filter" />
							<i className={`fas fa-times ${classes.filterCancel}`} />
						</IconButton>
					</Tooltip>
				</Toolbar>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell align="center">Folio</TableCell>
								<TableCell align="center">Tipo de Solicitud</TableCell>
								<TableCell align="center">Cliente</TableCell>
								<TableCell align="center">Responsable</TableCell>
								<TableCell align="center">Fecha de Creación</TableCell>
								<TableCell align="center">Fecha de Solicitud</TableCell>
								<TableCell align="center">Estatus</TableCell>
								<TableCell align="center">% de avance</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{requests.map((request) => {
								const statusMapped = mapStatus[request.status];
								return (
									<TableRow
										key={request.folio}
										onClick={e => showDetails(e)(request.id, request.name, request.request_type)}
										hover
									>
										<TableCell align="center">{request.folio}</TableCell>
										{request.request_type !== "DISABILITIES"
											? <TableCell align="center">{mapRequestType[request.request_type].name}</TableCell>
											: (
												<TableCell align="center">
Incapacidad por
													{" "}
													{disabilitiesTypes[request.imss_request.disability_type]}
												</TableCell>
											)}
										<TableCell align="left">{request.customer ? request.customer.name : "No asignado"}</TableCell>
										<TableCell align="left">{request.responsible_employee.name}</TableCell>
										<TableCell align="center">
											{format(new Date(`${request.created_at.replace(" ", "T")}Z`), "dd/MMMM/yyyy hh:mm aaa",
										 { timezone : Intl.DateTimeFormat().resolvedOptions().timeZone, locale : esLocale })}
										</TableCell>
										<TableCell align="center">
											{format(new Date(`${request.movement_date.replace(" ", "T")}Z`), "dd/MMMM/yyyy",
										 { timezone : Intl.DateTimeFormat().resolvedOptions().timeZone, locale : esLocale })}
										</TableCell>
										<TableCell align="center">{statusMapped.name}</TableCell>
										<TableCell align="left">
											<Grid
												container
												direction="column"
												justify="flex-start"
												alignItems="center"
											>
												<Grid item>
													<CircularProgressWithLabel
														primaryClass={`${statusMapped.className} progressCircle`}
														secondaryClass={`${statusMapped.className}-back`}
														labelClassName="progressCircleLabel"
														value={statusMapped.progress}
														size={45}
													/>
												</Grid>
												{/* <Grid item>
                                            <Typography variant="h6"
                                                align="center"
                                                className={`${classes.marginText}
                                                ${classes.statusFont}`}>
                                                {statusMapped.name}
                                            </Typography>
                                        </Grid> */}
											</Grid>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					labelRowsPerPage="Registros por pagina"
					labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más que ${to}`}`}
					component="div"
					count={total}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</ModuleContainer>
			<SwipeableDrawer
				anchor="left"
				open={openDrawer}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
			>
				<Typography variant="h6" className={classes.drawerTitle}>
                    Filtros avanzados para solicitudes de nominas
				</Typography>
				<List>

					{/* BÚSQUEDA POR EMPLEADO */}
					<ListItem>
						<Grid container spacing={1} alignItems="flex-end">
							<Grid item xs={1}>
								<i className="fas fa-search" />
							</Grid>
							<Grid item xs={11}>
								<Autocomplete
									onChange={(e, newValue) => handleChangeFilter("employee")(newValue && newValue.id ? newValue.id : null)}
									getOptionSelected={(option, value) => option.name === value.name}
									getOptionLabel={option => option.name}
									options={employees}
									inputValue={employeeFilter}
									onInputChange={(event, newInputValue) => {
										setEmployeesFilter(newInputValue);
									}}
									noOptionsText="No hay más empleados"
									loadingText="Cargando..."
									renderInput={params => (
										<TextField
											{...params}
											label="Buscar por empleados"
											inputProps={{
												...params.inputProps,
												autoComplete : "no-fill",
											}}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</ListItem>

					{/* BÚSQUEDA POR CLIENTE */}
					<ListItem>
						<Grid container spacing={1} alignItems="flex-end">
							<Grid item xs={1}>
								<i className="fas fa-search" />
							</Grid>
							<Grid item xs={11}>
								<Autocomplete
									onChange={(e, newValue) => setCustomer(newValue && newValue.id ? newValue.id : null)}
									getOptionSelected={(option, value) => option.name === value.name}
									getOptionLabel={option => option.name}
									options={customers}
									inputValue={customerFilter}
									onInputChange={(event, newInputValue) => {
										setCustomerFilter(newInputValue);
									}}
									noOptionsText="No hay más clientes"
									loadingText="Cargando..."
									renderInput={params => (
										<TextField
											{...params}
											label="Buscar por clientes"
											inputProps={{
												...params.inputProps,
												autoComplete : "no-fill",
											}}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</ListItem>

					{/* BÚSQUEDA POR SUCURSAL */}
					{branchOffices.length ? (
						<ListItem>
							<Grid container spacing={1} alignItems="flex-end">
								<Grid item xs={1}>
									<i className="fas fa-search" />
								</Grid>
								<Grid item xs={11}>
									<FormControl className="formControl" style={{ width : "100%" }}>
										<TextField
											select
											id="branch-office-select"
											label="Buscar por sucursal"
											value={filters.branch_office_id || ""}
											onChange={e => handleChangeFilter("branch_office_id")(e)}
										>
											{
												branchOffices.map(b => (
													<MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
												))
											}
										</TextField>
									</FormControl>
								</Grid>
							</Grid>

						</ListItem>
					) : <></>}

					{/* TIPO DE MOVIMIENTO */}
					<ListItem>
						<Grid container spacing={1} alignItems="flex-end">
							<Grid item xs={1}>
								<i className="fas fa-search" />
							</Grid>
							<Grid item xs={11}>
								<FormControl className={classes.formControl}>
									<InputLabel id="request_type">Tipo de Solicitud</InputLabel>
									<Select
										labelId="request_type"
										value={filters.type}
										onChange={handleChangeFilter("type")}

									>
										<MenuItem value="">Todos</MenuItem>
										<MenuItem value="REGISTER_WORKER">Alta de Empleado</MenuItem>
										<MenuItem value="WITHDRAWAL">Baja de Empleado</MenuItem>
										<MenuItem value="NEW_SALARY">Modificación de Salario</MenuItem>
										<MenuItem value="DISABILITIES">Incapacidades</MenuItem>
										<MenuItem value="EMPLOYMENT_CREDIT">Crédito de Empleados</MenuItem>
										<MenuItem value="EMPLOYER_REGISTRATION">Alta de Registro Patronal</MenuItem>
										<MenuItem value="EMPLOYER_REGISTRATION_WITHDRAWAL">Baja de Registro Patronal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</ListItem>

					{/* FECHA INICIAL */}
					<ListItem>
						<Grid container spacing={1} alignItems="flex-end">
							<Grid item xs={1}>
								<i className="far fa-calendar-alt" />
							</Grid>
							<Grid item xs={11}>
								<DatePicker
									autoOk
									fullWidth
									format="dd-MMMM-yyyy"
									cancelLabel="CANCELAR"
									showTodayButton
									todayLabel="HOY"
									color="primary"
									orientation="portrait"
									openTo="date"
									label="Fecha inicial"
									inputVariant="standard"
									variant="dialog"
									value={filters.date_start}
									onChange={date => handleChangeFilter("date_start")(format(date, "yyyy-MM-dd HH:mm"))}
								/>
							</Grid>
						</Grid>
					</ListItem>

					{/* TIPO DE MOVIMIENTO */}
					<ListItem>
						<Grid container spacing={1} alignItems="flex-end">
							<Grid item xs={1}>
								<i className="far fa-calendar-alt" />
							</Grid>
							<Grid item xs={11}>
								<DatePicker
									autoOk
									fullWidth
									format="dd-MMMM-yyyy"
									cancelLabel="CANCELAR"
									showTodayButton
									todayLabel="HOY"
									color="primary"
									orientation="portrait"
									openTo="date"
									label="Fecha final"
									inputVariant="standard"
									variant="dialog"
									value={filters.date_end}
									onChange={date => handleChangeFilter("date_end")(format(date, "yyyy-MM-dd HH:mm"))}
								/>
							</Grid>
						</Grid>
					</ListItem>
				</List>
			</SwipeableDrawer>
			<Dialog
				open={openSelector}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleRequestSelector}
				aria-labelledby="Crear nueva solicitud"
				aria-describedby="Crear nueva solicitud"
			>
				<RequestSelector match={match} modal />
			</Dialog>
			<Fab
				variant="extended"
				color="primary"
				className={classes.fab}
				aria-label="Nueva Solicitud"
				onClick={handleRequestSelector}
			>
				<AddIcon className="extendedIcon" />
				<span className="fabText">Nueva Solicitud</span>
			</Fab>
		</div>
	);
};


Requests.propTypes = {
	match        : PropTypes.object.isRequired,
	employeeId   : PropTypes.number.isRequired,
	modalActions : PropTypes.object.isRequired,
	userType     : PropTypes.string.isRequired,
};

const mapStateToProps = ({ authReducer: { customerData, type } }) => ({
	employeeId : customerData.id,
	userType   : type,
});

const mapDispatchToProps = dispatch => ({ modalActions : bindActionCreators(ModalActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
