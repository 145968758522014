/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState }	from "react";
import PropTypes 	from "prop-types";
import {
	List,
	ListItem,
	ListItemAvatar,
	Grid,
	Typography
} from "@material-ui/core";
import { bindActionCreators }	from "redux";
import { connect }  			from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Preview 	from "./Preview";
import ModalActions		from "../../config/store/actions/modalActions.js";
import "./ListFiles.scss";

const useStyles = makeStyles(theme => ({
	root : {
		width           : "100%",
		backgroundColor : theme.palette.background.paper,
	}
}));

const ListFiles = ({
	files, modalActions
}) => {
	const [openDetails, setOpenDetails]		= useState(false);

	const downloadFile = (urlFile, name) => {
		fetch(urlFile, {
			method : "get",
		}).then(response => response.blob()).then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", name);
			document.body.appendChild(link);
			link.click();
		});
	};
	const classes = useStyles();
	const filesType = {
		jpg	   : { preview : true, class : "fal fa-file-image color-yellow fa-5x", tipo : "Imagen JPG" },
		JPG	   : { preview : true, class : "fal fa-file-image color-yellow fa-5x", tipo : "Imagen JPG" },
		jpeg   : { preview : true, class : "fal fa-file-image color-yellow fa-5x", tipo : "Imagen JPEG" },
		png    : { preview : true, class : "fal fa-file-image color-yellow fa-5x", tipo : "Imagen PNG" },
		pdf    : { preview : true, class : "fal fa-file-pdf color-red fa-5x", tipo : "Archivo PDF" },
		xlsx   : { preview : false, class : "fal fa-file-excel color-green fa-5x", tipo : "Excel" },
		csv    : { preview : false, class : "fal fa-file-excel color-green fa-5x", tipo : "Excel" },
		docx   : { preview : false, class : "fal fa-file-word color-blue fa-5x", tipo : "Word" },
		pptx   : { preview : false, class : "fal fa-file-powerpoint color-orange fa-5x", tipo : "PowerPoint" },
		unknow : { preview : false, class : "fal fa-file fa-5x", tipo : "Desconocido" },
	};

	const monthNames = [
		"Enero",
		"Feb",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Ago",
		"Sept",
		"Oct",
		"Nov",
		"Dic"
	];

	const handleOpenDetails = () => {
		setOpenDetails(!openDetails);
	};

	const showPreview = (src, name, type) => {
		modalActions.openModal({
			title     : name,
			iconTitle : "fas fa-eye",
			md        : 10,
			lg        : 10,
			onClose   : handleOpenDetails,
			component : <Preview src={src} type={type} />,
		});
	};

	return (
		<div className={`ListFiles ${classes.root}`}>
			<List>
				{
					files.map((file, idx) => {
						const newDate = new Date(`${file.date_document} 00:00:00`);
						const month = newDate.getMonth();
						const year = newDate.getFullYear();
						return (
							<ListItem className="list-documents" divider key={file.id}>
								<ListItemAvatar>
									<Grid container direction="column" alignItems="center">
										<button className="button-download" type="button" onClick={() => downloadFile(`${process.env.REACT_APP_SYSCONTROL_API}/storage/file/${file.file_id}`, file.name)}>
											<Grid container direction="column" alignItems="center">
												<i className={filesType[file.file.extension] ? filesType[file.file.extension].class : filesType.unknow.class} />
												<legend className="p-2 download">
													<i className="fas fa-cloud-download fa-1x" />
													{"Descargar"}
												</legend>
											</Grid>
										</button>
										<button
											disabled={filesType[file.file.extension] ? !filesType[file.file.extension].preview : !filesType.unknow.preview}
											className="button-download button-preview"
											type="button"
											onClick={() => showPreview(`${process.env.REACT_APP_SYSCONTROL_API}/storage/file/${file.file_id}`, file.name, file.file.extension)}
										>
											<Grid container direction="column" alignItems="center">
												<legend className="p-2 download">
													<i className="fas fa-eye" />
													{"Previsualizar"}
												</legend>
											</Grid>
										</button>
									</Grid>
								</ListItemAvatar>
								<Grid container direction="column" justify="space-between" alignItems="flex-start">
									<Typography variant="subtitle1">{`Documentación del mes : ${monthNames[month]}/${year}`}</Typography>
									<Typography className="file-name my-2" variant="h6">{(file.name.length > 14) ? `${file.name.substring(0, 14)}...${file.file.extension}` : file.name}</Typography>
									<Typography className="my-2" variant="h6">{`Enviado: ${file.created_at.substring(0, 10)}`}</Typography>
								</Grid>
							</ListItem>
						);
					})
				}
			</List>
		</div>
	);
};

ListFiles.propTypes = {
	files        : PropTypes.arrayOf(PropTypes.shape({})),
	modalActions : PropTypes.object.isRequired,
};

ListFiles.defaultProps = {
	files : {},
};

const mapDispatchToProps = dispatch => ({ modalActions : bindActionCreators(ModalActions, dispatch) });

export default connect(null, mapDispatchToProps)(ListFiles);
