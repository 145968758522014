/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect }            from "react-redux";
import {
	TableContainer,
	Grid,
	Typography,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Dialog,
	Slide,
	Button
} from "@material-ui/core";
import CircularProgress  from "@material-ui/core/CircularProgress";
import Autocomplete      from "@material-ui/lab/Autocomplete";
import swal              from "sweetalert2";

// Import userService
import userService   from "../../../config/services/userService";

// import own components
import RequestSelector	from "../RequestSelector";
import { initState } from "./const";
import Row           from "./components/Row";
import "./EmployerRegistrationWithdrawal.scss";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const EmployerRegistrationWithdrawal = ({ employeeId, loggedIn, match }) => {
	const [employerRegisters, setEmployerRegisters] = useState([]);
	const [openSelector, setOpenSelector]	= useState(false);
	const [state, setState]                 = useState(initState);
	const [filter, setFilter]				= useState("");
	const [customers, setCustomers]			= useState([]);
	const [open, setOpen] 					= useState(false);
	const loading = open && customers.length === 0;

	const handleRequestSelector = () => {
		setOpenSelector(!openSelector);
	};


	const handleChange = name => (d) => {
		const value = d && d.target ? d.target.value : d && d.id ? d.id : d;

		setState({
			...state,
			formData : {
				...state.formData,
				[name] : value,
			}
		});
	};

	useEffect(() => {
		(async () => {
			setState({
				...state,
				formData : {
					...state.formData,
					responsible_id : employeeId,
				}
			});
		})();
	}, []);

	const getEmployerRegistrations = async () => {
		const response = await userService.getEmployerRegistrations(state.formData.customer_id);
		if (response.status !== 200) {
			swal.fire("¡Error!", "No ha sido posible cargar los registros patronales del cliente", "error");
		}
		setEmployerRegisters(response.data);
	};

	const getCustomers = async () => {
		const response = await userService.getCustomers({
			page                       : 1,
			per_page                   : 20,
			type                       : "activo",
			with_employer_registration : true,
			filter
		});
		if (response.status === 200) {
			setCustomers(response.data);
		} else {
			swal.fire("!Error!", "Error al cargar los clientes", "error");
		}
	};

	useEffect(() => {
		getCustomers();
	}, [filter]);

	useEffect(() => {
		getEmployerRegistrations();
	}, [state]);

	const handleCancellation = async (event, employerRegistration, isCancelable) => {
		if (!isCancelable) {
			return swal.fire({
				title : "¡Hey!",
				text  :
	"Debes dar de baja a todos los empleados de estas sucursales para poder dar de baja el registro patronal",
				icon              : "warning",
				confirmButtonText : "Ok",
			});
		}

		event.preventDefault();
		setState({
			...state,
			submitted : true,
		});
		try {
			swal.fire({
				title             : "Confirmación",
				text              : "¿Seguro que deseas iniciar este proceso?",
				icon              : "info",
				showCancelButton  : true,
				confirmButtonText : "Iniciar",
				cancelButtonText  : "Cancelar",
			}).then(async (result) => {
				const formData = {
					...state.formData,
					employer_registration_id : employerRegistration
				};
				if (result.isConfirmed) {
					const response = await userService.createRequestController({
						request     : JSON.stringify(formData),
						requestType : "EMPLOYER_REGISTRATION_WITHDRAWAL",
					});
					if (response.status === 200) {
						swal.fire("!Enviado!", "Tu solicitud ha sido enviada correctamente!", "success");
					} else {
						swal.fire("!Error!", response.label, "error");
					}
				}
			});
		} catch (error) {
			swal.fire(`Error ${error}`, "error");
		}
	};

	return (
		<>
			<div className="EmployerRegistrationWithdrawal">
				<div className="topHeader" />
				<Grid container item xs={11} alignItems="flex-end" justify="flex-end">
					<Grid item xs={4}>
						<Button className="btn-green" variant="outlined" color="primary" onClick={handleRequestSelector}>
						CAMBIAR TIPO DE SOLICITUD
						</Button>
					</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignItems="center"
				>
					<Grid item xs={11}>
						<Typography variant="h5" component="h2" style={{ paddingBottom : "1rem", textAlign : "center" }}>
                        Iniciar solicitud de baja de registro patronal
						</Typography>
					</Grid>
					<Grid item md={6}>
						<Autocomplete
							open={open}
							onOpen={() => {
								setOpen(true);
							}}
							onClose={() => {
								setOpen(false);
							}}
							onChange={(e, newValue) => handleChange("customer_id")(newValue)}
							getOptionSelected={(option, value) => option.name === value.name}
							getOptionLabel={option => option.name}
							options={customers}
							inputValue={filter}
							onInputChange={(event, newInputValue) => {
								setFilter(newInputValue);
							}}
							noOptionsText="No hay más clientes"
							loadingText="Cargando..."
							loading={loading}
							renderInput={params => (
								<TextField
									{...params}
									label="Clientes"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										autoComplete : "new-password",
										endadornment : (
											<>
												{loading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<Grid
					container
					justify="center"
					alignItems="center"
				>
					<TableContainer component={Paper}>
						<Table aria-label="collapsible table">
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell>Número de registro patronal</TableCell>
									<TableCell>Delegación y subdelegación</TableCell>
									<TableCell align="right">Número de empleados registrados</TableCell>
									<TableCell align="right">Fecha de Registro</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{employerRegisters.map(row => (
									<Row key={row.id} row={row} handleCancellation={handleCancellation} />
								))}
								{!employerRegisters.length ? (
									<TableRow>
										<TableCell />
										<TableCell component="th" scope="row">
										No hay clientes seleccionados o el cliente no tiene registros patronales
										</TableCell>
									</TableRow>
								) : null}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</div>
			<Dialog
				open={openSelector}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleRequestSelector}
				aria-labelledby="Crear nueva solicitud"
				aria-describedby="Crear nueva solicitud"
			>
				<RequestSelector match={match} modal />
			</Dialog>
		</>
	);
};

EmployerRegistrationWithdrawal.propTypes = {
	employeeId : PropTypes.number.isRequired,
	match      : PropTypes.object.isRequired,
	loggedIn   : PropTypes.bool,
};

EmployerRegistrationWithdrawal.defaultProps = {
	loggedIn : false,
};

const mapStateToProps = ({ authReducer: { customerData, loggedIn } }) => ({
	employeeId : customerData.id,
	loggedIn,
});

export default connect(mapStateToProps, null)(EmployerRegistrationWithdrawal);
