import React     from "react";
import PropTypes from "prop-types";

import "./MessageValidate.scss";

const MessageValidate = ({ message, type }) => (
	<div className="MessageValidate help-block mb-2">
		<span className={type === "error" ? "error-text" : "success-text"}>
			{ message }
		</span>
	</div>
);

MessageValidate.propTypes = {
	message : PropTypes.string.isRequired,
	type    : PropTypes.arrayOf(["success", "error"])
};

MessageValidate.defaultProps = {
	type : "error"
};

export default MessageValidate;
