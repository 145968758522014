import React		from "react";
import PropTypes	from "prop-types";
// Import Own Components
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";

const CustomerLayout = ({ children }) => (
	<>
		<Header />
		<main>
			{children}
		</main>
		<Footer />
	</>
);

CustomerLayout.propTypes = {
	children : PropTypes.node.isRequired
};

export default CustomerLayout;
