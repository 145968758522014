/* eslint-disable react/jsx-curly-brace-presence */
import React, {
	useState,
	useEffect
} from "react";
import PropTypes              from "prop-types";
import { connect }            from "react-redux";
import { bindActionCreators } from "redux";
import { orange }             from "@material-ui/core/colors";
import swal                   from "sweetalert2";
import MailOutline            from "@material-ui/icons/MailOutline";
import VpnKey                 from "@material-ui/icons/VpnKey";
import Visibility             from "@material-ui/icons/Visibility";
import VisibilityOff          from "@material-ui/icons/VisibilityOff";
import {
	TextField,
	InputAdornment,
	Button,
	Card,
	withStyles,
	FormControlLabel,
	Switch,
	IconButton
} from "@material-ui/core";

// Import Own Components
import { CircularDeterminateLoading } from "../../components/Loading";
import { history }                    from "../../config/helpers";
import AuthActions                    from "../../config/store/actions/authActions";
import "./SignInForm.scss";

const styles = theme => ({
	main : {
		[theme.breakpoints.up("xs")] : { maxWidth : "95%" },
		[theme.breakpoints.up("sm")] : { maxWidth : "70%" },
		[theme.breakpoints.up("md")] : { maxWidth : "40%" },
		[theme.breakpoints.up("lg")] : { maxWidth : "30%" },
		margin                       : "0 auto",
	},
	SignInButton : {
		backgroundColor : theme.palette.primary.main,
		color           : theme.palette.common.white,
		"&:hover"       : {
			backgroundColor : theme.palette.primaryShades[400],
		},
	},
	errorMessage : {
		color : orange[900],
	},
});

const SignInForm = ({
	loggedIn,
	classes,
	authActions,
}) => {
	useEffect(() => {
		// If we're logged in, move to home
		if (loggedIn) {
			history.push("/");
		}
	}, [loggedIn]);

	const [signInData, setSignInData] = useState({
		username : "",
		password : "",
		type   	 : "cliente"
	});

	const [submitted, setSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showPass, setShowPass]   = useState(false);

	const handleChange = ({ target }) => {
		if (target.name === "type") {
			target.value = target.checked ? "empleado" : "cliente";
		}
		setSignInData({
			...signInData,
			[target.name] : target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSubmitted(true);
		setIsLoading(true);

		const { username, password, type } = signInData;

		if (username && password) {
			const loginSuccess = await authActions.login(username, password, type);

			if (!loginSuccess) {
				swal.fire({
					title               : "",
					text                : "Usuario o contraseña incorrectos",
					icon                : "error",
					timer               : 3000,
					buttons             : false,
					closeOnClickOutside : true,
				});
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
		}
	};

	const { username, password } = signInData;

	return (
		<Card className={`${classes.main} SignInForm`}>
			<div className="formContainer">
				<form autoComplete="on">
					<div className="SignInInput">
						<TextField
							fullWidth
							label="Usuario o Correo"
							type="email"
							name="username"
							value={username}
							onChange={handleChange}
							InputProps={{
								autoComplete   : "on",
								startAdornment : (
									<InputAdornment position="start">
										<MailOutline />
									</InputAdornment>
								),
							}}
						/>
						{submitted && !username && (
							<div className={classes.errorMessage}>
								{ "El usuario es requerido." }
							</div>
						)}
					</div>
					<div className="SignInInput">
						<TextField
							fullWidth
							label="Contraseña"
							type={showPass ? "text" : "password"}
							name="password"
							value={password}
							onChange={handleChange}
							InputProps={{
								autoComplete   : "on",
								startAdornment : (
									<InputAdornment position="start">
										<VpnKey />
									</InputAdornment>
								),
								endAdornment : (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowPass(!showPass)}>
											{showPass ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
						{submitted && !password && (
							<div className={classes.errorMessage}>
								{ "La contraseña es requerida" }
							</div>
						)}
					</div>
					<FormControlLabel
						control={(
							<Switch
								checked={signInData.type === "empleado"}
								onChange={handleChange}
								name="type"
								color="primary"
							/>
						)}
						label="Iniciar sesión como empleado"
					/>
					<Button
						fullWidth
						variant="contained"
						className={classes.SignInButton}
						onClick={handleSubmit}
						type="submit"
					>
						<i className="fas fa-lock-alt" />
						{" Acceder a mi cuenta"}
					</Button>
				</form>

				{ isLoading && (
					<div className="CircularLoading">
						<CircularDeterminateLoading />
					</div>
				) }
			</div>
		</Card>
	);
};

SignInForm.propTypes = {
	loggedIn    : PropTypes.bool,
	classes     : PropTypes.object.isRequired,
	authActions : PropTypes.object.isRequired,
};

SignInForm.defaultProps = {
	loggedIn : false,
};

const mapStateToProps    = ({ authReducer : { loggedIn } }) => ({ loggedIn });
const mapDispatchToProps = dispatch => ({ authActions : bindActionCreators(AuthActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(
	withStyles(styles)(SignInForm)
);
