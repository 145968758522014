import React from "react";
import PropTypes from "prop-types";
import {
	Grid,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
	IconButton
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";

import useStyles1 from "./styles";

const TablePaginationActions = (props) => {
	const classes = useStyles1();
	const theme = useTheme();
	const {
		count, page, rowsPerPage, onChangePage
	} = props;

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
};

TablePaginationActions.propTypes = {
	page         : PropTypes.number.isRequired,
	onChangePage : PropTypes.func.isRequired,
	count        : PropTypes.number,
	rowsPerPage  : PropTypes.number,
};

TablePaginationActions.defaultProps = {
	count       : 0,
	rowsPerPage : 5
};

const EmployeesTable = ({ employees, pagination, handlePagination }) => (
	<Grid container>
		<TableContainer component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Nombre</TableCell>
						<TableCell align="center">RFC</TableCell>
						<TableCell align="center">CURP</TableCell>
						<TableCell align="center">NSS</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{employees.map(row => (
						<TableRow key={row.name}>
							<TableCell component="th" scope="row">
								{`${row.name} ${row.lastname}`}
							</TableCell>
							<TableCell align="center">{row.rfc}</TableCell>
							<TableCell align="center">{row.curp}</TableCell>
							<TableCell align="center">{row.nss}</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							colSpan={3}
							count={pagination.total ? pagination.total : 0}
							rowsPerPage={pagination.per_page ? Number(pagination.per_page) : 5}
							page={pagination.current_page - 1}
							labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
							labelRowsPerPage="Registros por pagina"
							SelectProps={{
								inputProps : { "aria-label" : "Registros por pagina" },
								native     : true,
							}}
							onChangePage={(_, page) => handlePagination("page")(page + 1)}
							onChangeRowsPerPage={e => handlePagination("per_page")(parseInt(e.target.value, 10))}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	</Grid>
);

EmployeesTable.propTypes = {
	employees        : PropTypes.array.isRequired,
	pagination       : PropTypes.object.isRequired,
	handlePagination : PropTypes.func.isRequired
};

export default EmployeesTable;
